import {
  Injectable, Pipe,
  PipeTransform
} from '@angular/core';

@Pipe({
  name: 'weekDays'
})
@Injectable()
export class WeekDaysPipe implements PipeTransform {
  transform(days: boolean[], lang: any): string {
    let res: string = "";
    if(!days || days.length < 7){
      return res;
    }
    let count: number = 0;
    if(days[0]){
      res += lang.dom + ", ";
      count++;
    }
    if(days[1]){
      res += lang.lun + ", ";
      count++;
    }
    if(days[2]){
      res += lang.mar + ", ";
      count++;
    }
    if(days[3]){
      res += lang.mer + ", ";
      count++;
    }
    if(days[4]){
      res += lang.gio + ", ";
      count++;
    }
    if(days[5]){
      res += lang.ven + ", ";
      count++;
    }
    if(days[6]){
      res += lang.sab + ", ";
      count++;
    }
    if(res){
      res = res.substr(0, res.length - 2);
    }
    if(count === 7) return lang.tutti
    return res;
  }
}
