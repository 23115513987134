import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-step-card',
  templateUrl: './step-card.component.html',
  styleUrls: ['./step-card.component.scss'],
})
export class StepCardComponent implements OnInit {
  @Input() items;
  lang: any;
  constructor(
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    this.translate.get('REFERRAL').subscribe((lang: any) => {
      this.lang = lang;
    });
  }

}
