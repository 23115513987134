import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, Input,
  OnInit
} from '@angular/core';
import {
  ViewService
} from "../../../services/common/view.service";

@Component({
  selector: 'common-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopBarComponent implements OnInit {

  @Input() menuButtons: any;
  @Input() navButtons: any;
  @Input() sidebar_isEnabled: boolean;
  @Input() navCtrl: any;

  listeners: any[] = [];

  tempAvatar = "https://www.cinemat" +
    "ographe.it/wp-content/uploads/2" +
    "019/04/Avatar.jpg";

  constructor(
    private ref: ChangeDetectorRef,
    public view: ViewService
  ) {
  }

  ngOnInit() {
    this.listeners.push(ViewService.updateView$.subscribe(() => {
      this.ref.markForCheck();
    }));
  }

  ngOnDestroy() {
    for(let listener of this.listeners){
      listener.unsubscribe();
    }
  }

  openPage(path: string) {
    if (
      path && path != this.view.currentPath
    ) {
      this.navCtrl.navigateRoot("/" + path);
      this.view.setCurrentPath(path);
      this.ref.markForCheck();
    }
  }





  // sidebar

  async set_sidebar_isEnabled() {
    // this.sidebar_isEnabled = await this.menuCtrl.isEnabled("sidebar");
    // this.cd.markForCheck();
  }

  toggleSidebar() {
    // this.menuCtrl.toggle("sidebar");
  }


}
