import {Charge} from "./charge"
import {InvoiceLine} from "./invoiceLine"
import {PaymentMethodsDetails} from "./paymentMethods"
import {Order} from "src/app/classes/common/order";

export const INVOICE_STATUS = {
  paid: 'paid',
  not_paid: 'not_paid',
  in_payment: 'in_payment'
}

export class Invoice {

  id: string;
  number: string;
  $stripe_invoice_number;
  created: number;
  metadata: any;
  balance_used_amount: number;
  balance_used_amount_formatted: string;
  changed_balance_used_amount_formatted: string;
  amount_due_formatted: string;
  tax: string;
  tax_formatted: string;
  total_excluding_tax_formatted: string;
  total_formatted: string;
  payment_method_label: string;
  payment_history: string[];
  period_start: number;
  period_end: number;
  total: number;
  charge: Charge;
  private _status: string;
  status_formatted: string;
  invoice_pdf: string;
  amount_due: number;
  paid: boolean;
  lines: InvoiceLine[] = [];
  status_transitions: any;
  $lastPayment: PaymentMethodsDetails;
  $raw: string[];
  $order: Order;

  _finalized_at: number;


  private $getLineName: (number) => string = () => {return undefined}
  private $lang: string;
  constructor(data?: any, options?: { getLineName?: (number) => string, lang?: string }) {
    if (data) {
      this.setData(data, options);
    }
  }

  setData(data: any, options? : { getLineName?: (number) => string, lang?: string}) {
    if(options){
     if(options.getLineName) { this.$getLineName = options.getLineName}
     if(options.lang) {
       this.$lang = options.lang
     }
    }
    this.id = data.id;
    this.number = data.metadata.number_fattura_elettronica ? data.metadata.number_fattura_elettronica : '';
    this.$stripe_invoice_number = data.number;
    this.created = data.created;
    this.metadata = data.metadata;
    this.balance_used_amount = data.balance_used_amount;
    this.balance_used_amount_formatted = data.balance_used_amount_formatted;
    this.changed_balance_used_amount_formatted = data.changed_balance_used_amount_formatted;
    this.amount_due_formatted = data.amount_due_formatted;
    this.tax = data.tax;
    this.tax_formatted = data.tax_formatted;
    this.total_excluding_tax_formatted = data.total_excluding_tax_formatted;
    this.total_formatted = data.total_formatted;
    this.payment_method_label = data.payment_method_label;
    this.payment_history = data.payment_history;
    this.status = data.status;
    this.status_formatted = data.status_formatted;
    this.total = data.total;
    this.invoice_pdf = data.invoice_pdf;
    this.period_start = data.period_start;
    this.period_end = data.period_end;
    this.amount_due = data.amount_due;
    this.paid = data.paid;
    this.charge = new Charge(data.charge_object_formatted);
    this.status_transitions = data.status_transitions;
    if (data.lines) {
      for (let line of data.lines) {
        this.lines.push(new InvoiceLine(line, {getLineName: this.$getLineName, lang: this.$lang}))
      }
    }
    if (this.charge && this.charge.payment_method_details) {
      this.$lastPayment = this.charge.payment_method_details;
    }
    this.$order = new Order(data.order);
    this.$raw = data
    this.finalized_at = data.status_transitions;
    this.checkBalance(data)
  }

  get status(): string{
    return this._status;
  }

  set status(value: string){
    this._status = value;
  }

  checkBalance(data){
    if(data.status === 'draft' && data.starting_balance < 0 && data.ending_balance === null && data.amount_due !== data.total){
      this.balance_used_amount = data.amount_due - data.total;
      this.balance_used_amount_formatted = parseFloat((-1 * (this.balance_used_amount / 100)).toString()).toFixed(2);
      this.changed_balance_used_amount_formatted = parseFloat((this.balance_used_amount / 100).toString()).toFixed(2);
    }
  }

  get finalized_at(): number{
    return this._finalized_at;
  }

  set finalized_at(value: any){
    if(value.finalized_at){
      this._finalized_at = value.finalized_at;
    }
  }


}
