import {Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, Input} from '@angular/core';
import {
  ViewService
} from "../../../services/common/view.service";


// type Layout =
//   "chip-lbl" |
//   "item";


@Component({
  selector: 'app-map-popup-poi',
  templateUrl: './map-popup-poi.component.html',
  styleUrls: ['./map-popup-poi.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapPopupPoiComponent implements OnInit {



  // component args

  // @Input() layout/*: Layout*/ = "item";

  // @Input() id: string;
  // @Input() name: string;

  // @Input() status: string;



  stats = [
    {
      lbl: "Nome",
      val: "Magazzino Ferrero",
    },

  ];

  stats2 = [
    {
      lbl: "Lat",
      val: "40000",
    },

    {
      lbl: "Long",
      val: "40000",
    },

  ];

  btns = [
    {
      icon: "road+cursor_mid-dark.svg",
      lbl: "Street view",
    },

  ];



  // listener

  listener = {
    view: null,
  };



  constructor(
    private cd: ChangeDetectorRef,
    // private navCtrl: NavController,
    // navParams can only be used in modals and popovers!
    // private navParams: NavParams,
    // private tdweb: TdwebService,
    // private dataService: DataService,
    // private popoverCtrl: PopoverController,
    // private alertCtrl: AlertController,
    // private modalCtrl: ModalController,
    // private api: ApiService,
    public view: ViewService,
    // private menuCtrl: MenuController,
  ) {}


  ngOnInit() {
    // console.log("ngOnInit()");


    //  -------------------------
    //  listeners:

    this.listenerView();

    //  -------------------------


    /*if (!this.status) {
      this.status = "off";
    }*/
  }

  ngOnDestroy() {
    // console.log("ngOnDestroy()");


    //  -------------------------
    // disengage listeners:

    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }

    //  -------------------------
  }





  /*myFunc() {
    console.log("myfunc", typeof this.val == 'number')
    return typeof this.val == 'number';
  }*/





  // listener

  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      // console.log("listener.view", obj);

      // this.zone.run(() => {

      this.view.pipeChanged++;
      this.cd.markForCheck();

      setTimeout(() => {
        this.cd.markForCheck();
      }, 250);

      // });
    });
  }










}
