export class Poi {

  _id: string;
  idOrganization: string;
  name: string;
  images: string;
  position: any;
  radius: number;
  timestampLastUpdate: number;
  timestampCreate: number;
  address: string;
  label_number: number;

  // Aggiunti frontend
  latitude: number;
  longitude: number;
  hideOnMap: boolean;
  hideInList: boolean;
  hideInListTimeout: any;

  constructor(data?: any) {
    if(data){
      this.setData(data);
    }
  }

  setData(data: any){
    this._id = data._id;
    this.name = data.name;
    this.idOrganization = data.idOrganization;
    this.images = data.images;
    this.position = data.position;
    this.radius = data.radius;
    this.timestampLastUpdate = data.timestampLastUpdate;
    this.timestampCreate = data.timestampCreate;
    this.address = data.address || data.adress; // cambiare poi con address
    this.label_number = data.label_number;
    if(this.position && this.position.coordinates && this.position.coordinates.length === 2){
      this.latitude = this.position.coordinates[1];
      this.longitude = this.position.coordinates[0];
    }
  }
}
