import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  ViewService
} from "../../../services/common/view.service";


type Layout =
  "check" |
  "check+avatar" |
  // "radio" |
  "toggle";


@Component({
  selector: 'app-input-bool',
  templateUrl: './input-bool.component.html',
  styleUrls: ['./input-bool.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputBoolComponent implements OnInit {
  /*
    nested components
    'selector' list:
    --------------------------------
    app-avatar
  */


  // component args

  @Input() avatar: {
    // avatar css
    // css?: string,
    img?: string,
    initials?: string,
  }/*  = {} */;
  @Input() css: string;
  @Input() layout: Layout = "check";
  @Input() lbl: string;
  @Input() val: boolean = false;
  @Input() disabled: boolean;

  @Output() toggle = new EventEmitter<any>();



  // listener

  listener = {
    view: null,
  };


  constructor(
    // private alertCtrl: AlertController,
    // private api: ApiService,
    private cd: ChangeDetectorRef,
    // private dataService: DataService,
    // private menuCtrl: MenuController,
    // private modalCtrl: ModalController,
    // private navCtrl: NavController,
    // navParams can only be used in modals and popovers!
    // private navParams: NavParams,
    // private popoverCtrl: PopoverController,
    // private tdweb: TdwebService,
    public view: ViewService,
  ) {}

  ngOnInit() {
    // console.log("ngOnInit()");
    this.listenerView();

    if (this.layout == "check+avatar" && !this.avatar) {
      this.avatar = {};
    }
  }

  ngOnDestroy() {
    // console.log("ngOnDestroy()");
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
  }



  /*myFunc() {
    console.log("myfunc", typeof this.val == 'number')
    return typeof this.val == 'number';
  }*/




  // listener

  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      // console.log("listener.view", obj);

      // this.zone.run(() => {

      this.view.pipeChanged++;
      this.cd.markForCheck();

      setTimeout(() => {
        this.cd.markForCheck();
      }, 250);

      // });
    });
  }

  changeVal(val: any){
    val = !val;
    this.toggle.emit();
  }












}
