import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {
  ViewService
} from "../../../services/common/view.service";


// type Layout =
//   "check" |
//   "check+avatar" |
//   "radio" |
//   "toggle";


@Component({
  selector: 'app-input-select',
  templateUrl: './input-select.component.html',
  styleUrls: ['./input-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputSelectComponent implements OnInit {



  // component args

  // @Input() css: string;
  // @Input() layout: Layout;
  @Input() placeholder: string;
  @Input() val: number | string;



  // listener

  listener = {
    view: null,
  };


  constructor(
    // private alertCtrl: AlertController,
    // private api: ApiService,
    private cd: ChangeDetectorRef,
    // private dataService: DataService,
    // private menuCtrl: MenuController,
    // private modalCtrl: ModalController,
    // private navCtrl: NavController,
    // navParams can only be used in modals and popovers!
    // private navParams: NavParams,
    // private popoverCtrl: PopoverController,
    // private tdweb: TdwebService,
    public view: ViewService,
  ) {}

  ngOnInit() {
    // console.log("ngOnInit()");
    this.listenerView();
  }

  ngOnDestroy() {
    // console.log("ngOnDestroy()");
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
  }



  /*myFunc() {
    console.log("myfunc")
  }*/




  // listener

  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      // console.log("listener.view", obj);

      // this.zone.run(() => {

      this.view.pipeChanged++;
      this.cd.markForCheck();

      setTimeout(() => {
        this.cd.markForCheck();
      }, 250);

      // });
    });
  }










}
