import {Component, OnInit, ChangeDetectionStrategy, EventEmitter, Output} from '@angular/core';
import {Input} from "@angular/core";

/**
 * Questo componente è stato creato inizialmente per la lista di elementi in mappa di balin.app
 * e sostituisce il componente app-item con layout: car+star e poi+star
 * E' composto da:
 *  - avatar con bordo di stato e label interno;
 *  - titolo
 *  - prima riga
 *  - seconda riga
 *  - icona di destra
 */
@Component({
  selector: 'avatar-item',
  templateUrl: './avatar-item.component.html',
  styleUrls: ['./avatar-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarItemComponent implements OnInit {

  @Input() selected: boolean; // se l'item è selezionato
  @Input() rightIconName: string; // nome icona di dx
  @Input() rightIconShowedOnSelected: boolean;
  @Input() rightIconSelectedName: string; // nome icona di destra selezionata
  @Input() rightIconSelected: any; // distinzione tra icona selezionata e non
  @Input() avatar: string; // immagine avatar
  @Input() avatarCss: string; // css classes for the avatar wrapper elem
  @Input() avatarText: string | number ; // testo nell'avatar
  @Input() title: string; // titolo dell'item (prima riga)
  @Input() firstRow: string; // testo prima riga dopo titolo
  @Input() secondRow: string; // testo seconda riga dopo titolo
  @Input() secondRowIcon: string; // icona iniziale della seconda riga
  @Input() viewDisabled: boolean; // distinzione da elemento "disabilitato" e "abilitato"
  @Input() noRightIcon: boolean;
  @Input() enabledState: boolean;
  @Input() stateColor: string;
  @Input() stateTooltip: string;
  @Input() icon: string;

  @Output() select = new EventEmitter<any>(); // evento al click sulla parte principale dell'emento
  @Output() rightIconSelect = new EventEmitter<any>(); // evento al click sull'icona di dx

  constructor() {
  }

  ngOnInit() {
  }

}
