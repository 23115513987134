import {Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter, Output} from '@angular/core';
import {Input} from "@angular/core";
import {
  ViewService
} from "../../../services/common/view.service";

type Layout =
  "car" |
  "car+star" |
  "carDetailHead" |
  "carSimple" |
  // * POI
  "poi" |
  "poi+star" |
  "poiDetailHead";

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemComponent implements OnInit {

  @Input() layout: Layout;
  @Input() obj;
  @Input() selected: boolean;
  @Input() starred: boolean;
  @Input() status: boolean;
  @Input() visibleOnMap: boolean;
  @Input() labelDay: string;
  @Input() hideAddress: boolean;

  // checkbox
  @Input() checkboxValue: boolean;
  @Input() showCheckbox: boolean;

  @Output() select = new EventEmitter<any>();
  @Output() star = new EventEmitter<any>();
  @Output() toggleCheckbox = new EventEmitter<any>();

  listener = {
    view: null
  };

  constructor(
    private cd: ChangeDetectorRef,
    public view: ViewService,
  ) {
  }

  ngOnInit() {
    this.listenerView();
  }

  ngOnDestroy() {
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
  }

  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.cd.markForCheck();
      setTimeout(() => {
        this.cd.markForCheck();
      }, 250);
    });
  }

}
