export class PaymentMethods {

  id: string;
  customer: string;
  metadata: string[];
  type: string;

  $payment_method_details: PaymentMethodsDetails
  $raw: string[]

  constructor(data?: any) {
    if (data) {
      this.setData(data)
    }
  }

  setData(data) {
    this.id = data.id;
    this.customer = data.customer;
    this.metadata = data.metadata;
    this.type = data.type;
    this.$payment_method_details = new PaymentMethodsDetails(data, this.type)
    this.$raw = data
  }
}

export class PaymentMethodsDetails {

  brand: string;
  last4: string;
  exp_year: string;
  exp_month: string;
  country: string;
  $name: string;
  private _type: string

  constructor(data?: any, type?: string) {
    if (data[type]) {
      this.setData(data[type], type)
    }
  }

  setData(data, type?: string) {
    this.brand = data.brand
    this.last4 = data.last4
    this.exp_year = data.exp_year
    this.exp_month = data.exp_month
    this.country = data.country
    this.type = type
  }

  get type(): string {
    return this._type;
  }

  set type(value: string) {
    if(value){
      switch(value){
        case 'card':
          this.$name = this.brand;
          break;
        case 'sepa_debit':
          this.$name = 'SEPA'
          break;
      }
    }
    this._type = value;
  }




}
