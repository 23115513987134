import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decimalNumber',
})
export class DecimalNumberPipe implements PipeTransform {

  transform(num: number, decimals: number, no_number?: string) {
    if(!num) return no_number;
    let num_string: string = num.toString();

    let result: string;
    let splitted: string[] = num_string.split(".");
    if(decimals <= 0) return splitted[0];
    if(splitted.length == 2){
      if(splitted[1].length >= decimals){
        result = splitted[0] + "," + splitted[1].substring(0, decimals);
      }else{
        result = splitted[0] + "," + splitted[1];
        for(let i=(splitted[1].length); i<decimals; i++){
          result += "0";
        }
      }
    }else{
      result = splitted[0] + ",";
      for(let i=0; i<decimals; i++){
        result += "0";
      }
    }
    return result;
  }
}
