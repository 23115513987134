import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {
  ViewService
} from "../../../services/common/view.service";


type Layout =
  "modal" |
  "page" |
  "page-t2";


@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageTitleComponent implements OnInit {


  // component args

  @Input() css: string;
  @Input() icon: string;
  @Input() layout: Layout = "page";
  @Input() lbl: string;
  @Input() lbl2: string;
  @Input() closeButton: boolean;
  @Input() closeAction : () => void;



  // listener

  listener = {
    view: null,
  };


  constructor(
    private cd: ChangeDetectorRef,
    // private navCtrl: NavController,
    // navParams can only be used in modals and popovers!
    // private navParams: NavParams,
    // private tdweb: TdwebService,
    // private dataService: DataService,
    // private popoverCtrl: PopoverController,
    // private alertCtrl: AlertController,
    // private modalCtrl: ModalController,
    // private api: ApiService,
    public view: ViewService,
    // private menuCtrl: MenuController,
  ) {}


  ngOnInit() {
    // console.log("ngOnInit()");

    //  -------------------------
    //  listeners:

    this.listenerView();

    //  -------------------------


    /*if (!this.status) {
      this.status = "off";
    }*/
  }


  ngOnDestroy() {
    // console.log("ngOnDestroy()");

    //  -------------------------
    // disengage listeners:

    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }

    //  -------------------------
  }




  /*myFunc() {
    console.log("myfunc", typeof this.val == 'number')
    return typeof this.val == 'number';
  }*/




  // listener

  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      // console.log("listener.view", obj);

      // this.zone.run(() => {

      this.view.pipeChanged++;
      this.cd.markForCheck();

      setTimeout(() => {
        this.cd.markForCheck();
      }, 250);

      // });
    });
  }







}
