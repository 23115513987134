import { Pipe, PipeTransform } from '@angular/core';
import {Partecipant} from "src/app/classes/common/partecipant";
import {DeadlineTarget} from "src/app/classes/common/deadline";

@Pipe({
  name: 'deadlineVehicles'
})
export class DeadlineVehiclesPipe implements PipeTransform {

  transform(vehicles: Partecipant[], target?: DeadlineTarget[], filter?: any, changed?: number): any[] {
    /** 1. Ottengo la lista mergiata di veicoli **/
    const mergedVehicles: (Partecipant|DeadlineTarget)[] = [...vehicles, ...target];

    /** 2. Applico i filtri **/
    let filtered: (Partecipant|DeadlineTarget)[] = []

    for(let filtro of filter){

      if(filtro.selected === true){

        if(filtro.id === 'veicoli'){
          //aggiungo all'array filtered
          let a = mergedVehicles.filter((elem)=>{
            return (elem instanceof Partecipant) || ((elem instanceof DeadlineTarget) && elem.type === 1)
          })

          filtered = [...filtered, ...a]
        }

        if(filtro.id === 'autisti'){
          let a = mergedVehicles.filter((elem)=>{
            return (elem instanceof DeadlineTarget) && elem.type === 2
          })

          filtered = [...filtered, ...a]
        }

      }
    }

    return filtered;
  }
}
