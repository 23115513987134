import { Component, OnInit } from '@angular/core';
import {Input} from "@angular/core";
import {ChangeDetectorRef} from "@angular/core";
import {PopoverController} from "@ionic/angular";
import {ViewService} from "src/app/services/common/view.service";
import {ModalController} from "@ionic/angular";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-report-settings',
  templateUrl: './report-settings.page.html',
  styleUrls: ['./report-settings.page.scss'],
})
export class ReportSettingsPage implements OnInit {

  @Input() opts: any = {};
  @Input() type: string;


  changed: boolean = false;

  closeAction: any = () => {this.dismiss()};
  lang: any;

  constructor(
    private cd: ChangeDetectorRef,
    private popoverCtrl: PopoverController,
    private modalCtrl: ModalController,
    public view: ViewService,
    private translate: TranslateService,

  ) { }

  ngOnInit() {
  }

  async ionViewDidEnter() {
    this.translate.get('ROUTES_HISTORY').subscribe((lang: any) => {
      this.lang = lang;
    });

    ViewService.updateView.next()
  }


  changeVal(option: any, event: any){
    switch (option){
      case 'stopTime':
        if(this.opts.report_stopTime !== event.value){

        }
        break;
      case 'tresholdStart':
        if(this.opts.report_tresholdStart !== event.value){

        }
        break;
    }
  }

  dismiss(){
    this.modalCtrl.dismiss()
  }

  save(){
    this.modalCtrl.dismiss({opts: this.opts})

  }

}
