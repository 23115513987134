import {Partecipant} from "./partecipant";
import {Subscription} from "./subscription";
import {ShopProducts} from "src/app/classes/common/shop-products";

export type BillingType = 1 | 2; // Privato | Azienda
export type BillingLocation = 1 | 2 | 3; // Italia | EU | Fuori EU

export class Organization {

  _id: string;
  name: string;
  timestamp_created: number;
  minute_difference_from_UTC: number;
  platform_type: number;
  service_sid: string;
  translate_language: string;
  timezone: string;
  phone_contacts:  {
    dial_code: string
    partial_phone: string
    label: string
  }[];

  billing_setting: boolean;
  login_need_default_payment_method: boolean;
  login_view_demo: boolean;
  login_isInTrial: boolean;
  login_timestamp_trial_end: number;
  login_timestamp_trial_end_ms: number;
  login_delinquent: boolean;

  // Billing data
  billing_type: BillingType;
  billing_location: BillingLocation;
  billing_companyName: string;
  billing_name: string;
  billing_surname: string;
  billing_address: string;
  billing_zip: string;
  billing_city: string;
  billing_province: string;
  billing_country: string;
  billing_mail: string;
  billing_phone: string;
  billing_vat: string;
  billing_mailPEC: string;
  billing_sdiCode_fatturaElettronica: string;
  billing_codiceFiscale: string;
  stripe_customer_id: string;
  stripe_customer: any;
  stripe_subscription: Subscription;
  stripe_invoices: any;
  stripe_payment_methods: any;
  stripe_customer_balance: any;
  stripe_upcoming_invoice: any;
  login_email_partecipant_owner: string;
  login_blocked: boolean;
  enable_balance_recharge: boolean;
  login_stripe_invoices_unpaid_count: number;
  last_shop_invoice: string;
  elenco_prodotti_shop: any[];

  participants: Partecipant[] = [];

  stripe_disable_add_payment: boolean = false;
  stripe_disable_add_sepa: boolean = false;

  me: Partecipant; // reppresenta l'utente corrente
  owner: Partecipant; // reppresenta l'utente proprietario dell'organizzazione

  constructor(data?: any){
    if(data){
      this.setData(data);
    }
  }

  setData(data){
    this._id = data._id;
    this.name = data.name;
    this.timestamp_created = data.timestamp_created;
    this.minute_difference_from_UTC = data.minute_difference_from_UTC;
    this.platform_type = data.platform_type;
    this.service_sid = data.service_sid;
    this.translate_language = data.translate_language;
    this.timezone = data.timezone;
    this.phone_contacts = data.phone_contacts ?? [];

    this.billing_setting = data.billing_setting;
    this.login_need_default_payment_method = data.login_need_default_payment_method;
    this.login_view_demo = data.login_view_demo;
    this.login_timestamp_trial_end = data.login_timestamp_trial_end;
    if(this.login_timestamp_trial_end) this.login_timestamp_trial_end_ms = this.login_timestamp_trial_end * 1000;
    this.login_isInTrial = data.login_isInTrial;
    this.login_delinquent = data.login_delinquent;

    this.billing_type = data.billing_type;
    this.billing_location = data.billing_location;
    this.billing_companyName = data.billing_companyName;
    this.billing_name = data.billing_name;
    this.billing_surname = data.billing_surname;
    this.billing_address = data.billing_address;
    this.billing_zip = data.billing_zip;
    this.billing_city = data.billing_city;
    this.billing_province = data.billing_province;
    this.billing_country = data.billing_country;
    this.billing_mail = data.billing_mail;
    this.billing_phone = data.billing_phone;
    this.billing_vat = data.billing_vat;
    this.billing_mailPEC = data.billing_mailPEC;
    this.billing_sdiCode_fatturaElettronica = data.billing_sdiCode_fatturaElettronica;
    this.billing_codiceFiscale = data.billing_codiceFiscale;
    this.stripe_customer_id = data.stripe_customer_id;
    this.stripe_customer = data.stripe_customer;
    this.stripe_invoices = data.stripe_invoices;
    this.stripe_payment_methods = data.stripe_payment_methods;
    this.stripe_customer_balance = data.stripe_customer_balance;
    this.stripe_upcoming_invoice = data.stripe_upcoming_invoice;
    this.login_email_partecipant_owner = data.login_email_partecipant_owner;
    this.login_blocked = data.login_blocked;
    this.enable_balance_recharge = data.enable_balance_recharge;
    this.stripe_subscription = new Subscription(data.stripe_subscription);
    this.login_stripe_invoices_unpaid_count = data.login_stripe_invoices_unpaid_count;
    this.last_shop_invoice = data.last_shop_invoice;
    this.elenco_prodotti_shop = data.elenco_prodotti_shop;
    this.stripe_disable_add_payment = data.stripe_disable_add_payment || false;
    this.stripe_disable_add_sepa = data.stripe_disable_add_sepa || false;
  }

  addParticipant(participant: Partecipant){
    if(participant){
      this.participants.push(participant);
    }
  }

  updateParticipant(rawData: any){
    if(rawData){
      for(let participant of this.participants){
        if(participant._id === rawData._id){
          participant.setData(rawData);
          break;
        }
      }
    }
  }

  removeParticipant(id: string){
    for(let i=0; i<this.participants.length; i++){
      if(this.participants[i]._id === id){
        this.participants.splice(i, 1);
        break;
      }
    }
  }

  getParticipant(id: string){
    return this.participants.find((elem: Partecipant) => {
      return elem._id === id;
    });
  }

  /** Dice se ci sono pagamenti in corso o ancora da effettuare **/
  havePaymentsToDo(): boolean{
    if(this.stripe_invoices){
      for(let key in this.stripe_invoices){
        if(this.stripe_invoices[key].status_formatted === 'not_paid' || this.stripe_invoices[key].status_formatted === 'in_payment'){
          return true;
        }
      }
    }
    return false;
  }

  /** Ritorna la lista di partecipanti di tipo tracker che sono attivi **/
  getActiveTrackers(): Partecipant[]{
    return this.participants.filter((elem: Partecipant) => elem.type === 2 && elem.isActive);
  }

  getSubUsers(): Partecipant[]{
    return this.participants.filter((elem: Partecipant) => elem.type === 1 && !elem.isOwner);
  }
}
