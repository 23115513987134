import {TypeParticipant} from "./platform";
import {ShopProducts} from "src/app/classes/common/shop-products";

export class GeneralConfig {

  timezones: string[] = [
    "Europe/Amsterdam",
    "Europe/Andorra",
    "Europe/Astrakhan",
    "Europe/Athens",
    "Europe/Belfast",
    "Europe/Belgrade",
    "Europe/Berlin",
    "Europe/Bratislava",
    "Europe/Brussels",
    "Europe/Bucharest",
    "Europe/Budapest",
    "Europe/Busingen",
    "Europe/Chisinau",
    "Europe/Copenhagen",
    "Europe/Dublin",
    "Europe/Gibraltar",
    "Europe/Guernsey",
    "Europe/Helsinki",
    "Europe/Isle_of_Man",
    "Europe/Istanbul",
    "Europe/Jersey",
    "Europe/Kaliningrad",
    "Europe/Kiev",
    "Europe/Kirov",
    "Europe/Lisbon",
    "Europe/Ljubljana",
    "Europe/London",
    "Europe/Luxembourg",
    "Europe/Madrid",
    "Europe/Malta",
    "Europe/Mariehamn",
    "Europe/Minsk",
    "Europe/Monaco",
    "Europe/Moscow",
    "Europe/Nicosia",
    "Europe/Oslo",
    "Europe/Paris",
    "Europe/Podgorica",
    "Europe/Prague",
    "Europe/Riga",
    "Europe/Rome",
    "Europe/Samara",
    "Europe/San_Marino",
    "Europe/Sarajevo",
    "Europe/Saratov",
    "Europe/Simferopol",
    "Europe/Skopje",
    "Europe/Sofia",
    "Europe/Stockholm",
    "Europe/Tallinn",
    "Europe/Tirane",
    "Europe/Tiraspol",
    "Europe/Ulyanovsk",
    "Europe/Uzhgorod",
    "Europe/Vaduz",
    "Europe/Vatican",
    "Europe/Vienna",
    "Europe/Vilnius",
    "Europe/Volgograd",
    "Europe/Warsaw",
    "Europe/Zagreb",
    "Europe/Zaporozhye",
    "Europe/Zurich",
  ];
  italy_province: any[] = [];
  nations: any[] = [];

  ios_review_version: string;
  ios_min_version: string;
  android_min_version: string;

  // Il server me li passa in platform ma io li metto direttamente qua visto che non ho più tipi di platform
  languages: any[] = [
    // {code: "de", language: "Deutsch"},
    {code: "en", language: "English"},
    // {code: "es", language: "Español"},
    // {code: "fr", language: "Français"},
    {code: "it", language: "Italiano"},
  ];
  url_activation: string;
  url_activation_app: string;
  url_buy: string;
  url_support: string;
  url_youtube: string;
  type: number;
  type_partecipant: TypeParticipant[];
  time_hint_native_app: number = 2000;

  stripe_public_key: string;

  list_dial_code_phone: {
    name: string
    dial_code: string
  }[]

  // Sono tutti i parametri condivisi con il sito web
  sito: any;

  iban: string;
  minRechargeAmount: number;
  elenco_prodotti_shop: ShopProducts[] = [];
  url_terms_of_sales: string;
  url_street_view: string;

  // this prop is a Map-like literal
  // (chose not use an actual Map() to avoid problems w/ Angular's DOM)
  // prop example -> _1: {...}
  private _type_trackerGps: any;

  constructor(data?: any, platform_type?: number){
    if(data){
      this.setData(data, platform_type);
    }
  }

  setData(data: any, platform_type: number){

    if(!platform_type) platform_type = 1; // default 1 = balinApp

    if(data.timezone && data.timezone.length > 0){
      this.timezones = data.timezone;
    }
    if(data.italy_province && data.italy_province.length > 0){
      this.italy_province = data.italy_province;
    }
    if(data.nation && data.nation.length > 0){
      this.nations = data.nation;
    }

    if(data.array_platform && data.array_platform.length > 0){
      const raw_platform: any = data.array_platform.find((elem: any) => elem.type === platform_type); // type = 1 = balinApp
      if(raw_platform){
        if(raw_platform.translate_language && raw_platform.translate_language.length > 0){
          this.languages = raw_platform.translate_language;
        }
        this.type = raw_platform.type;
        this.type_partecipant = [];
        for(let p of raw_platform.type_partecipant){
          this.type_partecipant.push(new TypeParticipant(p));
        }
        this.url_activation = raw_platform.url_activation;
        this.url_activation_app = raw_platform.url_activation_app;
        this.url_buy = raw_platform.url_buy;
        this.url_youtube = raw_platform.url_youtube;
        this.url_support = raw_platform.url_support;
        this.sito = raw_platform.sito;
        this.time_hint_native_app = raw_platform.time_hint_native_app || 2000;
        this.stripe_public_key = raw_platform.stripe_public_key;

        this.ios_review_version = raw_platform.ios_review_version;
        this.ios_min_version = raw_platform.ios_min_version;
        this.android_min_version = raw_platform.android_min_version;
        this.iban = raw_platform.iban
        this.url_terms_of_sales = raw_platform.url_terms_of_sales;
        this.url_street_view = raw_platform.url_street_view;
        this.minRechargeAmount = raw_platform.minRechargeAmount;
      }else{
        console.error(`Piattaforma con type ${platform_type} non trovata in general_config`);
      }
    }

    this.type_trackerGps = data.type_trackerGps;
    if(data.elenco_prodotti_shop){
      for(let prodotto of data.elenco_prodotti_shop ){
        this.elenco_prodotti_shop.push(new ShopProducts(prodotto));
      }
    }
    this.list_dial_code_phone = data.list_dial_code_phone;
  }

  // * accessor

  public get type_trackerGps(): any {
    return this._type_trackerGps;
  }
  public set type_trackerGps(value: any) {
    const ret = {}
    value?.forEach(el => {
      ret[`_${el.type_trackerGps}`] = el
    })
    this._type_trackerGps = ret;
  }

  // * method

  getProvinceIta(provinceCode: string){
    return this.italy_province.find((elem: any) => {
      return elem.sigla === provinceCode;
    });
  }

  getNation(nationCode: string){
    return this.nations.find((elem: any) => {
      return elem.code === nationCode;
    });
  }

  getLanguage(languageCode: string){
    return this.languages.find((elem: any) => {
      return elem.code === languageCode;
    });
  }

  getTypeTracker(type: number): TypeParticipant{
    if(this.type_partecipant){
      return this.type_partecipant.find((elem: TypeParticipant) => {
        return elem.type_trackerGps === type;
      });
    }
    return null;
  }


}


