import {
  Injectable, Pipe,
  PipeTransform
} from '@angular/core';

@Pipe({
  name: 'sortAlphabetically'
})
@Injectable()
export class SortAlphabeticallyPipe implements PipeTransform {
  transform(list: any[], fields: string[], crescent: boolean, changed: number, disabled?: boolean): any[] {
    if(disabled) return list;
    if(!list) return list;

    let no_null_list: any[] = [];
    let null_list: any[] = [];

    for(let x=0; x<list.length; x++){
      let current: string = null;
      for(let i=0; i<fields.length; i++){
        let fieldList: string[] = fields[i].split(".");
        let aus_a: any = list[x];
        for(let j=0; j<fieldList.length; j++){
          if(aus_a.hasOwnProperty(fieldList[j])){
            aus_a = aus_a[fieldList[j]];
          }else{
            aus_a = null;
            break;
          }
        }
        if(aus_a){
          current += aus_a;
        }
      }
      if(current?.length > 0){
        list[x].compareQuery = current.toLowerCase();
        no_null_list.push(list[x]);
      }else{
        null_list.push(list[x]);
      }
    }

    no_null_list.sort((a, b) => {
      if (a.compareQuery > b.compareQuery) {
        return crescent ? 1 : -1;
      }
      if (a.compareQuery < b.compareQuery) {
        return crescent ? -1 : 1;
      }
      return 0;
    });

    return no_null_list.concat(null_list);
  }
}
