import {PositionGps} from "./position-gps";
import {Partecipant} from "src/app/classes/common/partecipant";

export class Utility {

  static italia_phone_prefix: string[] = [
    '351', '352', '330', '331', '333', '334', '335', '336', '337', '338', '339', '360', '361', '362',
    '363', '366', '368', '381', '340', '341', '342', '343', '344', '345', '346', '347', '348', '349',
    '383', '320', '322', '323', '324', '327', '328', '329', '355', '380', '388', '389', '390', '391',
    '392', '393', '397', '377', '379', '371', '370', '353', '373', '375', '350', '382', '378', '376'
  ];

  /** Crea le tappe di un report e il suo sommario **/
  static parseRoute(route: PositionGps[], report: any, lang: any){
    let summary: any = {
      totalDuration: 0,
      driveDuration: 0,
      stopDuration: 0,
      totalDistance: 0
    };
    if(report){
      summary.totalDuration = report.time_total || 0;
      summary.driveDuration = report.time_trip || 0;
      summary.stopDuration = report.time_stop || 0;
      summary.totalDistance = ((report.total_distance || 0) / 1000);
    }
    // let totalDistance = 0;
    let stopsCounter: number = 0;
    for(let i=0; i<route.length; i++){
      if(i !== (route.length - 1)){
        let distance: number = ((route[i + 1].total_odometer - route[i].total_odometer) / 1000);
        route[i].distance = distance;
        // totalDistance += distance;
        route[i].durationMs = route[i + 1].timestamp_position - route[i].timestamp_position;
        // summary.totalDuration += route[i].durationMs;
        route[i].endPathTimestamp = route[i + 1].timestamp_position;
        if(route[i].durationMs <= (60000 * 60)){
          route[i].labelUnitDuration = lang['minuti'];
          const min: number = Math.floor(route[i].durationMs / 60000);
          route[i].duration = min < 10 ? ('0' + min) : ('' + min);
          const sec: number = Math.floor((route[i].durationMs - (min * 60000)) / 1000);
          route[i].duration += sec < 10 ? (':0' + sec) : (':' + sec);
        }else if(route[i].durationMs <= (60000 * 60 * 24)){
          route[i].labelUnitDuration = lang['ore'];
          const ore: number = Math.floor(route[i].durationMs / (60000 * 60));
          route[i].duration = ore < 10 ? ('0' + ore) : ('' + ore);
          const min: number = Math.floor((route[i].durationMs - (ore * 60000 * 60)) / 60000);
          route[i].duration += min < 10 ? (':0' + min) : (':' + min);
        }else{
          route[i].labelUnitDuration = lang['giorni'];
          const gg: number = Math.floor(route[i].durationMs / (60000 * 60 * 24));
          route[i].duration = '' + gg;
        }
        if(route[i].value === 1){
          route[i].pathType = 'trip';
          route[i].labelActivity = lang['distanza_percorsa'];
          // summary.driveDuration += route[i].durationMs;
        }else{
          route[i].pathType = 'stop';
          route[i].labelActivity = lang['fermata_per'];
          stopsCounter++;
        }
      }
      if(i === 0){
        route[i].divider = {
          circleType: 'special',
          addedLbl: lang['inizio']
        };
      }else if(i === (route.length - 1)){
        route[i].divider = {
          circleType: 'special',
          addedLbl: lang['fino']
        }}else{
        route[i].divider = {
          circleType: 'normal'
        };
      }
      route[i].divider.num = stopsCounter;
    }
    // summary.totalDistance = totalDistance.toFixed(1);
    if(summary.driveDuration > 0){
      summary.avgSpeed = (summary.totalDistance / (summary.driveDuration / (60000 * 60))).toFixed(2);
    }else{
      summary.avgSpeed = 0;
    }
    // summary.stopDuration = summary.totalDuration - summary.driveDuration;
    // if(summary.stopDuration < 0) summary.stopDuration = 0;
    return summary;
  }

  static parseDetailedRoute(route: PositionGps[], report: any, lang: any, summary: any ){
    if(!Object.keys(summary).length){
      summary = {
        totalDuration: 0,
        driveDuration: 0,
        stopDuration: 0,
        totalDistance: 0,
        maxSpeed: 0,
      };
    }
    if(report){
      summary.totalDuration = summary.totalDuration + (report.time_total || 0);
      summary.driveDuration = summary.driveDuration + (report.time_trip || 0);
      summary.stopDuration = summary.stopDuration + (report.time_stop || 0);
      summary.totalDistance = summary.totalDistance + ((report.total_distance || 0) / 1000);
      summary.maxSpeed = summary.maxSpeed + (report.max_speed || 0);
    }
    // let totalDistance = 0;
    let stopsCounter: number = 0;
    for(let i=0; i<route.length; i++){
      if(i !== (route.length - 1)){
        let distance: number = ((route[i + 1].total_odometer - route[i].total_odometer) / 1000);
        route[i].distance = distance;
        // totalDistance += distance;
        route[i].durationMs = route[i + 1].timestamp_position - route[i].timestamp_position;
        // summary.totalDuration += route[i].durationMs;
        route[i].endPathTimestamp = route[i + 1].timestamp_position;
        if(route[i].durationMs <= (60000 * 60)){
          route[i].labelUnitDuration = lang['minuti'];
          const min: number = Math.floor(route[i].durationMs / 60000);
          route[i].duration = min < 10 ? ('0' + min) : ('' + min);
          const sec: number = Math.floor((route[i].durationMs - (min * 60000)) / 1000);
          route[i].duration += sec < 10 ? (':0' + sec) : (':' + sec);
        }else if(route[i].durationMs <= (60000 * 60 * 24)){
          route[i].labelUnitDuration = lang['ore'];
          const ore: number = Math.floor(route[i].durationMs / (60000 * 60));
          route[i].duration = ore < 10 ? ('0' + ore) : ('' + ore);
          const min: number = Math.floor((route[i].durationMs - (ore * 60000 * 60)) / 60000);
          route[i].duration += min < 10 ? (':0' + min) : (':' + min);
        }else{
          route[i].labelUnitDuration = lang['giorni'];
          const gg: number = Math.floor(route[i].durationMs / (60000 * 60 * 24));
          route[i].duration = '' + gg;
        }
        if(route[i].value === 1){
          route[i].pathType = 'trip';
          route[i].labelActivity = lang['distanza_percorsa'];
          // summary.driveDuration += route[i].durationMs;
        }else{
          route[i].pathType = 'stop';
          route[i].labelActivity = lang['fermata_per'];
          stopsCounter++;
        }
      }
      if(i === 0){
        route[i].divider = {
          circleType: 'special',
          addedLbl: lang['inizio']
        };
      }else if(i === (route.length - 1)){
        route[i].divider = {
          circleType: 'special',
          addedLbl: lang['fino']
        }}else{
        route[i].divider = {
          circleType: 'normal'
        };
      }
      route[i].divider.num = stopsCounter;
    }
    // summary.totalDistance = totalDistance.toFixed(1);
    if(summary.driveDuration > 0){
      summary.avgSpeed = (summary.totalDistance / (summary.driveDuration / (60000 * 60))).toFixed(2);
    }else{
      summary.avgSpeed = 0;
    }
    // summary.stopDuration = summary.totalDuration - summary.driveDuration;
    // if(summary.stopDuration < 0) summary.stopDuration = 0;
    return summary;
  }

  static parseDetailedRouteIngressi(ingressi: PositionGps[], report: any, lang: any, participant: Partecipant){

    let summary: any = {
      totalDin1_1: 0,
      totalDin1_0: 0,
      driveDuration: 0,
    };

    if(report){
      summary.totalDin1_1 = report.time_total_din1_1 || 0;
      summary.totalDin1_0 = report.time_total_din1_0 || 0;
      summary.driveDuration = report.time_trip || 0;
    }

    let stopsCounter: number = 0;

    for(let i=0; i<ingressi.length; i++){
      stopsCounter++
      if(i !== (ingressi.length - 1)){
        let distance: number = ((ingressi[i + 1].total_odometer - ingressi[i].total_odometer) / 1000);
        ingressi[i].distance = distance;
        ingressi[i].durationMs = ingressi[i + 1].timestamp_position - ingressi[i].timestamp_position;
        ingressi[i].endPathTimestamp = ingressi[i + 1].timestamp_position;
        if(ingressi[i].durationMs <= (60000 * 60)){
          ingressi[i].labelUnitDuration = lang['minuti'];
          const min: number = Math.floor(ingressi[i].durationMs / 60000);
          ingressi[i].duration = min < 10 ? ('0' + min) : ('' + min);
          const sec: number = Math.floor((ingressi[i].durationMs - (min * 60000)) / 1000);
          ingressi[i].duration += sec < 10 ? (':0' + sec) : (':' + sec);
        }else if(ingressi[i].durationMs <= (60000 * 60 * 24)){
          ingressi[i].labelUnitDuration = lang['ore'];
          const ore: number = Math.floor(ingressi[i].durationMs / (60000 * 60));
          ingressi[i].duration = ore < 10 ? ('0' + ore) : ('' + ore);
          const min: number = Math.floor((ingressi[i].durationMs - (ore * 60000 * 60)) / 60000);
          ingressi[i].duration += min < 10 ? (':0' + min) : (':' + min);
        }else{
          ingressi[i].labelUnitDuration = lang['giorni'];
          const gg: number = Math.floor(ingressi[i].durationMs / (60000 * 60 * 24));
          ingressi[i].duration = '' + gg;
        }

        if(ingressi[i].value === 1){
          ingressi[i].pathType = 'trip';
          ingressi[i].labelActivity = `${participant.din1_name} ${participant.din1_label_1}` ;
        }else{
          ingressi[i].pathType = 'stop';
          ingressi[i].labelActivity = `${participant.din1_name} ${participant.din1_label_0}` ;
        }
      }
      if(i === 0){
        ingressi[i].divider = {
          circleType: 'special',
          addedLbl: lang['inizio']
        };
      }else if(i === (ingressi.length - 1)){
        ingressi[i].divider = {
          circleType: 'special',
          addedLbl: lang['fino']
        }}else{
        ingressi[i].divider = {
          circleType: 'normal'
        };
      }

      ingressi[i].divider.num = stopsCounter;
    }

    return summary
  }

  static validateEmail(email: string): boolean{
    // let re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let re = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    return re.test(String(email).toLowerCase());
  }

  // Valida solo telefoni scritti in formato +39...
  static validatePhone(phone: string): boolean{
    let re = new RegExp('^\\d+$');
    if(!phone || phone.length < 6 || phone[0] !== '+') return false;
    const aus: string = phone.substr(1, phone.length);
    return re.test(aus);
  }

  // Pulisce un numero di telefono e gli aggiunge il +39 se necessario
  static sanitizePhone(phone: string): string{
    phone = phone.replace(/[^\d+]+/, '');
    if(phone.length < 0) return null;
    if(phone[0] === '+') return phone;
    for(let dial of Utility.italia_phone_prefix){
      if(phone.indexOf(dial) === 0){
        return `+39${phone}`;
      }
    }
    return null;
  }

  // Ritorna data in formato YYYY-MM-DDTHH:mm
  static toDateString(date: Date): string {
    return (date.getFullYear().toString() + '-'
      + ("0" + (date.getMonth() + 1)).slice(-2) + '-'
      + ("0" + (date.getDate())).slice(-2))
      + 'T' + date.toTimeString().slice(0,5);
  }

  // Ritorna data in formato YYYY-MM-DD
  static toIsoDateString(date: Date): string {
    return (date.getFullYear().toString() + '-'
      + ("0" + (date.getMonth() + 1)).slice(-2) + '-'
      + ("0" + (date.getDate())).slice(-2));
  }

  // Ritorna time in formato HH:mm
  static toIsoTimeString(date: Date): string {
    return date.toTimeString().slice(0,5);
  }

  static wait = (delay, ...args) => new Promise(resolve => setTimeout(resolve, delay, ...args));

  // Seleziona la corretta icona per il marker con l'headeang corretto
  static selectMarkerIcons(movingMarkers: any, stopMarkers: any, inMoving: boolean, angle: number, engine_state?: boolean) {
    // 1. Scelta icone verdi o rosse a seconda dello stato del veicolo;
    let obj: any = stopMarkers;
    if(inMoving) obj = movingMarkers;
    // 2. Cerco l'immagine con angolazione più prossima;
    for (let k in obj) {
      if (obj[k].min < obj[k].max) { // Sono tutti i casi diversi dal primo
        if (obj[k].max > angle && obj[k].min <= angle){
          if(engine_state !== undefined){
            return engine_state ? obj[k].url_in1_1 : obj[k].url_in1_0
          }
          return obj[k].url;
        }
      }else{ // Quando si trova nel primo intervallo a cavallo dello 0
        if ((obj[k].max > angle && 0 <= angle) || (obj[k].min <= angle && 360 > angle)){
          if(engine_state !== undefined){
            return engine_state ? obj[k].url_in1_1 : obj[k].url_in1_0
          }
          return obj[k].url;
        }
      }
    }
  }

  // Prende un numero di versione scritto in formato '0.0.1' e lo trasforma in un numero confrontabile moltiplicando ogni spazio per 10 a seconda della posizione 1.1.1 = (1x100) + (1*10) + 1
  static parseVersionNumber(version: string): number{
    if(!version) return 0;
    let parts: string[] = version.split('.');
    let moltiplicatore: number = 1;
    let total = 0;
    for(let i=parts.length - 1; i>=0; i--){
      const num: number = parseInt(parts[i]);
      total += num * moltiplicatore;
      moltiplicatore = moltiplicatore * 10;
    }
    return total;
  }

  // Passando un language code (es it, it-IT) ritorna sempre un codice a due lettere (es. it)
  static parseLanguageCode(code: string): string{
    return code.substr(0, 2);
  }

  static parseRouteReportDettagliato(route: any, summary: any, lang: any){

    // let totalDistance = 0;
    let stopsCounter: number = 0;
    for(let i=0; i<route.length; i++){
      if(i !== (route.length - 1)){
        let distance: number = ((route[i + 1].total_odometer - route[i].total_odometer) / 1000);
        route[i].distance = distance;
        // totalDistance += distance;
        route[i].durationMs = route[i + 1].timestamp_position - route[i].timestamp_position;
        // summary.totalDuration += route[i].durationMs;
        route[i].endPathTimestamp = route[i + 1].timestamp_position;
        if(route[i].durationMs <= (60000 * 60)){
          route[i].labelUnitDuration = lang['minuti'];
          const min: number = Math.floor(route[i].durationMs / 60000);
          route[i].duration = min < 10 ? ('0' + min) : ('' + min);
          const sec: number = Math.floor((route[i].durationMs - (min * 60000)) / 1000);
          route[i].duration += sec < 10 ? (':0' + sec) : (':' + sec);
        }else if(route[i].durationMs <= (60000 * 60 * 24)){
          route[i].labelUnitDuration = lang['ore'];
          const ore: number = Math.floor(route[i].durationMs / (60000 * 60));
          route[i].duration = ore < 10 ? ('0' + ore) : ('' + ore);
          const min: number = Math.floor((route[i].durationMs - (ore * 60000 * 60)) / 60000);
          route[i].duration += min < 10 ? (':0' + min) : (':' + min);
        }else{
          route[i].labelUnitDuration = lang['giorni'];
          const gg: number = Math.floor(route[i].durationMs / (60000 * 60 * 24));
          route[i].duration = '' + gg;
        }
        if(route[i].value === 1){
          route[i].pathType = 'trip';
          route[i].labelActivity = lang['distanza_percorsa'];
          // summary.driveDuration += route[i].durationMs;
        }else{
          route[i].pathType = 'stop';
          route[i].labelActivity = lang['fermata_per'];
          stopsCounter++;
        }
      }
      if(i === 0){
        route[i].divider = {
          circleType: 'special',
          addedLbl: lang['inizio']
        };
      }else if(i === (route.length - 1)){
        route[i].divider = {
          circleType: 'special',
          addedLbl: lang['fino']
        }}else{
        route[i].divider = {
          circleType: 'normal'
        };
      }
      route[i].divider.num = stopsCounter;
    }
    // summary.totalDistance = totalDistance.toFixed(1);
    if(summary.driveDuration > 0){
      summary.avgSpeed = (summary.totalDistance / (summary.driveDuration / (60000 * 60))).toFixed(2);
    }else{
      summary.avgSpeed = 0;
    }
    // summary.stopDuration = summary.totalDuration - summary.driveDuration;
    // if(summary.stopDuration < 0) summary.stopDuration = 0;
    return;
  }



}
