import {Injectable} from '@angular/core';
import {
  HttpClient,
  HttpHeaders
} from "@angular/common/http";

import {AuthService} from "./auth.service";
import {AlertController} from "@ionic/angular";
import {ViewService} from "./view.service";
import {timeout} from "rxjs/operators";
import {
  Partecipant
} from "../../classes/common/partecipant";
import {AlarmPoi} from "../../classes/common/alarm-poi";
import {
  Organization
} from "../../classes/common/organization";
import {Storage} from '@ionic/storage-angular';
import {ShippingAddress} from "src/app/classes/common/order";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  static firebase: any;
  static platformType: number;

  baseUrl: string ;
  communicationBaseUrl: string ;
  isDevelopment: boolean = false;
  apiChanged: boolean;

  upEventListener: any;
  upEventCount: number;

  constructor(
    private http: HttpClient,
    public storage: Storage,
    private auth: AuthService,
    private alertController: AlertController
  ) {

  }

  /** Imposta e verifica l'API URL **/
  async setModality(url: string, communication_url: string) {
    try {
      this.baseUrl = url;
      this.communicationBaseUrl = communication_url;
      await this.storage.create();
      let baseUrl: string = await this.storage.get("baseUrl") as string;
      if (baseUrl && baseUrl !== this.baseUrl) {
        this.apiChanged = true;
        ViewService.updateView.next();
      }
      if (baseUrl) {
        this.isDevelopment = true;
        this.baseUrl = baseUrl;
        console.error("da storage " + this.baseUrl);
      }
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Setta l'header con il il token per l'autenticazione dello user
   * @private
   */
  private setAuthTokenHeader(): HttpHeaders{
    return new HttpHeaders().set('authToken', AuthService.authTokenInUrl)
  }

  /**
   * Setta l'header con le credenziali volute
   * @param {string} username
   * @param {string} password
   * @returns {HttpHeaders}
   */
  private setHeaders(username: string, password: string): HttpHeaders {
    return new HttpHeaders().set('Authorization', 'Basic ' + btoa(username + ':' + password));
  }

  /**
   * Setta l'header prendendo ogni volta il token di firebase (che nel tempo potrebbe scadere)
   * @returns {Observable<any>}
   */
  private getFirebaseHeader(forcedEmail?: string) {
    return new Promise((resolve, reject) => {
      if(!AuthService.authTokenInUrl){
        this.auth.getIdToken().then((token: string) => {
          return resolve(<HttpHeaders>this.setHeaders(forcedEmail || AuthService.getEmail(), token));
        }).catch((err: any) => {
          return reject(err);
        });
      }else{
        return resolve(<HttpHeaders>this.setAuthTokenHeader())
      }
    });
  }


  async upEvent(event: any) {
    if (this.upEventListener) clearTimeout(this.upEventListener);
    this.upEventListener = setTimeout(() => {
      this.upEventCount = 0;
    }, 400);
    this.upEventCount++;
    if (this.upEventCount >= 5) {
      this.upEventCount = 0;
      console.error('upEvent');
      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        backdropDismiss: false,
        message: '<small>Corrente:<br><b>' + this.baseUrl + '</b></small>',
        inputs: [
          {
            name: 'manualUrl',
            type: 'text',
            placeholder: 'Url manuale'
          }
        ],
        buttons: [
          {
            text: 'Usa URL manuale',
            handler: (data) => {
              if (data && data.manualUrl) {
                this.changeBaseUrl(data.manualUrl);
              }
            }
          },
          {
            text: 'white-label-dev-635e0.ey.r.appspot.com',
            handler: () => {
              this.changeBaseUrl('https://white-label-dev-635e0.ey.r.appspot.com/webApp/');
            }
          },
          {
            text: 'api-dev.balin.app',
            handler: () => {
              this.changeBaseUrl('https://api-dev.balin.app/webApp/');
            }
          },
          {
            text: 'api.495318.net',
            handler: () => {
              this.changeBaseUrl('https://api.495318.net/webApp/');
            }
          },
          {
            text: 'Annulla',
            role: 'cancel',
            handler: () => {
              console.log('Confirm Ok');
            }
          }
        ]
      });
      await alert.present();
    }
  }

  private async changeBaseUrl(url) {
    await this.storage.set('baseUrl', url);
    this.baseUrl = url;
    window.location.reload();
    // setTimeout(() => {
    //   window.location.reload();
    // }, 5000);
  }

  getLogin(){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(this.baseUrl + 'webApp/login?platform_type=' + ApiService.platformType, options).pipe(timeout(10000)).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }

  getConfigInfo() {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(new HttpHeaders()),
          timeout: 1
        };
        this.http.get(this.baseUrl + 'webApp/generalParameter?platform_type=' + ApiService.platformType, options).pipe(timeout(10000)).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  getMicroserviceInfo() {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(new HttpHeaders()),
          timeout: 1
        };
        this.http.get(this.baseUrl + 'webApp/microservices?platform_type=' + ApiService.platformType, options).pipe(timeout(10000)).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  patchSpeedAlarm(idParticipant: string, idTarget: string, participant: Partecipant) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/partecipant/' + idTarget + '?platform_type=' + ApiService.platformType;
        url += '&idPartecipant=' + idParticipant;
        let data: any = {
          speed_alarm_enable: participant.speed_alarm_enable,
          speed_alarm_value: participant.speed_alarm_value,
          speed_alarm_hour_start: participant.speed_alarm_hour_start,
          speed_alarm_minute_start: participant.speed_alarm_minute_start,
          speed_alarm_hour_stop: participant.speed_alarm_hour_stop,
          speed_alarm_minute_stop: participant.speed_alarm_minute_stop,
          speed_alarm_day: participant.speed_alarm_day,
          speed_alarm_email: participant.speed_alarm_email,
          speed_alarm_partecipant: participant.speed_alarm_partecipant
        };
        this.http.patch(url, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  patchInput1Alarm(idParticipant: string, idTarget: string, participant: Partecipant) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/partecipant/' + idTarget + '?platform_type=' + ApiService.platformType;
        url += '&idPartecipant=' + idParticipant;
        let data: any = {
          din1_alarm_enable: participant.din1_alarm_enable,
          din1_alarm_value: participant.din1_alarm_value,
          din1_alarm_hour_start: participant.din1_alarm_hour_start,
          din1_alarm_minute_start: participant.din1_alarm_minute_start,
          din1_alarm_hour_stop: participant.din1_alarm_hour_stop,
          din1_alarm_minute_stop: participant.din1_alarm_minute_stop,
          din1_alarm_day: participant.din1_alarm_day,
          din1_alarm_email: participant.din1_alarm_email,
          din1_alarm_partecipant: participant.din1_alarm_partecipant
        };
        this.http.patch(url, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  patchOfflineAlarm(idParticipant: string, idTarget: string, participant: Partecipant) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/partecipant/' + idTarget + '?platform_type=' + ApiService.platformType;
        url += '&idPartecipant=' + idParticipant;
        let data: any = {
          offline_alarm_enable: participant.offline_alarm_enable,
          offline_alarm_value: participant.offline_alarm_value,
          offline_alarm_hour_start: participant.offline_alarm_hour_start,
          offline_alarm_minute_start: participant.offline_alarm_minute_start,
          offline_alarm_hour_stop: participant.offline_alarm_hour_stop,
          offline_alarm_minute_stop: participant.offline_alarm_minute_stop,
          offline_alarm_day: participant.offline_alarm_day,
          offline_alarm_email: participant.offline_alarm_email,
          offline_alarm_partecipant: participant.offline_alarm_partecipant
        };
        this.http.patch(url, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  patchMovementAlarm(idParticipant: string, idTarget: string, participant: Partecipant) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/partecipant/' + idTarget + '?platform_type=' + ApiService.platformType;
        url += '&idPartecipant=' + idParticipant;
        let data: any = {
          movement_alarm_enable: participant.movement_alarm_enable,
          movement_alarm_hour_start: participant.movement_alarm_hour_start,
          movement_alarm_minute_start: participant.movement_alarm_minute_start,
          movement_alarm_hour_stop: participant.movement_alarm_hour_stop,
          movement_alarm_minute_stop: participant.movement_alarm_minute_stop,
          movement_alarm_day: participant.movement_alarm_day,
          movement_alarm_email: participant.movement_alarm_email,
          movement_alarm_partecipant: participant.movement_alarm_partecipant
        };
        this.http.patch(url, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  patchUnplugAlarm(idParticipant: string, idTarget: string, participant: Partecipant) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/partecipant/' + idTarget + '?platform_type=' + ApiService.platformType;
        url += '&idPartecipant=' + idParticipant;
        let data: any = {
          unplug_alarm_enable: participant.unplug_alarm_enable,
          unplug_alarm_hour_start: participant.unplug_alarm_hour_start,
          unplug_alarm_minute_start: participant.unplug_alarm_minute_start,
          unplug_alarm_hour_stop: participant.unplug_alarm_hour_stop,
          unplug_alarm_minute_stop: participant.unplug_alarm_minute_stop,
          unplug_alarm_day: participant.unplug_alarm_day,
          unplug_alarm_email: participant.unplug_alarm_email,
          unplug_alarm_partecipant: participant.unplug_alarm_partecipant
        };
        this.http.patch(url, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  patchParkingAlarm(idParticipant: string, idTarget: string, participant: Partecipant) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/partecipant/' + idTarget + '?platform_type=' + ApiService.platformType;
        url += '&idPartecipant=' + idParticipant;
        let data: any = {
          parking_alarm_enable: participant.parking_alarm_enable,
          parking_alarm_hour_start: participant.parking_alarm_hour_start,
          parking_alarm_minute_start: participant.parking_alarm_minute_start,
          parking_alarm_hour_stop: participant.parking_alarm_hour_stop,
          parking_alarm_minute_stop: participant.parking_alarm_minute_stop,
          parking_alarm_day: participant.parking_alarm_day,
          parking_alarm_email: participant.parking_alarm_email,
          parking_alarm_partecipant: participant.parking_alarm_partecipant
        };
        this.http.patch(url, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  getPositionsGps(idParticipant: string, idParticipant_search: string, start: number, stop: number, limit: number, skip: number, avlId?: number) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/positionGps?platform_type=' + ApiService.platformType;
        url += '&idPartecipant=' + idParticipant;
        url += '&idPartecipant_search=' + idParticipant_search;
        url += '&timestampStart=' + start;
        url += '&timestampStop=' + stop;
        url += '&limit=' + limit;
        url += '&skip=' + skip;
        if (avlId) {
          url += '&avl_id=' + avlId;
        }
        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  getRoute(idParticipant: string, idParticipant_search: string, start: number, stop: number, getIngressi?: boolean) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/positionGps/report_bigtable?platform_type=' + ApiService.platformType;
        // if(start < 1712275200000){ // Per test e fa si che chieda il report su bigtable se abbiamo i dati
        //   url = this.baseUrl + 'webApp/positionGps/report?platform_type=' + ApiService.platformType;
        // }
        url += '&idPartecipant=' + idParticipant;
        url += '&idPartecipant_search=' + idParticipant_search;
        url += '&timestampStart=' + start;
        url += '&timestampStop=' + stop;
        if(getIngressi) url += '&getIngressi=' + getIngressi;
        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  getReportDettagliato(idParticipant: string, idParticipant_search: string, start: number, stop: number, treshold_partenza?: number, get_storico?: boolean, tempo_sosta?: number, richiedi_ingressi?: boolean) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/positionGps/report_dettagliato_bigtable?platform_type=' + ApiService.platformType;
        // if(start < 1712275200000){ // Per test e fa si che chieda il report su bigtable se abbiamo i dati
        //   url = this.baseUrl + 'webApp/positionGps/report_dettagliato?platform_type=' + ApiService.platformType;
        // }
        url += '&idPartecipant=' + idParticipant;
        url += '&idPartecipant_search=' + idParticipant_search;
        url += '&timestampStart=' + start;
        url += '&timestampStop=' + stop;
        if(treshold_partenza)  url += '&tresholdPartenza=' + treshold_partenza;
        if(tempo_sosta)  url += '&tempoSosta=' + tempo_sosta;
        if(get_storico)  url += '&getHistory=' + get_storico;
        if(richiedi_ingressi)  url += '&getIngressi=' + richiedi_ingressi;

        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  getReportForward(idParticipant: string, idParticipant_search: string, start: number, stop: number, treshold_partenza?: number, get_storico?: boolean, tempo_sosta?: number, richiedi_ingressi?: boolean) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/positionGps/report_forward?platform_type=' + ApiService.platformType;
        url += '&idPartecipant=' + idParticipant;
        url += '&idPartecipant_search=' + idParticipant_search;
        url += '&timestampStart=' + start;
        url += '&timestampStop=' + stop;
        if(treshold_partenza)  url += '&tresholdPartenza=' + treshold_partenza;
        if(tempo_sosta)  url += '&tempoSosta=' + tempo_sosta;
        if(get_storico)  url += '&getHistory=' + get_storico;
        if(richiedi_ingressi)  url += '&getIngressi=' + richiedi_ingressi;

        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }


  getAddress(idParticipant: string, lat: number, long: number) {
    return new Promise(async (resolve, reject) => {
      try {
        let res: any = await this.storage.get(lat + '_' + long);
        if (res) return resolve(res);
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/utility/locationiq/reverseGeocoding?platform_type=' + ApiService.platformType;
        url += '&idPartecipant=' + idParticipant;
        url += '&lat=' + lat;
        url += '&longi=' + long;
        this.http.get(url, options).subscribe((res: any) => {
          // resolve(res);
          if (res && res.display_name) this.storage.set(lat + '_' + long, res.display_name).then(() => {
          }).catch(() => {
          });
          resolve(res.display_name);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  getCoordinates(idParticipant: string, address: string) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/utility/locationiq/geocoding?platform_type=' + ApiService.platformType;
        url += '&idPartecipant=' + idParticipant;
        url += '&address=' + address;
        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  getOrganization(idParticipant: string, idOrganization: string) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/organization/' + idOrganization + '?platform_type=' + ApiService.platformType;
        url += '&idPartecipant=' + idParticipant;
        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  getParticipant(idParticipant: string, idPartecipantToRetrieve: string) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/partecipant/' + idPartecipantToRetrieve + '?platform_type=' + ApiService.platformType;
        url += '&idPartecipant=' + idParticipant;
        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  getParticipantsBucket(idParticipant: string, ids: string[]) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/partecipant/bucket?platform_type=' + ApiService.platformType;
        url += '&idPartecipant=' + idParticipant;
        this.http.post(url, {ids: ids}, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  postParticipant(idParticipant: string, participant: Partecipant) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/partecipant/user?platform_type=' + ApiService.platformType;
        url += '&idPartecipant=' + idParticipant;
        let data: any = {
          typeUser: participant.type,
          email: participant.email,
          list_partecipant: participant.list_partecipant,
          functionality_alarm: participant.functionality_alarm,
          functionality_veichle: participant.functionality_vehicle,
          functionality_billing: participant.functionality_billing,
          functionality_map: participant.functionality_map,
          functionality_historical: participant.functionality_historical,
          functionality_report: participant.functionality_report,
          functionality_poi: participant.functionality_poi,
          functionality_activation: participant.functionality_activation
        };
        this.http.post(url, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  patchParticipant(idParticipant: string, participant: Partecipant) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/partecipant/' + participant._id + '?platform_type=' + ApiService.platformType;
        url += '&idPartecipant=' + idParticipant;
        let data: any = {
          typeUser: participant.type,
          email: participant.email,
          list_partecipant: participant.list_partecipant,
          functionality_alarm: participant.functionality_alarm,
          functionality_veichle: participant.functionality_vehicle,
          functionality_billing: participant.functionality_billing,
          functionality_map: participant.functionality_map,
          functionality_historical: participant.functionality_historical,
          functionality_report: participant.functionality_report,
          functionality_poi: participant.functionality_poi,
          functionality_activation: participant.functionality_activation
        };
        this.http.patch(url, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  deleteAccount(idOrganization: string, idParticipant: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}webApp/organization/${idOrganization}?idPartecipant=${idParticipant}`;
        this.http.delete(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    })
  }

  patchApiSettingsParticipant(idParticipant: string, api_enabled: boolean, generate_api_token: boolean){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/partecipant/api_settings/' + idParticipant + '?platform_type=' + ApiService.platformType;
        url += '&idPartecipant=' + idParticipant;
        let data: any = {
          api_enabled: api_enabled,
          generate_api_token: generate_api_token
        };
        this.http.patch(url, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  patchChangeEmailMe(idParticipant: string, oldEmail: string, newEmail: string) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader(oldEmail))
        };
        let url: string = this.baseUrl + 'webApp/partecipant/' + idParticipant + '?platform_type=' + ApiService.platformType;
        url += '&idPartecipant=' + idParticipant;
        let data: any = {
          email: newEmail
        };
        this.http.patch(url, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  patchParticipantRegister(idParticipant: string, idTargetPartecipant: string, name: string, label: number, odometer: number) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/partecipant/' + idTargetPartecipant + '?platform_type=' + ApiService.platformType;
        url += '&idPartecipant=' + idParticipant;
        let data: any = {
          label_string: name,
          label_number: label,
          total_odometer: odometer
        };
        this.http.patch(url, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  patchParticipantDin1Info(myParticipant: string, idParticipant: string, din1_name: string, din1_label_0: string, din1_label_1: string, din1_stop_enabled: boolean, din1_moving_enabled: boolean) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}webApp/partecipant/din1_info/${idParticipant}?idPartecipant=${myParticipant}&platform_type=${ApiService.platformType}`;
        let data: any = {
          din1_name,
          din1_label_0,
          din1_label_1,
          din1_stop_enabled,
          din1_moving_enabled
        };
        this.http.patch(url, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  deleteParticipant(idParticipant: string, idPartecipantToDelete: string) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/partecipant/' + idPartecipantToDelete + '?platform_type=' + ApiService.platformType;
        url += '&idPartecipant=' + idParticipant;
        this.http.delete(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  getPoi(idParticipant: string, idPoi: string) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/poi/' + idPoi + '?platform_type=' + ApiService.platformType;
        url += '&idPartecipant=' + idParticipant;
        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  getPois(idParticipant: string, skip: number, limit: number) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/poi?platform_type=' + ApiService.platformType;
        url += '&idPartecipant=' + idParticipant;
        url += '&limit=' + (limit || 500);
        url += '&skip=' + (skip || 0);
        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  getAlarmsPoi(idParticipant: string, skip: number, limit: number) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/alarmPoi?platform_type=' + ApiService.platformType;
        url += '&idPartecipant=' + idParticipant;
        url += '&limit=' + (limit || 500);
        url += '&skip=' + (skip || 0);
        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  postAlarmsPoi(idParticipant: string, alarm: AlarmPoi) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/alarmPoi?platform_type=' + ApiService.platformType;
        url += '&idPartecipant=' + idParticipant;
        let data: any = {
          idPartecipant: alarm.idPartecipant,
          idPoi: alarm.idPoi,
          idOrganization: alarm.idOrganization,
          onEntry: alarm.onEntry,
          onExit: alarm.onExit,
          emails: alarm.emails,
          push: alarm.push,
          alarmPoi_day: alarm.alarmPoi_day,
          alarmPoi_hour_start: alarm.alarmPoi_hour_start,
          alarmPoi_minute_start: alarm.alarmPoi_minute_start,
          alarmPoi_hour_stop: alarm.alarmPoi_hour_stop,
          alarmPoi_minute_stop: alarm.alarmPoi_minute_stop
        };
        console.error(data);
        this.http.post(url, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  patchAlarmsPoi(idParticipant: string, alarm: AlarmPoi) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/alarmPoi/' + alarm._id + '?platform_type=' + ApiService.platformType;
        url += '&idPartecipant=' + idParticipant;
        let data: any = {
          onEntry: alarm.onEntry,
          onExit: alarm.onExit,
          emails: alarm.emails,
          push: alarm.push,
          alarmPoi_day: alarm.alarmPoi_day,
          alarmPoi_hour_start: alarm.alarmPoi_hour_start,
          alarmPoi_minute_start: alarm.alarmPoi_minute_start,
          alarmPoi_hour_stop: alarm.alarmPoi_hour_stop,
          alarmPoi_minute_stop: alarm.alarmPoi_minute_stop
        };
        this.http.patch(url, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  deleteAlarmPoi(idParticipant: string, idAlarm: string) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/alarmPoi/' + idAlarm + '?platform_type=' + ApiService.platformType;
        url += '&idPartecipant=' + idParticipant;
        this.http.delete(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  patchOrganization(idOrganization: string, idParticipant: string, language: string, timezone: string, name?: string, send_welcome?: boolean) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/organization/' + idOrganization + '?idPartecipant=' + idParticipant + '&platform_type=' + ApiService.platformType;
        let data: any = {
          translate_language: language,
          timezone: timezone,
          name: name,
          send_welcome: send_welcome
        };
        this.http.patch(url, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  patchOrganizationPhone_contacts(
    organization__id: string,
    participant__id: string,
    body: {
      phone_contacts: {
        dial_code: string
        partial_phone: string
        label: string
      }[]
    },
  ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };

        this.http.patch(
          `${this.baseUrl}webapp/organization/phone_contacts/${
            organization__id}?idPartecipant=${participant__id}`
          , body
          , options
        ).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  postCreatePoi(idParticipant: string, lat: number, lng: number, name: string, labelNumber: number, radius: number) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/poi?platform_type=' + ApiService.platformType;
        url += '&idPartecipant=' + idParticipant;
        let data: any = {
          name: name,
          label_number: labelNumber,
          position: {
            type: 'Point',
            coordinates: [lng, lat]
          },
          radius: radius
        };
        this.http.post(url, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  patchCreatePoi(idParticipant: string, idPoi: string, lat: number, lng: number, name: string, labelNumber: number, radius: number) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/poi/' + idPoi + '?platform_type=' + ApiService.platformType;
        url += '&idPartecipant=' + idParticipant;
        let data: any = {
          name: name,
          label_number: labelNumber,
          position: {
            type: 'Point',
            coordinates: [lng, lat]
          },
          radius: radius
        };
        this.http.patch(url, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  deletePoi(idParticipant: string, idPoi: string) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/poi/' + idPoi + '?platform_type=' + ApiService.platformType;
        url += '&idPartecipant=' + idParticipant;
        this.http.delete(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  postTicket(idParticipant: string, name: string, email: string, subject: string, description: string, phone?: string, filename?: string, filename_base64Url?: string) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/zendesk/ticket?platform_type=' + ApiService.platformType;
        url += '&idPartecipant=' + idParticipant;
        let data: any = {
          name: name,
          email: email,
          phone: phone,
          subject: subject,
          description: description
        };
        this.http.post(url, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }



  getTicketComments(idParticipant: string, idTicket: number) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/zendesk/ticket/comment/' + idTicket + '?platform_type=' + ApiService.platformType;
        url += '&idPartecipant=' + idParticipant;
        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  patchTicket(idParticipant: string, idTicket: number, authorId: number, request: string) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/zendesk/ticket/' + idTicket + '?platform_type=' + ApiService.platformType;
        url += '&idPartecipant=' + idParticipant;
        let data: any = {
          request: request,
          author_id: authorId
        };
        this.http.patch(url, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  patchBillingUser(idParticipant: string, organization: Organization) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/organization/billing/' + organization._id + '?platform_type=' + ApiService.platformType;
        url += '&idPartecipant=' + idParticipant;
        let data: any = {
          billing_type: organization.billing_type,
          billing_location: organization.billing_location,
          billing_companyName: organization.billing_companyName,
          billing_name: organization.billing_name,
          billing_surname: organization.billing_surname,
          billing_address: organization.billing_address,
          billing_zip: organization.billing_zip,
          billing_city: organization.billing_city,
          billing_province: organization.billing_province,
          billing_country: organization.billing_country,
          billing_mail: organization.billing_mail,
          billing_phone: organization.billing_phone,
          billing_vat: organization.billing_vat,
          billing_mailPEC: organization.billing_mailPEC,
          billing_sdiCode_fatturaElettronica: organization.billing_sdiCode_fatturaElettronica,
          billing_codiceFiscale: organization.billing_codiceFiscale
        };
        this.http.patch(url, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  getBillingPortalUrl(idParticipant: string, return_url: string) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/organization/billing_stripe_portal_url';
        url += `?idPartecipant=${idParticipant}&return_url=${return_url}`;
        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  getCommandsTracker(idParticipant: string, idTargetParticipant: string) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/commandTrackerGps/' + idTargetParticipant;
        url += '?idPartecipant=' + idParticipant;
        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  postCommandPosition(idParticipant: string, idTargetParticipant: string) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/commandTrackerGps/get_position/' + idTargetParticipant;
        url += '?idPartecipant=' + idParticipant;
        this.http.post(url, {}, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  postResetTracker(idParticipant: string, idTargetParticipant: string) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/commandTrackerGps/reset_tracker/' + idTargetParticipant;
        url += '?idPartecipant=' + idParticipant;
        this.http.post(url, {}, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  postStopEngine(idParticipant: string, idTargetParticipant: string, value: number, notifyOnSuccess: boolean, notifyOnError: boolean, emails: string[], push: any[], max_retry: number) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let data: any = {
          notifyOnSuccess: notifyOnSuccess,
          notifyOnError: notifyOnError,
          emails: emails,
          push: push,
          max_retry: max_retry
        };
        let url: string = this.baseUrl + 'webApp/commandTrackerGps/stop_engine/' + idTargetParticipant + '/' + value;
        url += '?idPartecipant=' + idParticipant;
        this.http.post(url, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  getNotificationsCount(idParticipant: string) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/notification/countUnRead?platform_type=' + ApiService.platformType;
        url += '&idPartecipant=' + idParticipant;
        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  getNotifications(idParticipant: string, skip: number, limit: number, lang?: string) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/notification?platform_type=' + ApiService.platformType;
        url += '&idPartecipant=' + idParticipant;
        url += '&limit=' + (limit || 500);
        url += '&skip=' + (skip || 0);
        if (lang) url += '&lang=' + lang;
        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  getNotification(idParticipant: string, idNotification: string, lang?: string) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/notification/' + idNotification + '?platform_type=' + ApiService.platformType;
        url += '&idPartecipant=' + idParticipant;
        if (lang) url += '&lang=' + lang;
        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  patchNotification(idParticipant: string, idNotification: string, isRead: boolean) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/notification/' + idNotification + '?platform_type=' + ApiService.platformType;
        url += '&idPartecipant=' + idParticipant;
        let data: any = {
          isRead: isRead
        };
        this.http.patch(url, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  patchNotificationSetAll(idParticipant: string, isRead: boolean) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/notification/setAllReady?platform_type=' + ApiService.platformType;
        url += '&idPartecipant=' + idParticipant;
        let data: any = {
          setAllReady: isRead
        };
        this.http.patch(url, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  getClientSecretStripe(idOrganization: string, idParticipant: string) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/stripe/setupIntents/client_secret/' + idOrganization + '?platform_type=' + ApiService.platformType;
        url += '&idPartecipant=' + idParticipant;
        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  payStripeInvoices(idParticipant: string, paymentId: string, invoiceId: string) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/stripe/invoice/pay/credit_card?idPartecipant=' + idParticipant;

        let data: any = {
          invoice_id: invoiceId,
          payment_method: paymentId,
        };
        this.http.post(url, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  payStripeInvoicesWithBankTransfer(idParticipant: string, invoiceId: string, amount_to_paid_bank_transfer: number) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/stripe/invoice/pay/bank_transfer/request?idPartecipant=' + idParticipant;

        let data: any = {
          invoice_id: invoiceId,
          amount_to_paid_bank_transfer: amount_to_paid_bank_transfer
        };
        this.http.post(url, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  deleteStripeInvoiceBankTransfer(idParticipant: string, invoiceId: string) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}webApp/stripe/invoice/pay/bank_transfer/${invoiceId}?idPartecipant=${idParticipant}`;

        this.http.delete(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  payStripeInvoicesWithBalance(idParticipant: string, invoiceId: string) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/stripe/invoice/pay/balance?idPartecipant=' + idParticipant;

        let data: any = {
          invoice_id: invoiceId,
        };
        this.http.post(url, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  buyCustomerBalance(idParticipant: string, paymentId: string, quantity: number, paid_with_bank_transfer: boolean) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/stripe/customer/balance/buy?idPartecipant=' + idParticipant;

        let data: any = {
          quantity: quantity,
          with_bank_transfer: paid_with_bank_transfer,
          payment_method: paymentId,
        };
        this.http.post(url, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  patchStripeCustomerDefaultPaymentMethod(idOrganization: string, idParticipant: string, idPayment: any) {
    return new Promise(async (resolve, reject) => {
      try {
        if (!idPayment) {
          reject('missing payment method params');
        }
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/stripe/customer/payment/default?idPartecipant=' + idParticipant;

        const data = {
          idPayment: idPayment
        }
        this.http.patch(url, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  deleteStripeCustomerSource(idOrganization: string, idParticipant: string, payment_method_id: any) {
    return new Promise(async (resolve, reject) => {
      try {
        if (!payment_method_id) {
          reject('missing payment method params');
        }
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}webApp/stripe/customer/payment/${payment_method_id}`;
        url += '?idPartecipant=' + idParticipant;

        this.http.delete(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  getStripeInvoiceUrl(idOrganization: string, idParticipant: string, invoice_id: string, platform_type: number) {
    return new Promise(async (resolve, reject) => {
      try {
        if (!invoice_id) {
          reject('missing invoice number params');
        }
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}webApp/stripe/invoice/url`;
        url += '?idPartecipant=' + idParticipant;

        const data = {
          invoice_id: invoice_id,
          platform_type: platform_type,
        }

        this.http.post(url,data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  postActivateTracker(idParticipant: string, imei: string, serial: string, name: string, label: number, odometer: number) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/partecipant/trackerGps';
        url += '?idPartecipant=' + idParticipant;
        const data: any = {
          imei: imei,
          serial_number: serial,
          label_number: label,
          label_string: name,
          total_odometer: odometer
        };
        this.http.post(url, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  postTokenFCM(idParticipant: string, token: string, idDevice: string) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/partecipant/tokenFCM';
        url += '?idPartecipant=' + idParticipant;
        const data: any = {
          idPartecipant: idParticipant,
          token: token,
          idDevice: idDevice
        };
        this.http.post(url, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  deleteTokenFCM(idDevice: string) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/partecipant/tokenFCM/' + idDevice;
        this.http.delete(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  getReferrals(idParticipant: string, id?: string) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}webApp/referral/${idParticipant}/?idPartecipant=${idParticipant}`;
        if (id) {
          url += `&id=${id}`
        }
        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  postReferral(idParticipant: string, name: string, email: string, phone: string, notes: string) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/referral?idPartecipant=' + idParticipant;

        let data: any = {
          referee_name: name,
          referee_email: email,
          referee_phone: phone,
          referee_request_status: 0,
          is_completed: false,
          date: new Date(Date.now()),
          notes: notes
        };
        this.http.post(url, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  updateCustomerOrganization(idParticipant: string, paymentId: string, invoiceId: string) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/stripe/invoice/pay?idPartecipant=' + idParticipant;

        let data: any = {
          invoice_id: invoiceId,
          payment_method: paymentId,
        };
        this.http.post(url, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  // * webapp/utility

  patchCheck_codice_fiscale(
    participantId: string,
    body: {
      codice_fiscale: string
    }
  ): Promise<{
    isCorrect: boolean
  }> {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };

        this.http.patch(
          `${this.baseUrl}webapp/utility/check_codice_fiscale?idPartecipant=${
            participantId}`
          , body
          , options
        ).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  patchCheck_partita_iva(
    participantId: string,
    body: {
      partita_iva: string
    }
  ): Promise<{
    isCorrect: boolean
  }> {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };

        this.http.patch(
          `${this.baseUrl}webapp/utility/check_partita_iva?idPartecipant=${
            participantId}`
          , body
          , options
        ).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  patchCheck_zip(
    participantId: string,
    body: {
      zip: string
    }
  ): Promise<{
    isCorrect: boolean
  }> {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };

        this.http.patch(
          `${this.baseUrl}webapp/utility/check_zip?idPartecipant=${
            participantId}`
          , body
          , options
        ).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }


  updateOrganization(idParticipant: string, idOrganization: string) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + 'webApp/updateOrganization?idPartecipant=' + idParticipant;

        let data: any = {
          update: true,
          idOrganization: idOrganization,
        };
        this.http.post(url, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }


  getMandateUrlByPaymentMethodId(idOrganization: string, idParticipant: string, payment_id: string){
    return new Promise(async (resolve, reject) => {
      try {
        if (!payment_id) {
          reject('missing payment id params');
        }
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}webApp/stripe/mandate/url/${payment_id}`;
        url += '?idPartecipant=' + idParticipant;

        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }


  async getIp(){
    return new Promise(async (resolve, reject) => {
      try{
        this.http.get(`https://api.ipify.org/?format=json`, {}).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }

  postMatchContact(idParticipant: string, ip: string, cookie: string){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}webApp/match_contact`;
        url += '?idPartecipant=' + idParticipant;
        this.http.post(url, {contact_ip_address: ip, contact_cookie: cookie}, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  sendLog(type: number, page: string, message: string, err: string, native_app: string, version: string, id_organization: string){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(new HttpHeaders()),
        };
        let url: string = `${this.baseUrl}webApp/log/frontend-error`;
        let data: any = {
          type: type,
          page: page,
          message: message,
          error: err,
          native_app: native_app,
          version: version,
          organizationId: id_organization
        };
        this.http.post(url, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  shopCreateInvoice(idParticipant: string){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}webApp/shop/invoice`;
        url += '?idPartecipant=' + idParticipant;
        this.http.post(url, {}, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  shopAddItemToInvoice(idParticipant: string, price_id: string, quantity: number){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}webApp/shop/invoice/item`;
        url += '?idPartecipant=' + idParticipant;
        this.http.post(url, {price_id: price_id, quantity: quantity}, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  shopPatchInvoiceItem(idParticipant: string, item_id: string, quantity: number){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}webApp/shop/invoice/item`;
        url += '?idPartecipant=' + idParticipant;
        this.http.patch(url, {item_id: item_id, quantity: quantity}, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }


  shopDeleteInvoiceItem(idParticipant: string, item_id: string){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}webApp/shop/invoice/item/${item_id}`;
        url += '?idPartecipant=' + idParticipant;
        this.http.delete(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  shopDeleteInvoice(idParticipant: string, invoice_id: string){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}webApp/shop/invoice/${invoice_id}`;
        url += '?idPartecipant=' + idParticipant;
        this.http.delete(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  shopGetInvoice(idParticipant: string, invoice_id: string){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}webApp/shop/invoice/${invoice_id}`;
        url += '?idPartecipant=' + idParticipant;
        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  shopFinalizeInvoice(idParticipant: string, payment_method: string, with_bank_transfer: boolean, shipping_address: ShippingAddress, is_mobile: boolean){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}webApp/shop/invoice/finalize`;
        url += '?idPartecipant=' + idParticipant;
        this.http.post(url, {payment_method: payment_method, with_bank_transfer: with_bank_transfer, shipping_address: shipping_address, is_mobile}, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  shopGetPrice(idParticipant: string, price_id: string){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}webApp/shop/price/${price_id}`;
        url += '?idPartecipant=' + idParticipant;
        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  shopGetRecentOrder(idParticipant: string, limit: number){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}webApp/shop/orders?idPartecipant=${idParticipant}&limit=${limit}`;
        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  shopFindOrderByInvoiceId(idParticipant: string, invoiceId: string){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}webApp/shop/order/invoiceId?idPartecipant=${idParticipant}&invoice_id=${invoiceId}`;
        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  shopGetOrdersByOrganizationId(idParticipant: string, organizationId: string){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}webApp/shop/orders/organization?idPartecipant=${idParticipant}&organization_id=${organizationId}`;
        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  requestFreeTrial(idParticipant: string, shippingData: ShippingAddress, is_mobile: boolean){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}webApp/shop/orders/free_trial?idPartecipant=${idParticipant}`;
        this.http.post(url, {shipping_address: shippingData, is_mobile},options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  getFleet(idParticipant: string, idOrganization?: string, idFleet?: string){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}webApp/fleet?idPartecipant=${idParticipant}&idOrganization=${idOrganization}&idFleet=${idFleet}`;
        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }


  postFleet(idParticipant: string, name: string, description: string, participants: string[]){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}webApp/fleet?idPartecipant=${idParticipant}`;
        this.http.post(url, {name, description, participants },options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  deleteFleet(idParticipant: string, fleetId: string){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}webApp/fleet/${fleetId}?idPartecipant=${idParticipant}`;
        this.http.delete(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  patchFleet(idParticipant: string, id: string, name: string, description: string, participants: string[]){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}webApp/fleet?idPartecipant=${idParticipant}`;
        this.http.patch(url, {id, name, description, participants },options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  /**
   *
   * @param idParticipant
   */
  authToken(idParticipant){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}webApp/token?idPartecipant=${idParticipant}`;
        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  generateAuthToken(idParticipant){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}webApp/token?idPartecipant=${idParticipant}`;
        this.http.post(url, {}, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }


  /** API COMMUNICATION/WEBAPP */

  associateOrganizationCommunication(customer_phone, owner_phone){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.communicationBaseUrl}communication/webApp/contact/associate_organization`;
        this.http.patch(url, {customer_phone, owner_phone}, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  getTicketsCommunication(){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.communicationBaseUrl}communication/webApp/tickets`;
        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  getTicketCommunication(id_ticket: string){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.communicationBaseUrl}communication/webApp/ticket/${id_ticket}`;
        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  getTicketMessagesCommunication(id_ticket: string){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.communicationBaseUrl}communication/webApp/ticket/messages/${id_ticket}`;
        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }



  postWebMessageTicketCommunication(id_ticket: string, message: string, filename?: string, firebase_path?: string,){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let data = {
          id_ticket,
          message,
          filename,
          firebase_path
        }
        let url: string = `${this.communicationBaseUrl}communication/webApp/web_message/${id_ticket}`;
        this.http.post(url, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  postTicketCommunication(type: number, sub_type: number, title: string, message: string, trackers: any, filename?: string, firebase_path?: string, description?: string){

    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let data = {
          type,
          sub_type,
          title,
          message,
          trackers,
          filename,
          firebase_path,
          description
        }
        let url: string = `${this.communicationBaseUrl}communication/webApp/ticket`;
        this.http.post(url, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  patchTicketCommunication(id_ticket: string, id_organization: string, to_read_client: boolean ){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let data = {
          to_read_client,
          id_organization
        }
        let url: string = `${this.communicationBaseUrl}communication/webApp/ticket/${id_ticket}`;
        this.http.patch(url, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }


  getTicketCount() {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.communicationBaseUrl}communication/webApp/ticket/countUnread`;
        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  resetPassword(email: string){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}webApp/reset_password`;
        this.http.post(url, {email: email, platform_type: ApiService.platformType}, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  postChangePassword(password: string, idParticipant: string){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}webApp/change_password?idPartecipant=${idParticipant}`;
        this.http.post(url, {password: btoa(password)}, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  /** Deadline **/

  getDeadlinesTarget(idParticipant: string){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}webApp/deadline/deadline_target?idPartecipant=${idParticipant}`;
        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  getDeadlineTarget(idDeadlineTarget: string, idParticipant: string){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}webApp/deadline/deadline_target/${idDeadlineTarget}?idPartecipant=${idParticipant}`;
        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  getDeadlines(skip: number, limit: number, idParticipant: string){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}webApp/deadline?idPartecipant=${idParticipant}&limit=${limit}&skip=${skip}`;
        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  getDeadline(idDeadline: string, idParticipant: string){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}webApp/deadline/${idDeadline}?idPartecipant=${idParticipant}`;
        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  postDeadlineTarget(name: string, type: number, idParticipant: string, surname?: string, plate?: string, description?: string){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}webApp/deadline/deadline_target?idPartecipant=${idParticipant}`;

        let data: any = {
          name: name,
          type: type,
        };
        if(plate) data.plate = plate
        if(surname) data.surname = surname
        if(description) data.description = description

        this.http.post(url, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }


  patchDeadlineTarget(id_deadline_target: string, name: string, type: number, idParticipant: string, surname?: string, plate?: string, description?: string){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}webApp/deadline/deadline_target/${id_deadline_target}?idPartecipant=${idParticipant}`;

        let data: any = {
          name: name,
          type: type,
        };
        if(plate) data.plate = plate
        if(surname) data.surname = surname
        if(description) data.description = description

        this.http.patch(url, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }




  postDeadlineCost(
    idParticipant: string,
    id_partecipant_target: string,
    type: number,
    id_deadline_target: string,
    title: string,
    description: string,
    amount: number,
    start_cost_date: number,
    end_cost_date: number,
  ){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}webApp/deadline/deadline_cost?idPartecipant=${idParticipant}`;

        let data: any = {
          type: type,
          id_participant: id_partecipant_target,
          id_deadline_target: id_deadline_target,
          title: title,
          description_cost: description,
          amount: amount,
          start_cost_date: start_cost_date,
          end_cost_date: end_cost_date,
        };

        this.http.post(url, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }




  postDeadline(
    idParticipant: string,
    type: number,
    id_vehicle: string,
    id_deadline_target: string,
    title: string,
    description: string,
    expiration_date: number,
    expiration_km: number,
    recurring: boolean,
    recurring_years: number,
    recurring_months: number,
    recurring_days: number,
    recurring_km: number,
    emails: any[],
    apps: any[],
    notify_limit_km: number,
    notify_limit_date: number
  ){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}webApp/deadline?idPartecipant=${idParticipant}`;

        let data: any = {
          type: type,
          id_participant: id_vehicle,
          id_deadline_target: id_deadline_target,
          title: title,
          description: description,
          expiration_date: expiration_date,
          expiration_km: expiration_km,
          recurring: recurring,
          recurring_years: recurring_years,
          recurring_months: recurring_months,
          recurring_days: recurring_days,
          recurring_km: recurring_km,
          emails: emails,
          apps: apps,
          notify_limit_km: notify_limit_km,
          notify_limit_date: notify_limit_date
        };

        this.http.post(url, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  patchDeadline(
    idParticipant: string,
    id_deadline: string,
    title: string,
    description: string,
    expiration_date: number,
    expiration_km: number,
    recurring: boolean,
    recurring_years: number,
    recurring_months: number,
    recurring_days: number,
    recurring_km: number,
    emails: any[],
    apps: any[],
    notify_limit_km: number,
    notify_limit_date: number
  ){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}webApp/deadline/${id_deadline}?idPartecipant=${idParticipant}`;

        let data: any = {
          title: title,
          description: description,
          expiration_date: expiration_date,
          expiration_km: expiration_km,
          recurring: recurring,
          recurring_years: recurring_years,
          recurring_months: recurring_months,
          recurring_days: recurring_days,
          recurring_km: recurring_km,
          emails: emails,
          apps: apps,
          notify_limit_km: notify_limit_km,
          notify_limit_date: notify_limit_date
        };

        this.http.patch(url, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  deleteDeadline(idParticipant: string, id_deadline: string){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}webApp/deadline/${id_deadline}?idPartecipant=${idParticipant}`

        this.http.delete(url,  options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }


  patchDeadlineComplete(idParticipant: string, id_deadline: string){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}webApp/deadline/complete/${id_deadline}?idPartecipant=${idParticipant}`

        this.http.patch(url, {},  options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }


  patchDeadlineCost(idParticipant: string, id_deadline: string, amount: number, start_cost_date: number, end_cost_date: number, description_cost: string, title: string){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let data = {
          amount: amount,
          start_cost_date: start_cost_date,
          end_cost_date: end_cost_date,
          title:title,
          description_cost: description_cost
        }
        let url: string = `${this.baseUrl}webApp/deadline/cost/${id_deadline}?idPartecipant=${idParticipant}`
        this.http.patch(url, data,  options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  deleteTarget(idParticipant: string, id_deadline_target: string){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}webApp/deadline/deadline_target/${id_deadline_target}?idPartecipant=${idParticipant}`
        this.http.delete(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  getReportBatchDetail(idParticipant: string, id_batch: string, id_participant_search: string){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}webApp/batch_detail?idPartecipant=${idParticipant}&id_partecipant_search=${id_participant_search}&id_batch=${id_batch}`
        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  getReportBatch(idParticipant: string, skip: number, limit: number){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}webApp/batch?idPartecipant=${idParticipant}&skip=${skip}&limit=${limit}`
        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  getSingleBatch(idParticipant: string, id_batch: string){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}webApp/batch/${id_batch}?idPartecipant=${idParticipant}`
        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  batchNotification(idParticipant: string, id_batch: string, id_participant_batch: string){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}webApp/batch_notification/${id_batch}?idPartecipant=${idParticipant}&idParticipantBatch=${id_participant_batch}`
        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  postReportBatch(idParticipant: string, array_participant: any[], timestampStart: number, timestampStop: number, tresholdPartenza: number, tempoSosta: number){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let data = {
          array_participant: array_participant,
          timestampStart: timestampStart,
          timestampStop: timestampStop,
          tresholdPartenza: tresholdPartenza,
          tempoSosta: tempoSosta
        }
        let url: string = `${this.baseUrl}webApp/positionGps/report_batch?idPartecipant=${idParticipant}`
        this.http.post(url, data,  options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  deleteBatch(idParticipant: string, id_batch: string){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}webApp/batch/${id_batch}?idPartecipant=${idParticipant}`
        this.http.delete(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  retryBatch(idParticipant: string, id_batch: string){
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        let data = {
          id_batch: id_batch
        }
        let url: string = `${this.baseUrl}webApp/batch/retry_report_batch?idPartecipant=${idParticipant}`
        this.http.post(url, data,  options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

}
