import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {
  ViewService
} from "../../../services/common/view.service";


@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss']
})
export class StepsComponent{


  // component args

  // @Input() layout: Layout;
  @Input() css: string;
  @Input() current: number;
  @Input() steps: string[];
  @Input() collapse_step: boolean;


  constructor(
    public view: ViewService,
  ) {}

  ionViewDidEnter() {
  }

  scrollCurrent(){
    const elementToCenter = document.getElementById('id_6');
    const scrollDiv = document.getElementById('compt');

    scrollDiv.scrollLeft = elementToCenter.offsetLeft - (scrollDiv.clientWidth - elementToCenter.clientWidth) / 2;

  }














}
