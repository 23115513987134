import { Pipe, PipeTransform } from '@angular/core';
import {Partecipant} from "src/app/classes/common/partecipant";
import {DeadlineTarget} from "src/app/classes/common/deadline";

@Pipe({
  name: 'filterScadenze'
})
export class SearchFilterPipe implements PipeTransform {
  transform(scadenze: any[], filter: any, participant: Partecipant, target: DeadlineTarget, visualizza_costi: boolean, changed: number): any[] {

    if (!scadenze || !filter) {
      return [];
    }

    scadenze = scadenze.filter((scadenza)=>{
      if(visualizza_costi){
        if(scadenza.amount){
          return true
        }else{
          return false;
        }
      }else{
        // voglio ottenere solo le scadenze
        if(scadenza.expiration_km || scadenza.expiration_date){
          return true
        }else{
          return false
        }
      }
    })

    scadenze = scadenze.filter(scadenza => {

      if (filter.completato && scadenza.completed) {
        return true;
      }
      if (filter.scaduto && scadenza.expired) {
        return true;
      }
      if (filter.in_corso && !scadenza.completed && !scadenza.expired) {
        return true;
      }

      return false;
    });

    if(participant || target){

      let filtered: any = []
      for(let scadenza of scadenze){
        //ho selezionato un partecipant o un target
        if(participant){
          if(scadenza.$participant?._id === participant._id){
            filtered.push(scadenza)
          }
        }

        if(target){
          if(scadenza.$target?._id === target._id){
            filtered.push(scadenza)
          }
        }
      }

      return filtered


    }else{
      return scadenze
    }
  }
}
