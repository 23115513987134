import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(items: any[], fields: string[], query: string, changed: number, different?: boolean): any {
    let filtered = items;

    if (query) {
      if (fields.length > 0) {
        let searchTarget: string;

        let props: string[];
        let itemProp: any;

        filtered = items.filter(item => {
          searchTarget = "";

          fields.forEach((field: string) => {
            props = field.split(".");
            itemProp = item;

            for (let prop of props) {
              if (itemProp[prop] || itemProp[prop] == 0) {
                itemProp = itemProp[prop];
              } else {
                itemProp = "";
                break;
              }
            }

            searchTarget += itemProp + " ";
          });

          if (!different) {
            return searchTarget.toUpperCase().indexOf(query.toUpperCase()) !== -1;
          } else {
            return searchTarget.toUpperCase().indexOf(query.toUpperCase()) === -1;
          }
        });
      } else {
        filtered = items.filter(item => item.includes(query));
      }
    }

    return filtered;
  }

}
