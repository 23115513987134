import { LOCALE_ID, NgModule } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from '../app.component';
import { AppRoutingModule } from '../app-routing.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {
  TranslateModule,
  TranslateLoader, TranslateService
} from '@ngx-translate/core';
import {forkJoin, Observable} from "rxjs";
import { IonicStorageModule } from '@ionic/storage-angular';

import localeIt from '@angular/common/locales/it';
import {registerLocaleData} from "@angular/common";
import {
  BrowserAnimationsModule
} from "@angular/platform-browser/animations";
import {CommonComponentsModule} from "../components/common/common-components.module";
import {DesktopComponentsModule} from "../components/desktop/desktop-components.module";
import {ScrollingModule} from "@angular/cdk/scrolling";

registerLocaleData(localeIt);

export class DynamicLocaleId extends String {
  constructor(protected service: TranslateService) {
    super('');
  }

  toString() {
    return this.service.currentLang;
  }
}

export function translateLoader(http: HttpClient) {

  return new MultiTranslateHttpLoader(http, [
    {prefix: './assets/i18n/', suffix: '.json'},
    {prefix: './assets/i18n/specific-', suffix: '.json'}
  ]);
}

export class MultiTranslateHttpLoader implements TranslateLoader {

  constructor(
    private http: HttpClient,
    public resources: { prefix: string, suffix: string }[] = [{
      prefix: '/assets/i18n/',
      suffix: '.json'
    }]
  ) {}

  public getTranslation(lang: string): any {

    let list: Observable<any>[] = [];
    this.resources.map(config => {
      list.push(this.http.get(`${config.prefix}${lang}${config.suffix}`));
    });

    var nestedassign = (target: any, source: any) => {
      Object.keys(source).forEach(sourcekey=>{
        if (Object.keys(source).find(targetkey=>targetkey===sourcekey) !== undefined && typeof source[sourcekey] === "object") {
          target[sourcekey]=nestedassign(target[sourcekey],source[sourcekey]);
        } else {
          target[sourcekey]=source[sourcekey];
        }
      });
      return target;
    }

    return new Observable(subscriber => {
      let final;
      forkJoin(list).subscribe((response: any) => {
        final = response.reduce((a: any, b: any) => {
          // return Object.assign(a, b);
          return nestedassign(a, b);
        });
        console.log(final)
        subscriber.next(final);
      });
    });

  }
}

@NgModule({
  declarations: [
    AppComponent
  ],
  entryComponents: [],
  imports: [
    ScrollingModule,
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({
      mode: 'md'
    }),
    AppRoutingModule,
    HttpClientModule,
    IonicStorageModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (translateLoader),
        deps: [HttpClient]
      },
      isolate: false
    }),
    CommonComponentsModule,
    DesktopComponentsModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    // * translation
    { provide: LOCALE_ID,
      useFactory: (translate: TranslateService) => {
        let tryLang: string = localStorage["appLanguage"] || (navigator.language[0] + navigator.language[1]);
        console.log(`tryLang`, tryLang)
        // Aggiungere tutte le lingue che si vogliono per i
        // pipes come quello delle date

        let avilable: string[] = ['it', 'en', 'de'];
        for(let lang of avilable){
          if(lang === tryLang) return lang;
        }
        return 'en';
      },
      deps: [TranslateService]
    },
    { provide: LOCALE_ID, useClass: DynamicLocaleId, deps: [TranslateService] }
  ],
  bootstrap: [
    AppComponent
  ],
})
export class AppModule {}
