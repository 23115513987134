import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from '@angular/core';
import {
  Partecipant
} from "../../../classes/common/partecipant";
import {ModalController} from "@ionic/angular";
import {
  ViewService
} from "../../../services/common/view.service";
import {TranslateService} from "@ngx-translate/core";
import {
  ApiService
} from "../../../services/common/api.service";
import {
  DataService
} from "../../../services/common/data.service";

@Component({
  selector: 'app-input-device',
  templateUrl: './input-device.component.html',
  styleUrls: ['./input-device.component.scss'],
})
export class InputDeviceComponent implements OnInit {

  @Input() participant: Partecipant;

  lang: any;

  name: string;
  nameError: string;
  label_0: string;
  label_0Error: string;
  label_1: string;
  label_1Error: string;
  stop_enabled: boolean;
  moving_enabled: boolean;

  listener = {
    view: null
  };


  constructor(
    private cd: ChangeDetectorRef,
    private modalCtrl: ModalController,
    private view: ViewService,
    private translate: TranslateService,
    private api: ApiService,
    private data: DataService
  ) {}

  ngOnInit() {}

  ionViewDidEnter() {
    this.listenerView();
    this.translate.get('INPUT_DEVICE').subscribe((lang: any) => {
      this.lang = lang;
      if(this.participant){
        this.name = this.participant.din1_name;
        this.label_0 = this.participant.din1_label_0;
        this.label_1 = this.participant.din1_label_1;
        this.stop_enabled = this.participant.din1_stop_enabled;
        this.moving_enabled = this.participant.din1_moving_enabled;
      }
      ViewService.updateView.next();
    });
  }

  ionViewWillLeave() {
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.cd.markForCheck();
    });
  }

  async checkFormError(input: string){
    switch (input) {
      case 'name':
        if(!this.name){
          this.nameError = this.lang.nome_ingresso_placeholder;
          ViewService.updateView.next();
          return;
        }
        this.nameError = undefined;
        break;
      case 'label_0':
        if(!this.label_0){
          this.label_0Error = this.lang.label_0_placeholder;
          ViewService.updateView.next();
          return;
        }
        this.label_0Error = undefined;
        break;
      case 'label_1':
        if(!this.label_1){
          this.label_1Error = this.lang.label_1_placeholder;
          ViewService.updateView.next();
          return;
        }
        this.label_1Error = undefined;
        break;
    }
    ViewService.updateView.next();
  }

  async save(){
    this.checkFormError('name');
    this.checkFormError('label_0');
    this.checkFormError('label_1');
    let error: string = this.nameError || this.label_0Error || this.label_1Error;
    if(error){
      return this.view.modalAlert(this.lang.attenzione, error, 'warn', this.lang.ok);
    }
    await this.view.showLoading();
    try{
      if(!this.participant.isDemo){
        const res: any = await this.api.patchParticipantDin1Info(this.data.selOrganization.me._id, this.participant._id, this.name, this.label_0, this.label_1, this.stop_enabled || false, this.moving_enabled || false);
        this.participant.setData(res);
      }
      this.view.modalAlert(this.lang.successo, this.lang.salvataggio_completato, 'succ', this.lang.ok);
      this.view.hideLoading();
      this.dismiss();
    }catch(err){
      console.error(err);
      this.view.modalAlert(this.lang.errore, this.lang.impossibile_salvare, 'warn', this.lang.ok);
      this.view.hideLoading();
    }
  }

}
