import {Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter} from '@angular/core';
import {Input} from "@angular/core";
import {
  ViewService
} from "../../../services/common/view.service";


type Layout =
  "col" |
  "row";

// type Status =
//   "off" |
//   "on";


@Component({
  selector: 'app-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputDateComponent implements OnInit {


  // component args

  @Input() layout: Layout = "col";
  @Input() lbl = "Data";
  @Input() placeholder = "Seleziona giorno";
  @Input() val: unknown;

  @Output() pick = new EventEmitter<any>();



  // listener

  listener = {
    view: null,
  };


  constructor(
    private cd: ChangeDetectorRef,
    // private navCtrl: NavController,
    // navParams can only be used in modals and popovers!
    // private navParams: NavParams,
    // private tdweb: TdwebService,
    // private dataService: DataService,
    // private popoverCtrl: PopoverController,
    // private alertCtrl: AlertController,
    // private modalCtrl: ModalController,
    // private api: ApiService,
    public view: ViewService,
    // private menuCtrl: MenuController,
  ) {}

  ngOnInit() {
    // console.log("ngOnInit()");

    //  -------------------------
    //  listeners:

    this.listenerView();

    //  -------------------------

    // only works on component init (ofc)
    // if (!this.status) {
    //   this.status = "off";
    // }
  }

  ngOnDestroy() {
    // console.log("ngOnDestroy()");

    //  -------------------------
    // disengage listeners:

    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }

    //  -------------------------
  }





  /*myFunc() {
    console.log("myfunc", typeof this.val == 'number')
    return typeof this.val == 'number';
  }*/



  openDatePicker() {
    this.pick.emit("05-08-2020 h10:00");
  }




  // listener

  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      // console.log("listener.view", obj);

      // this.zone.run(() => {

      this.view.pipeChanged++;
      this.cd.markForCheck();

      setTimeout(() => {
        this.cd.markForCheck();
      }, 250);

      // });
    });
  }












}
