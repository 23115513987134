import { Component, OnInit } from '@angular/core';
import {ModalController} from "@ionic/angular";
import {Input} from "@angular/core";

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.page.html',
  styleUrls: ['./login-modal.page.scss'],
})
export class LoginModalPage implements OnInit {
  @Input() closeModal: boolean;
  @Input() disableTitle: boolean;
  constructor(
    private modalCtrl: ModalController,
) { }

  ngOnInit() {

  }

  dismiss(){
    this.modalCtrl.dismiss()
  }
}
