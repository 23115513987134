import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { PopoverSelectCarComponent } from '../popover-select-car/popover-select-car.component';
import {
  Partecipant
} from "../../../classes/common/partecipant";
import {
  ViewService
} from "../../../services/common/view.service";
import {
  DataService
} from "../../../services/common/data.service";
import {PopoverSelectMultipleCarComponent} from "src/app/components/desktop/popover-select-multiple-car/popover-select-multiple-car.component";


@Component({
  selector: 'app-select-chip',
  templateUrl: './select-chip.component.html',
  styleUrls: ['./select-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectChipComponent implements OnInit {

  @Input() car: Partecipant;
  @Input() placeholder: string/*  = "REPORT.seleziona_veicolo" */;

  @Output() select = new EventEmitter<any>();
  @Input() multipleSelection: boolean = false;
  @Input() selectAll: boolean;


  // listener
  listener = {
    view: null,
  };


  constructor(
    private cd: ChangeDetectorRef,
    private popoverCtrl: PopoverController,
    public view: ViewService,
    private data: DataService,
  ) {}


  ngOnInit() {
    this.listenerView();
  }

  ngOnDestroy() {
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
  }

  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
       this.view.pipeChanged++;
      this.cd.markForCheck();
    });
  }

  async popoverSelectCar(event: any) {
    // Devo passargli tutti i participants con type === 2
    let cars: any[] = [];
    if(this.data.selOrganization && this.data.selOrganization.participants){
      for(let participant of this.data.selOrganization.participants){
        if(participant.isTracker() && participant.isActive && participant.isInFleet()){
          cars.push(participant);
        }
      }
    }


    if(this.multipleSelection){
      const popover = await this.popoverCtrl.create({
        component: PopoverSelectMultipleCarComponent,
        componentProps: {
          cars: cars,
          selectAll: this.selectAll,
        },
        cssClass: "popover_list",
        event: event,
      });
      popover.onDidDismiss().then(() => {
        this.select.emit()
      });
      await popover.present();
    }else{
      const popover = await this.popoverCtrl.create({
        component: PopoverSelectCarComponent,
        componentProps: {
          cars: cars
        },
        cssClass: "popover_list",
        event: event,
      });
      popover.onDidDismiss().then((data: any) => {
        if(data && data.data){
          this.select.emit(data.data.car);
        }
      });
      await popover.present();
    }

  }






}
