import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from '@angular/core';
import {
  ModalController,
  NavController
} from '@ionic/angular';
import {TranslateService} from "@ngx-translate/core";
import {Poi} from "../../../../classes/common/poi";
import {AppComponent} from "../../../../app.component";
import {
  ViewService
} from "../../../../services/common/view.service";
import {
  PoiMapService
} from "../../../../services/common/poi-map.service";
import {
  ApiService
} from "../../../../services/common/api.service";
import {
  DataService
} from "../../../../services/common/data.service";
import {Utility} from "../../../../classes/common/utility";
import {
  AuthService
} from "../../../../services/common/auth.service";

@Component({
  selector: 'app-poi-add',
  templateUrl: './poi-add.page.html',
  styleUrls: ['./poi-add.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PoiAddPage implements OnInit {

  @Input() poi: Poi;
  @Input() lat: number;
  @Input() lng: any;

  @Input() appComponent: AppComponent;


  lang: any;

  listener = {
    view: null
  };

  addressError: string;
  address: string;
  searching: boolean;

  name: string;
  nameError; string;
  radius: number = 300;
  radiusError: number;
  labelNumber: number;
  labelNumberError: string;

  // page is for creating a new poi
  pageIsAdd: boolean;


  constructor(
    private cd: ChangeDetectorRef,
    private modalCtrl: ModalController,
    public view: ViewService,
    private translate: TranslateService,
    private map: PoiMapService,
    private api: ApiService,
    private data: DataService,
    private navCtrl: NavController
  ) {}

  ngOnInit() {
    if(this.poi){
      this.labelNumber = this.poi.label_number;
      this.name = this.poi.name;
      this.radius = this.poi.radius;
      ViewService.updateView.next();
    } else {
      this.pageIsAdd = true;
    }
  }

  async ionViewWillEnter(){
    if(!AuthService.isLogged) this.navCtrl.navigateRoot('login');
    if(this.appComponent) return await this.appComponent.checkPermission('poi', async () => {await this.dismiss()});
  }

  ionViewDidEnter() {
    this.listenerView();

    this.translate.get('POI_ADD').subscribe((lang: any) => {
      this.lang = lang;
      let coordinates: any = {
        lat: 41.8910091,
        lng: 12.49207
      };
      // let poiHasOwnPosition: boolean;

      if(this.poi && this.poi.position && this.poi.position.coordinates){
        coordinates.lat = this.poi.position.coordinates[1];
        coordinates.lng = this.poi.position.coordinates[0];
        // poiHasOwnPosition = true;
      }else if(this.lat && this.lng){
        coordinates.lat = this.lat;
        coordinates.lng = this.lng;
      }
      this.map.initMap('Google', 'assets/img/markers/marker-poi.png', this.lang.trascina_per_spostare, coordinates.lat, coordinates.lng, this.radius);

      // if poi has a position of his own, rather than a generated one
      // if (poiHasOwnPosition) {
        // populate search address input field
        this.api.getAddress(this.data.selOrganization.me._id, coordinates.lat, coordinates.lng).then((address: string) => {
          console.log('address: ', address);
          this.address = address;
          ViewService.updateView.next();
        }).catch((err: any) => {
          console.error(err);
        });
      // }

      ViewService.updateView.next();
    });

    if(this.data.pois){
      let max: number = 0;
      for(let poi of this.data.pois){
        if(poi.label_number >= max){
          max = poi.label_number;
        }
      }
      if (this.pageIsAdd) {
        this.labelNumber = max + 1;
      }
      ViewService.updateView.next();
    }

  }


  ionViewWillLeave() {
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
  }

  dismiss(data?: any) {
    this.modalCtrl.dismiss(data);
  }

  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.cd.markForCheck();
      setTimeout(() => {
        this.cd.markForCheck();
      }, 250);
    });
  }

  // Ricerca l'indirizzo e posiziona il marker su di esso
  async searchAddress(){
    if(!this.address){
      this.addressError = this.lang.inserire_indirizzo;
      ViewService.updateView.next();
      return;
    }
    this.addressError = undefined;
    this.searching = true;
    ViewService.updateView.next();
    try{
      let coordinates: any = await this.api.getCoordinates(this.data.selOrganization.me._id, this.address);
      if(!coordinates || !coordinates.lat || !coordinates.lon){
        throw new Error();
      }else{
        this.searching = false;
        // metto il marker nella posizione selezionata
        this.map.movePoiMarker(coordinates.lat, coordinates.lon);
      }
    }catch(err){
      console.error(err);
      this.searching = false;
      this.addressError = this.lang.impossibile_trovare_indirizzo;
    }
    ViewService.updateView.next();
  }

  // Effettua la verifica sul form
  async checkFormError(input: string, noTimeout?: boolean){
    if(!noTimeout) await Utility.wait(500);
    switch (input) {
      case 'name':
        if(!this.name){
          this.nameError = this.lang.inserisci_nome;
          break;
        }
        this.nameError = undefined;
        break;
      case 'radius':
        if(!this.radius || this.radius <= 0){
          this.radiusError = this.lang.raggio_invalido;
          break;
        }
        this.map.changeCircleRadius(this.radius);
        this.radiusError = undefined;
        break;
      case 'labelNumber':
        if(!this.labelNumber){
          this.labelNumberError = this.lang.inserisci_label_number;
          break;
        }
        if(this.labelNumber <= 0 || this.labelNumber > 999){
          this.labelNumberError = this.lang.label_number_invalido;
          break;
        }
        this.labelNumberError = undefined;
        break;
    }
    ViewService.updateView.next();
  }

  // Apre l'info window
  openInfoField(field: string){
    switch (field) {
      case 'label_number':
        return (this.view.modalAlert(
          this.lang.info,
          this.lang.info_etichetta_numerica,
          "info",
          this.lang.ok
        ));
    }
  }

  creaPoi(){
    this.checkFormError('name', true);
    this.checkFormError('radius', true);
    this.checkFormError('labelNumber', true);
    if(this.nameError || this.radiusError || this.labelNumberError){
      return;
    }
    // fare controllo numero poi esistente
    let poi: Poi;
    if(this.data.pois){
      poi = this.data.pois.find((elem: Poi) => {
        return (
          elem.label_number === this.labelNumber &&
          elem._id !== this.poi._id
        );
      });
    }
    if(poi){
      let buttons: any[] = [
        {
          text: this.lang.annulla,
          role: 'cancel'
        },
        {
          text: this.lang.conferma,
          handler: async () => {
            this.ausCreatePoi();
          }
        }
      ];
      this.view.presentAlert(this.lang.conferma, this.lang.esiste_label_number + `<br><br><small>` + this.lang.nome_poi + `: <b>` + poi.name + `</b></small>`, buttons);
    }else{
      this.ausCreatePoi();
    }
  }

  // Metodo ausiliario che distingue se patch o post effettua l'api di creazione di un poi, utile per la conferma del label_number già esistente
  private async ausCreatePoi(){
    if(this.poi){
      try{
        await this.view.showLoading();
        const position: any = this.map.poiMarker.getPosition();
        const poi = await this.api.patchCreatePoi(this.data.selOrganization.me._id, this.poi._id, position.lat(), position.lng(), this.name, this.labelNumber, this.radius);
        await this.view.hideLoading();
        this.view.modalAlert(this.lang.successo, this.lang.poi_modificato, 'succ', this.lang.ok);
        this.dismiss({poi});
      }catch(err){
        console.error(err);
        this.view.hideLoading();
        this.view.modalAlert(this.lang.errori.errore, this.lang.errori.modifica_poi, 'warn', this.lang.ok);
      }
    }else{
      try{
        await this.view.showLoading();
        const position: any = this.map.poiMarker.getPosition();
        const poi = await this.api.postCreatePoi(this.data.selOrganization.me._id, position.lat(), position.lng(), this.name, this.labelNumber, this.radius);
        await this.view.hideLoading();
        this.view.modalAlert(this.lang.successo, this.lang.poi_creato, 'succ', this.lang.ok);
        this.dismiss({poi});
      }catch(err){
        console.error(err);
        this.view.hideLoading();
        this.view.modalAlert(this.lang.errori.errore, this.lang.errori.creazione_poi, 'warn', this.lang.ok);
      }
    }

    this.data.pois = [...this.data.pois] //creo una copia dell'array per rendeere visibili le modifiche in virtual scroll
  }

  deletePoi() {
    // if page is not for creating a new POI
    if (!this.pageIsAdd) {
      let message: string;
      let button: any;
      message = this.lang.conferma_cancellazione_poi;
      button = {
        label: this.lang.conferma,
        click: async () => {
          await this.view.showLoading();
          try{
            await this.api.deletePoi(this.data.selOrganization.me._id, this.poi._id);
            this.view.modalAlert(this.lang.operazione_completata, this.lang.poi_eliminato, 'warn', this.lang.ok);
            this.dismiss();
            this.data.pois = [...this.data.pois]
          }catch (err) {
            console.error(err);
            this.view.modalAlert(this.lang.errore, this.lang.errori.eliminazione_poi, 'warn', this.lang.ok);
          }
          await this.view.hideLoading();
        }
      };
      // message += ` <b>` + (this.selectedCar.name || this.selectedCar.imei) + `</b>`;
      let buttons: any[] = [
        {
          label: this.lang.annulla,
          fill: 'outline',
          color: 'mid-light_base'
        },
        button
      ];
      this.view.modalAlert(this.lang.richiesta_conferma, message, 'warn', buttons);
    }
  }


}
