import {Poi} from "./poi";

export class AlarmPoi {

  _id: string;
  idPartecipant: string;
  idPoi: string;
  idOrganization: string;
  onEntry: boolean;
  onExit: boolean;
  emails: string[];
  push: string[];
  timestampLastCheck: number;
  isEntry: boolean;

  alarmPoi_hour_start: number;
  alarmPoi_minute_start: number;
  alarmPoi_hour_stop: number;
  alarmPoi_minute_stop: number;
  alarmPoi_day: boolean[];
  alarmPoi_day_default: boolean; // aggiunto per frontend (dice se sono esistenti o settasti di default)

  // aggiunti frontend
  poi: Poi;

  constructor(data?: any) {
    if(data){
      this.setData(data);
    }
  }

  setData(data: any) {
    this._id = data._id;
    this.idPartecipant = data.idPartecipant;
    this.idPoi = data.idPoi;
    this.idOrganization = data.idOrganization;
    this.onEntry = data.onEntry;
    this.onExit = data.onExit;
    this.emails = data.emails;
    this.push = data.push;
    this.timestampLastCheck = data.timestampLastCheck;
    this.isEntry = data.isEntry;
    this.alarmPoi_hour_start = data.alarmPoi_hour_start;
    this.alarmPoi_minute_start = data.alarmPoi_minute_start;
    this.alarmPoi_hour_stop = data.alarmPoi_hour_stop;
    this.alarmPoi_minute_stop = data.alarmPoi_minute_stop;
    this.alarmPoi_day = data.alarmPoi_day;
    if(!this.alarmPoi_day || this.alarmPoi_day.length < 7){
      this.alarmPoi_day = [false, false, false, false, false, false, false];
      this.alarmPoi_day_default = true;
    }
  }
}
