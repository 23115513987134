import {Component, Input, OnInit} from '@angular/core';
import {Invoice} from "../../../classes/common/invoice";
import {InvoiceLine} from "src/app/classes/common/invoiceLine";
import {DataService} from "src/app/services/common/data.service";

@Component({
  selector: 'app-invoice-cost-details',
  templateUrl: './invoice-cost-details.component.html',
  styleUrls: ['./invoice-cost-details.component.scss'],
})
export class InvoiceCostDetailsComponent implements OnInit {

  @Input() invoice: Invoice
  @Input() loaderPrice: boolean = false;

  public elenco_prodotti_shop: any;

  constructor(
    private data: DataService
  ) { }

  ngOnInit() {
    this.elenco_prodotti_shop = [];
    if(this.invoice.metadata && this.invoice.metadata.type === 'product'){
      this.elenco_prodotti_shop = DataService.generalConfig.elenco_prodotti_shop
    }
    this.mapInvoiceLineWithProduct()
    console.log(this.invoice)
  }

  ngOnChanges() {
    this.mapInvoiceLineWithProduct()
  }

  /**
   * Assegnare alle singole line il name e il type di elenco_prodotti
   */
  mapInvoiceLineWithProduct(){
    if(this.elenco_prodotti_shop && this.invoice.metadata?.type === 'product'){
      let newLines = [];
      for(let item of this.invoice.lines){
        //devo andare a cercare questo price
        this.elenco_prodotti_shop.forEach((prodotto, i)=>{
          if(prodotto.custom_priceId_list){
            for(let custom_list of prodotto.custom_priceId_list){
              if(custom_list.price_id === item.price.id){
                newLines.push(new InvoiceLine({...item, ...{prodotto_associato: prodotto}}, {lang: this.data.selOrganization.translate_language}))
              }
            }
          }
        })
      }
      this.invoice.lines = newLines;
    }
  }

}
