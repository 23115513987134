import {ShopProducts} from "src/app/classes/common/shop-products";

export class InvoiceLine{

  $name: string;
  quantity: string;
  amount_formatted: string;
  unit_amount_formatted: string;
  total_excluding_tax_formatted: string;
  tax_formatted: string;
  total_formatted: string;
  period: any;
  period_start: string;
  period_end: string;
  invoice_item: string;
  price: any;
  $raw: any;
  description: string;
  _prodotto_associato: any;
  private _type_trackerGps: number;

  private $getLineName: (number) => string = () => {return undefined};
  private $lang: string;

  constructor(data?: any, options?: {getLineName?: (number) => string, lang?: string}) {
    if(data){
      this.setData(data, options);
    }
  }

  setData(data, options?: {getLineName?: (number) => string, lang?: string}){
    if(options){
      if(options.getLineName) this.$getLineName = options.getLineName
      if(options.lang) this.$lang = options.lang
    }
    this.type_trackerGps = data.type_trackerGps;
    this.quantity= data.quantity
    this.amount_formatted = data.amount_formatted
    this.unit_amount_formatted = data.unit_amount_formatted
    this.total_excluding_tax_formatted = data.total_excluding_tax_formatted
    this.tax_formatted = data.tax_formatted
    this.total_formatted = data.total_formatted
    this.period_start = data.period?.start
    this.period_end =  data.period?.end
    this.period =  data.period
    this.invoice_item = data.invoice_item
    this.price = data.price
    this.description = data.description
    this.$raw = data
    if(data.prodotto_associato){
      this._prodotto_associato = new ShopProducts(data.prodotto_associato, {lang: this.$lang})
    }

  }

  set type_trackerGps(value: number) {
    this._type_trackerGps = value;
    if(this._type_trackerGps){
      this.$name = this.$getLineName(this._type_trackerGps);
    }
  }

  get type_trackerGps(): number {
    return this._type_trackerGps;
  }

}
