import { Component, OnInit } from '@angular/core';
import {DataService} from "src/app/services/common/data.service";
import {Partecipant} from "src/app/classes/common/partecipant";
import {FormGroup} from "@angular/forms";
import {Fleet} from "src/app/classes/common/fleet";
import {Input} from "@angular/core";
import {ViewService} from "src/app/services/common/view.service";
import {ModalController} from "@ionic/angular";
import {ApiService} from "src/app/services/common/api.service";
import {Storage} from "@ionic/storage-angular";
import {FormControl} from "@angular/forms";
import {Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-fleet-manager',
  templateUrl: './fleet-manager.page.html',
  styleUrls: ['./fleet-manager.page.scss'],
})
export class FleetManagerPage implements OnInit {

  @Input() selectedFleet: Fleet;

  cars: Partecipant[] = []

  fleetForm: FormGroup;

  fleetName: string;
  fleetDescription: string;

  fleetTrackers: string[];
  fleetTrackersError: string;

  searchTracker: string
  sortCrescent: boolean = true;

  loader: boolean = false;

  constructor(
    private data: DataService,
    public view: ViewService,
    private api: ApiService,
    private modalCtrl: ModalController,
    private storage: Storage,
    private translate: TranslateService,
  ) { }

  ngOnInit() {

    if(this.selectedFleet){
      this.fleetName = this.selectedFleet.name
      this.fleetDescription = this.selectedFleet.description
      this.fleetTrackers = this.selectedFleet.participants
    }

    this.fleetForm = new FormGroup({
      name: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.required, Validators.minLength(1)]
      }),
      description: new FormControl(null, {
        updateOn: 'change',
      }),

    });

    DataService.isInitialized().then(() => {
      for(let participant of this.data.selOrganization.participants){
        if(participant.isTracker() && participant.isActive){
          if(this.fleetTrackers && this.fleetTrackers.find((elem)=>elem === participant._id)){
            participant.$selected = true;
          }else{
            participant.$selected = false;
          }
          this.cars.push(participant);
        }
      }
    });
  }

  dismiss(){
    this.modalCtrl.dismiss()
  }

  /**
   * modifica / crea nuova flotta
   */
  async update(){
    this.loader = true;
    //verifico se c'è almeno un participant nella lista di flotte
    let newFleetParticipants = [];
    //verifico i participant da inserire nella flotta
    for(let participant of this.cars){
      if(participant.$selected){
        newFleetParticipants.push(participant._id)
      }
    }

    if(newFleetParticipants.length > 0){

     if(this.selectedFleet?._id){
       //devo modificare la flotta esistente
       try{
         let res: any = await this.api.patchFleet(this.data.selOrganization.me._id, this.selectedFleet._id,this.fleetName, this.fleetDescription, newFleetParticipants)
         if(res && res.success){

           for(let index in this.data.fleetList){
             if(this.data.fleetList[index]?._id === this.selectedFleet._id){
               this.data.fleetList[index] = new Fleet(res.fleet)
               //aggiornare il dato
               if(this.data.fleetSelectedInStorage?._id === res.fleet._id){
                 await this.storage.set('fleet', this.data.fleetList[index])
                 this.data.fleetSelectedInStorage = this.data.fleetList[index];
                 ViewService.updateView.next();
               }
             }
           }

           //se sto modificando la flotta selezionata devo
           if(this.data.fleetSelectedInStorage && this.data.fleetSelectedInStorage._id === this.selectedFleet._id){

             await this.showAlert(this.translate.instant('FLEET.flotta_aggiornata') , this.translate.instant('FLEET.flotta_aggiornata_toast'), true, ()=>{window.location.reload();})

           }else{
             await this.showAlert(this.translate.instant('FLEET.flotta_aggiornata'),'', true)
           }

         }
       }catch (e){
         console.error(e)
       }
     }else{
       try{
         let res: any = await this.api.postFleet(this.data.selOrganization.me._id, this.fleetName, this.fleetDescription, newFleetParticipants)
         if(res && res.success){
           let insertedFleet: any = await this.api.getFleet(this.data.selOrganization.me._id,'',res.insertedId)
            if(insertedFleet && insertedFleet.fleets){
              for(let fleet of insertedFleet.fleets){
                this.data.fleetList.push(new Fleet(fleet))
              }
            }

           await this.showAlert(this.translate.instant('FLEET.flotta_aggiunta'),'', true )

         }
       }catch (e){
         console.error(e)
       }
     }

     await this.modalCtrl.dismiss()

    }else{
      //Errore, gestione input
      this.fleetTrackersError = this.translate.instant('FLEET.veicoli_errore')
    }

    this.loader = false

  }

  manageInput(){
    if(this.fleetTrackersError){
      this.fleetTrackersError = undefined
    }
  }

  async deleteFleet(id: string){
    let buttons: any[] = [
      {
        text: this.translate.instant('FLEET.annulla'),
        role: 'cancel'
      },
      {
        text: this.translate.instant('FLEET.conferma'),
        handler: async () => {
          try{
            this.loader = true;
            let res: any = await this.api.deleteFleet(this.data.selOrganization.me._id, id)
            if(res && res.success){

              //rimuovo la flotta selezionata
              for(let index in this.data.fleetList){
                if(this.data.fleetList[index]?._id === id){
                  this.data.fleetList[index] = null
                  this.modalCtrl.dismiss()

                }
              }

              //La flotta che ho cancellato è quella attualmente selezionata?
              if (this.data.fleetSelectedInStorage && this.data.fleetSelectedInStorage._id === id){

                this.modalCtrl.dismiss()

                await this.storage.set('fleet', null)

                await this.showAlert(this.translate.instant('FLEET.flotta_aggiunta'),this.translate.instant('FLEET.flotta_aggiornata_toast'),true, ()=>{ this.loader = true;
                  window.location.reload();})

              }else{
                await this.showAlert(this.translate.instant('FLEET.flotta_eliminata'),'',true )
              }

            }else{
              this.loader = false;
            }
          }catch (e){
            console.error(e)
          }
        }
      }
    ];

    this.view.presentAlert(this.translate.instant('FLEET.elimina_titolo'), this.translate.instant('FLEET.elimina_contenuto'), buttons);

  }

  async showAlert(title: string, message: string, succ?: boolean, dismiss?: any){
    await this.modalCtrl.dismiss()
    let modal: HTMLIonModalElement = await this.view.modalAlert(title, message, succ ? 'succ' : 'warn', 'OK');

    const {data} = await modal.onDidDismiss()
    if(dismiss){
      dismiss(data)
    }
  }

}
