import {Component, OnInit} from '@angular/core';
import {Input} from "@angular/core";
import {ChangeDetectorRef} from "@angular/core";
import {PopoverController} from "@ionic/angular";
import {ViewService} from "src/app/services/common/view.service";
import {DataService} from "src/app/services/common/data.service";
import {ModalController} from "@ionic/angular";
import {FleetManagerPage} from "src/app/pages/desktop/common/fleet-manager/fleet-manager.page";
import {Fleet} from "src/app/classes/common/fleet";
import {Storage} from "@ionic/storage-angular";
import {ToastController} from "@ionic/angular";
import {last} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-popover-fleet', templateUrl: './popover-fleet.component.html', styleUrls: ['./popover-fleet.component.scss'],
})
export class PopoverFleetComponent implements OnInit{

  // component args
  @Input() fleets;

  // listener
  listener = {
    view: null,
  };

  constructor(
    private cd: ChangeDetectorRef,
    private popoverCtrl: PopoverController,
    public view: ViewService,
    public data: DataService,
    private modalCtrl: ModalController,
    private storage: Storage,
    private toastController: ToastController,
    private translate: TranslateService
  ){
  }

  ngOnInit(){
  }

  ionViewWillEnter(){
  }

  ionViewDidEnter(){
    this.listenerView();
  }

  ionViewWillLeave(){
    if (this.listener.view){
      this.listener.view.unsubscribe();
    }
  }

  ionViewDidLeave(){
  }

  async dismiss(id: string, data: any){
    await this.popoverCtrl.dismiss()
  }


  // listener
  listenerView(){
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.cd.markForCheck();
    });
  }



  async addFleet(){
    let modal = await this.modalCtrl.create({
      component: FleetManagerPage, cssClass: "modal_alarm", componentProps: {},
    });
    await modal.present();

    await this.popoverCtrl.dismiss()

    ViewService.updateView.next()
  }


  async editFleet(fleet: Fleet){
    if (fleet){
      let modal = await this.modalCtrl.create({
        component: FleetManagerPage, cssClass: "modal_alarm", componentProps: {
          selectedFleet: fleet
        },
      });
      await modal.present();

      await this.popoverCtrl.dismiss()

      ViewService.updateView.next()
    }
  }

  /**
   *
   * Set fleet in storage
   * @param fleet
   */
  async setFleetInStorage(fleet?: Fleet){
    if(this.data.fleetSelectedInStorage?._id === fleet?._id){
      return;
    }

    //imposto la fleet in storage, anche se è vuota (vuol dire che ho selezionato tutti i veicoli
    await this.storage.set('fleet', fleet ? fleet : [])

    this.popoverCtrl.dismiss()

    //verifico se l'ultimo veicolo selezionato fa parte della flotta
    if(fleet){

      let lastSelectedDevice = await this.storage.get('last_selected_device');
      let isPresent = false;
      for(let tracker of fleet.participants){
        if(tracker === lastSelectedDevice){
          isPresent = true;
        }
      }
      if(!isPresent){
        await this.storage.set('last_selected_device', fleet.participants[0])
      }
    }

    let modal: HTMLIonModalElement = await this.view.modalAlert(this.translate.instant('FLEET.flotta_aggiornata'), this.translate.instant('FLEET.flotta_aggiornata_toast'), 'succ' , 'OK');

    await modal.onDidDismiss()
    window.location.reload();

  }
}
