import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Input,
  Output, EventEmitter
} from '@angular/core';
import {NavigationExtras} from "@angular/router";
import {
  ModalController,
  NavController
} from "@ionic/angular";
import * as moment from "moment";
import {TranslateService} from "@ngx-translate/core";
import {
  Partecipant
} from "../../../classes/common/partecipant";
import {Poi} from "../../../classes/common/poi";
import {AppComponent} from "../../../app.component";
import {
  ViewService
} from "../../../services/common/view.service";
import {
  ApiService
} from "../../../services/common/api.service";
import {
  DataService
} from "../../../services/common/data.service";
import {
  PoiAddPage
} from "../../../pages/desktop/common/poi-add/poi-add.page";
import {CarFieldsPage} from "src/app/pages/desktop/common/car-fields/car-fields.page";

@Component({
  selector: 'app-map-popup-car',
  templateUrl: './map-popup-car.component.html',
  styleUrls: ['./map-popup-car.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapPopupCarComponent implements OnInit {

  @Input() selectedCar: Partecipant;
  @Input() selectedPoi: Poi;
  @Input() selectedPosition: any; // {lat: ..., lng: ...}

  @Input() blockAction: boolean = false;
  @Input() appComponent: AppComponent;
  @Input() idComponentDOM: string = 'main-map-infowindow';

  @Output() reloadMap = new EventEmitter<any>();
  @Output() close = new EventEmitter<any>();

  stats = [
    {
      lbl: "Nome",
      val: "Furgone demo 2",
    },

    {
      lbl: "Stato veicolo",
      val: "Il mezzo è in movimento",
    },

  ];

  stats2 = [
    {
      icon: "speed.svg",
      lbl: "Velocità",
      unit: "Km/h",
      val: "195.0",
    },

  ];

  btns = [
    {
      icon: "carDoc.svg",
      lbl: "Dettaglio",
    },

    {
      icon: "path.svg",
      lbl: "Storico",
    },
    {
      icon: "reportSearch.svg",
      lbl: "Report",
    },
    {
      icon: "alarm.svg",
      lbl: "Alarms",
    },

    {
      icon: "roadCursor.svg",
      lbl: "Street view",
    },

    {
      icon: "pin-add_mid-dark.svg",
      lbl: "Crea P.O.I",
    },
  ];

  listener = {
    view: null
  };

  startDate_date: any = moment().startOf("day");
  stopDate_date: any = moment();
  limit: number = 1000;
  lang: any;

  constructor(
    private cd: ChangeDetectorRef,
    public view: ViewService,
    private modalCtrl: ModalController,
    private navCtrl: NavController,
    private api: ApiService,
    private data: DataService,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.listenerView();
    this.translate.get('VEHICLE_DETAIL').subscribe((lang: any) => {
      this.lang = lang;
      ViewService.updateView.next();
    });
  }

  ngOnDestroy() {
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
  }

  async parseActionParticipant(action: string){
    let navigationExtras: NavigationExtras;
    switch (action) {
      case 'close':
        this.close.emit();
        break;
      case 'toggleVisibility':
        // Cambia la visibilità del veicolo su mappa
        break;
      case 'details':
      case 'diagnostic':
        // Apre il dettaglio del veicolo (lo sta facendo fra)
        navigationExtras = {
          queryParams: {
            _id: this.selectedCar._id
          }
        };
        this.navCtrl.navigateRoot(['cars'], navigationExtras);
        break;
      case 'history':
        // Apre pagina storico dei percorsi con veicolo selezionato
        navigationExtras = {
          queryParams: {
            _id: this.selectedCar._id
          }
        };
        this.navCtrl.navigateRoot(['routes-history'], navigationExtras);
        break;
      case 'report':
        // Apre pagina report con veicolo selezionato
        navigationExtras = {
          queryParams: {
            _id: this.selectedCar._id
          }
        };
        this.navCtrl.navigateRoot(['report'], navigationExtras);
        break;
      case 'alarms':
        // Apre pagina allarmi con veicolo selezionato
        navigationExtras = {
          queryParams: {
            _id: this.selectedCar._id
          }
        };
        this.navCtrl.navigateRoot(['alarms'], navigationExtras);
        break;
      case 'streetView':
        // Apre streetview wsternamentio sulla posizione del veicolo
        if(!DataService.generalConfig.url_street_view )return false;
        window.open(DataService.generalConfig.url_street_view + this.selectedCar.virtualPosition.latitude + ',' + this.selectedCar.virtualPosition.longitude, 'blank');
        break;
      case 'insertPoi':
        let lat: number;
        let lng: number;
        if(this.selectedPosition){
          lat = this.selectedPosition.lat;
          lng = this.selectedPosition.lng;
        }
        if(this.selectedCar){
          lat = this.selectedCar.virtualPosition.latitude;
          lng = this.selectedCar.virtualPosition.longitude;
        }
        const modal = await this.modalCtrl.create({
          component: PoiAddPage,
          cssClass: "modal_alarm",
          componentProps: {
            appComponent: this.appComponent,
            lat: lat,
            lng: lng
          }
        });
        modal.onDidDismiss().then(() => {
          this.reloadMap.emit();
          DataService.updateData.next({
            type: "add/edit",
          })
        });
        await modal.present();
        break;
    }
  }

  async parseActionPoi(action: string){
    switch (action) {
      case 'modify':
        const modal = await this.modalCtrl.create({
          component: PoiAddPage,
          cssClass: "modal_alarm",
          componentProps: {
            appComponent: this.appComponent,
            poi: this.selectedPoi
          }
        });
        modal.onDidDismiss().then(() => {
          this.reloadMap.emit();
          DataService.updateData.next({
            type: "add/edit",
          })
        });
        await modal.present();
        break;
    }
  }

  getAddress(){
    this.api.getAddress(this.data.selOrganization.me._id, this.selectedCar.virtualPosition.latitude, this.selectedCar.virtualPosition.longitude).then((address: string) => {
      this.selectedCar.virtualPosition.address = address;
      ViewService.updateView.next();
    }).catch((err: any) => {
      console.error(err);
    });
  }

  getReport(){
    this.getReportApi().then(() => {
      ViewService.updateView.next();
    }).catch((err: any) => {
      console.error(err);
    });
  }

  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.cd.markForCheck();
    });
  }

  async openModalCarFields(){
    const modal = await this.modalCtrl.create({
      component: CarFieldsPage,
      componentProps: {
        participant: this.selectedCar,
      },
      cssClass: "modal_alarm"
    });
    return await modal.present();
  }

  getReportApi(){
    return new Promise(async (resolve, reject) => {
      try{
        let res: any;
        if(this.selectedCar.isDemo){
          res = this.data.getDemoReport(this.selectedCar, moment().startOf("day").valueOf(), moment().valueOf());
        }else{
          res = await this.api.getRoute(this.data.selOrganization.me._id, this.selectedCar._id, moment().startOf("day").valueOf(), moment().valueOf());
        }
        if(res.report){
          this.selectedCar.report = res.report;
          this.selectedCar.report_outdated = false;
          this.selectedCar.reportError = false;
          ViewService.updateView.next();
        }else{
          this.selectedCar.reportError = true;
          this.selectedCar.report = {};
        }
        return resolve(null);
      }catch(err){
        this.selectedCar.reportError = true;
        return reject(err);
      }
    });
  }
}
