import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  OnInit
} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {
  ModalController,
  NavController, PopoverController
} from "@ionic/angular";
import {
  ViewService
} from "../../../../services/common/view.service";
import {
  DataService
} from "../../../../services/common/data.service";
import {
  AuthService
} from "../../../../services/common/auth.service";
import {
  ApiService
} from "../../../../services/common/api.service";
import {
  PopoverFlagComponent
} from "../../../../components/desktop/popover-flag/popover-flag.component";
import {Utility} from "../../../../classes/common/utility";
import {AlertController} from "@ionic/angular";
import {MenuController} from "@ionic/angular";

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.page.html',
  styleUrls: ['./account-settings.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountSettingsPage implements OnInit {

  lang: any;
  pageContent: number = 1;
  backLabel: string;
  stepCount: number;
  steps = [
    "ACCOUNT_SETTINGS.step1",
    "ACCOUNT_SETTINGS.step2"
  ];

  organizationName: string;
  organizationNameError: string;
  language: string;
  languageError: string;
  languageCode: string;
  timezone: string;
  timezoneError: string;

  password: string;
  passwordError: string;
  email: string;
  emailError: string;

  newPassword: string;
  newPasswordError: string;
  confirmNewPassword: string;
  confirmNewPasswordError: string;

  // listener
  listener = {
    view: null,
    updateData: null
  };

  closeAction: any = () => {this.dismiss()};

  constructor(
    private cd: ChangeDetectorRef,
    public view: ViewService,
    private translate: TranslateService,
    private navCtrl: NavController,
    private modalCtrl: ModalController,
    public data: DataService,
    private auth: AuthService,
    private popoverCtrl: PopoverController,
    private api: ApiService,
    private alertController: AlertController,
    private menuCtrl: MenuController,
  ) {}

  ngOnInit() {
    if(this.data.selOrganization){
      this.organizationName = this.data.selOrganization.name;
      this.timezone = this.data.selOrganization.timezone;
      if(this.data.selOrganization.translate_language){
        let language: any = DataService.generalConfig.getLanguage(this.data.selOrganization.translate_language);
        if(language) {
          this.language = language.language;
          this.languageCode = language.code;
        }
        console.log(`this.languageCode`, this.languageCode)
      }
    }
    this.translate.get('ACCOUNT_SETTINGS').subscribe((lang: any) => {
      this.lang = lang;
    });
    ViewService.updateView.next();
  }

  ionViewWillEnter(){
    if(!AuthService.isLogged) this.navCtrl.navigateRoot('login');
  }

  ionViewDidEnter() {
    this.listenerView();
  }

  ionViewWillLeave() {
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  async openSelect(event: any, type: string){
    let options: any[] = [];
    let popover: any;
    switch (type) {
      case 'language':
        for(let lang of DataService.generalConfig.languages){
          options.push({
            id: lang.code,
            lbl: lang.language,
            flag: lang.code,
            selected: this.language === lang.language,
          });
        }
        popover = await this.popoverCtrl.create({
          backdropDismiss: true,
          component: PopoverFlagComponent,
          componentProps: {
            opts: options,
            enableSearch: false
          },
          cssClass: "popover_listPlain",
          event: event,
          showBackdrop: false,
        });
        popover.onDidDismiss().then((data: any) => {
          // console.log(`data`, data)
          if(data && data.data){
            // this.translate.use(data.data.id)
            this.language = data.data.lbl;
            ViewService.updateView.next();
          }
          this.checkFormError('language');
        });
        await popover.present();
        break;
      case 'timezone':
        for(let timezone of DataService.generalConfig.timezones){
          options.push({
            id: timezone,
            lbl: timezone,
            selected: this.timezone === timezone,
          });
        }
        popover = await this.popoverCtrl.create({
          backdropDismiss: true,
          component: PopoverFlagComponent,
          componentProps: {
            opts: options,
            enableSearch: true
          },
          cssClass: "popover_listPlain",
          event: event,
          showBackdrop: false,
        });
        popover.onDidDismiss().then((data: any) => {
          if(data && data.data){
            this.timezone = data.data.lbl;
            ViewService.updateView.next();
          }
          this.checkFormError('timezone');
        });
        await popover.present();
        break;
    }
  }

  // Effettua la verifica sul form
  async checkFormError(input: string, noTimeout?: boolean){
    if(!noTimeout) await Utility.wait(500);
    switch (input) {
      case 'organizationName':
        if(!this.organizationName){
          this.organizationNameError = this.lang.no_organization_name;
          break;
        }
        this.organizationNameError = undefined;
        break;
      case 'language':
        if(!this.language){
          this.languageError = this.lang.no_language;
          break;
        }
        this.languageError = undefined;
        break;
      case 'timezone':
        if(!this.timezone){
          this.timezoneError = this.lang.no_timezone;
          break;
        }
        this.timezoneError = undefined;
        break;
      case 'password':
        if(!this.password){
          this.passwordError = this.lang.no_password;
          break;
        }
        this.passwordError = undefined;
        break;
      case 'email':
        if(!this.email){
          this.emailError = this.lang.inserisci_email;
          break;
        }
        if(!Utility.validateEmail(this.email)){
          this.emailError = this.lang.email_non_valida;
          break;
        }
        this.emailError = undefined;
        break;
      case 'newPassword':
        if(!this.newPassword){
          this.newPasswordError = this.lang.no_password;
          break;
        }
        if(this.newPassword.length < 8){
          this.newPasswordError = this.lang.password_lunghezza;
          break;
        }
        if(this.confirmNewPassword && this.newPassword !== this.confirmNewPassword){
          this.confirmNewPasswordError = this.lang.password_non_coincidono;
          this.newPasswordError = this.confirmNewPasswordError;
          break;
        }
        if(this.confirmNewPasswordError === this.newPasswordError){
          this.confirmNewPasswordError = undefined;
        }
        this.newPasswordError = undefined;
        break;
      case 'confirmNewPassword':
        if(!this.confirmNewPassword){
          this.confirmNewPasswordError = this.lang.no_conferma_password;
          break;
        }
        if(this.newPassword !== this.confirmNewPassword){
          this.confirmNewPasswordError = this.lang.password_non_coincidono;
          this.newPasswordError = this.confirmNewPasswordError;
          break;
        }
        if(this.newPasswordError === this.confirmNewPasswordError){
          this.newPasswordError = undefined;
        }
        this.confirmNewPasswordError = undefined;
        break;
    }
    ViewService.updateView.next();
  }

  // Salva la configurazione (nome organizzazione ecc)
  async saveConfiguration(){
    this.checkFormError('organizationName', true);
    this.checkFormError('language', true);
    this.checkFormError('timezone', true);
    if(this.organizationNameError || this.languageError || this.timezoneError){
      return;
    }
    ViewService.updateView.next();
    let lang: any = DataService.generalConfig.languages.find((elem: any) => {
      return elem.language === this.language;
    });
    if(!lang) return;

    console.log(lang.code, this.data.selOrganization.translate_language, this.timezone, this.data.selOrganization.timezone);
    if(lang.code !== this.data.selOrganization.translate_language || this.timezone !== this.data.selOrganization.timezone){
      let buttons: any[] = [
        {
          label: this.lang.annulla,
          fill: 'outline',
          color: 'mid-light_base'
        },
        {
          label: this.lang.conferma,
          click: async () => {
            this.apiSaveConfiguration(lang);
          }
        }
      ];
      this.view.modalAlert(this.lang.conferma, this.lang.conferma_info, 'warn', buttons);
    }else{
      this.apiSaveConfiguration(lang);
    }
  }

  private async apiSaveConfiguration(lang: any){
    await this.view.showLoading();
    try{
      await this.api.patchOrganization(this.data.selOrganization._id, this.data.selOrganization.me._id, lang.code, this.timezone, this.organizationName);
      // this.translate.use(lang.code)
      await this.view.modalAlert(this.lang.successo, this.lang.configurazione_salvata, 'succ', this.lang.ok);
      if (lang.code !== this.languageCode) {
        // console.log(`translating to`, lang.code)
        location.reload()
      }
    }catch(err){
      console.error(err);
      this.view.modalAlert(this.lang.errori.errore, this.lang.errori.salvataggio_configurazione, 'warn', this.lang.ok);
    }
    await this.view.hideLoading();
    ViewService.updateView.next();
  }

  async changePageContent(isForward: boolean, type?: string) {
    switch (this.pageContent) {
      case 1: // pagina principale
        if(isForward){
          if(AuthService.token || AuthService.forcedEmail) return this.navigateToLogin();
          if(type === 'email'){
            this.stepCount = 1;
            this.steps[1] = "ACCOUNT_SETTINGS.step2";
            this.pageContent = 2; // cambio email
          }else if(type === 'password'){
            this.stepCount = 1;
            this.steps[1] = "ACCOUNT_SETTINGS.step3";
            this.pageContent = 4; // cambio password
          }else{
            // salvataggio configurazione
            this.saveConfiguration();
          }
        }else{
          return this.dismiss();
        }
        break;
      case 2: // prima pagina cambio email
        if(isForward){
          if(await this.verifyAuthentication()){
            this.stepCount = 2;
            this.pageContent = 3;
          }
        }else{
          this.stepCount = undefined;
          this.pageContent = 1;
        }
        break;
      case 3: // pagina cambio email
        if(isForward){
          this.changeEmail();
        }else{
          this.stepCount = undefined;
          this.pageContent = 1;
        }
        break;
      case 4: // prima pagina cambio password
        if(isForward){
          if(await this.verifyAuthentication()){
            this.stepCount = 2;
            this.pageContent = 5;
          }
        }else{
          this.stepCount = undefined;
          this.pageContent = 1;
        }
        break;
      case 5: // pagina cambio password
        if(isForward){
          this.changePassword();
        }else{
          this.stepCount = undefined;
          this.pageContent = 1;
        }
        break;
      default:
        this.dismiss();
        break;
    }
    ViewService.updateView.next();
  }

  async verifyAuthentication(): Promise<boolean>{
    if(AuthService.token || AuthService.forcedEmail) return;
    this.checkFormError('password', true);
    if(this.passwordError){
      return;
    }
    await this.view.showLoading();
    let success: boolean;
    try{
      await this.auth.reauthenticateWithCredential(this.password);
      success = true;
    }catch(err){
      console.error(err);
      ViewService.updateView.next();
      this.parseFirebaseError(err, this.lang.errori.autenticazione_fallita);
    }
    await this.view.hideLoading();
    return success;
  }

  // Cambia l'email del partecipant
  async changeEmail(){
    if(AuthService.token || AuthService.forcedEmail) return this.navigateToLogin();
    this.checkFormError('email', true);
    if(this.emailError){
      return;
    }
    await this.view.showLoading();
    try{
      let oldEmail: string = AuthService.getEmail();
      // 1. Aggiorno l'email di firebase
      await this.auth.updateEmail(this.email);
      // 2. Aggiorno l'email del partecipant
      try{
        await this.api.patchChangeEmailMe(this.data.selOrganization.me._id, oldEmail, AuthService.getEmail());
      }catch(err){
        console.error(err);
        // 2.bis Se è andata in errore l'api di aggiornamento partecipant rimetto quella vecchia di firebase
        try{
          await this.auth.updateEmail(oldEmail);
          await this.view.hideLoading();
          return await this.view.modalAlert(this.lang.errori.errore, this.lang.errori.errore_cambio_email, 'warn', this.lang.ok);
        }catch(err){
          console.error(err);
          // CASO PESSIMO - Notifico di contattare l'assistenza perchè abbiamo cambiato l'email dell'account firebase ma non del participant con tutti i problemi annessi
          await this.view.hideLoading();
          this.dismiss();
          await this.view.modalAlert(this.lang.errori.errore, this.lang.errori.errore_grave_cambio_email, 'warn', this.lang.ok);
        }
      }
      await this.view.hideLoading();
      this.dismiss();
      await this.view.modalAlert(this.lang.successo, this.lang.email_cambiata, 'succ', this.lang.ok);
    }catch(err){
      console.error(err);
      await this.view.hideLoading();
      this.parseFirebaseError(err, this.lang.errori.errore_cambio_email);
    }
  }

  // Cambia la password dell'account firebase
  async changePassword(){
    if(AuthService.token || AuthService.forcedEmail)  return this.navigateToLogin();
    this.checkFormError('newPassword', true);
    this.checkFormError('confirmNewPassword', true);
    if(this.newPasswordError || this.confirmNewPasswordError){
      return;
    }
    await this.view.showLoading();
    try{
      await this.auth.updatePassword(this.newPassword);
      await this.view.hideLoading();
      this.dismiss();
      await this.view.modalAlert(this.lang.successo, this.lang.password_cambiata, 'succ', this.lang.ok);
    }catch(err){
      console.error(err);
      await this.view.hideLoading();
      this.parseFirebaseError(err, this.lang.errori.errore_cambio_password);
    }
  }

  // Gestisce gli errori provenienti da firebase
  parseFirebaseError(err: any, defaultError: string){
    switch (err.code) {
      case 'auth/invalid-email':
        return this.view.modalAlert(this.lang.errori.errore, this.lang.errori.autenticazione_fallita, 'warn', this.lang.ok);
      case 'auth/user-disabled':
        return this.view.modalAlert(this.lang.errori.errore, this.lang.errori.utente_disabilitato, 'warn', this.lang.ok);
      case 'auth/user-not-found':
        return this.view.modalAlert(this.lang.errori.errore, this.lang.errori.utente_non_trovato, 'warn', this.lang.ok);
      case 'auth/wrong-password':
        return this.view.modalAlert(this.lang.errori.errore, this.lang.errori.password_errata, 'warn', this.lang.ok);
      case 'auth/email-already-in-use':
        return this.view.modalAlert(this.lang.errori.errore, this.lang.errori.email_in_uso, 'warn', this.lang.ok);
      case 'auth/weak-password':
        return this.view.modalAlert(this.lang.errori.errore, this.lang.errori.password_non_abbastanza_complessa, 'warn', this.lang.ok);
    }
    return this.view.modalAlert(this.lang.errori.errore, defaultError, 'warn', this.lang.ok);
  }

  // Cambia lo stato del servizio REST API
  async changeApiStatus(){
    let message: string = this.lang.sei_sicuro_info_api_ab;
    if(this.data.selOrganization.me.api_enabled){
      message = this.lang.sei_sicuro_info_api_dis;
    }
    let buttons: any[] = [
      {
        label: this.lang.annulla,
        color: 'mid-light_base',
        fill: 'outline',
      },
      {
        label: this.lang.conferma,
        click: async () => {
          await this.view.showLoading();
          try{
            const res: any = await this.api.patchApiSettingsParticipant(this.data.selOrganization.me._id, !this.data.selOrganization.me.api_enabled, !this.data.selOrganization.me.api_enabled ? true : undefined);
            this.data.selOrganization.me.api_enabled = res.api_enabled;
            this.data.selOrganization.me.api_token = res.api_token;
            ViewService.updateView.next();
          }catch(err){
            console.error(err);
            this.view.modalAlert(this.lang.errori.errore, this.lang.errori.errore_api_settings, 'warn',this.lang.ok);
          }
          await this.view.hideLoading();
        }
      }
    ];
    this.view.modalAlert(this.lang.sei_sicuro, message, 'warn', buttons);
  }

  // Revoca e rigenera un'altro api token
  async revokeApiToken(){
    let message: string = this.lang.sei_sicuro_rigenerazione_token;
    let buttons: any[] = [
      {
        label: this.lang.annulla,
        color: 'mid-light_base',
        fill: 'outline',
      },
      {
        label: this.lang.conferma,
        click: async () => {
          await this.view.showLoading();
          try{
            const res: any = await this.api.patchApiSettingsParticipant(this.data.selOrganization.me._id, undefined, true);
            this.data.selOrganization.me.api_enabled = res.api_enabled;
            this.data.selOrganization.me.api_token = res.api_token;
            ViewService.updateView.next();
          }catch(err){
            console.error(err);
            this.view.modalAlert(this.lang.errori.errore, this.lang.errore_api_settings, 'warn',['Ok']);
          }
          await this.view.hideLoading();
        }
      }
    ];
    this.view.modalAlert(this.lang.sei_sicuro, message, 'warn', buttons);
    // this.data.selOrganization.me.api_enabled = !this.data.selOrganization.me.api_enabled;
    // ViewService.updateView.next();
  }

  openDocumentation(){
    try{
      window.open(DataService.generalConfig.sito.url_documentation, '_blank');
    }catch(err){
      console.error(err);
    }
  }

  /** listener **/
  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.cd.markForCheck();
      setTimeout(() => {
        this.cd.markForCheck();
      }, 250);
    });
  }

  async navigateToLogin(){
    const alert = await this.alertController.create({
      header: this.lang.conferma,
      message: this.lang.conferma_authtoken,
      buttons: [
        {
          text: this.lang.annulla,
          role: 'cancel'
        },
        {
          text: this.lang.conferma,
          handler: async () => {
            if(AuthService.token){
              await this.auth.logout();
              this.dismiss();
              this.menuCtrl.toggle('sidebar')
            }
          },
        },
      ],
    });
    await alert.present();
    return;
  }
}
