import {PositionGps} from "./position-gps";
import {AlarmPoi} from "./alarm-poi";
import {VirtualPosition} from "./virtualPosition";
import * as lodash from 'lodash';
type FunctionalityType = 'owner' | 'alarms' | 'vehicles' | 'billing' | 'history' | 'report' | 'poi' | 'activation';

export class Partecipant {

  static engineStates: any = {
    0: 'on',
    1: 'off'
  };

  _id: string;
  type: number;
  idOrganization: string;
  typeUser: number;
  email: string;
  isOwner: boolean;
  api_enabled: boolean;
  api_token: string;

  isActive: boolean;
  timestampActivation: number;
  timestampActivation_ms: number;
  timestampDeactivation: number;
  timestampDeactivation_ms: number;

  isPersonalized: boolean;
  plan: number;
  total_odometer: number;
  isOnline: boolean;
  timestamp_isOnline: number;
  isGPSOnline: boolean;
  timestamp_isGPSOnline: number;
  isPowerOn: boolean;
  timestamp_isPowerOn: number;
  battery_voltage: number;
  real_odometer: number;
  timestamp_position: number;
  satellites: number;
  havePosition: boolean;
  dout1: number;
  timestamp_dout1: number;
  dout1_label: string; // Aggiunto frontend
  latitude: number;
  longitude: number;
  din1: number;
  timestamp_din1: number;
  din1_name: string;
  din1_label_0: string;
  din1_label_1: string;
  din1_stop_enabled: boolean;
  din1_moving_enabled: boolean;

  timestamp_last_command_position: number; // Aggiunto frontend
  timestamp_last_command_reset: number; // Aggiunto frontend
  timestamp_last_command_dout1: number; // Aggiunto frontend
  response_last_command_position: string; // Aggiunto frontend
  response_last_command_reset: string; // Aggiunto frontend
  response_last_command_dout1: string; // Aggiunto frontend
  response_last_command_dout1_label: string; // Aggiunto frontend
  timeoutCommandPending: any; // Aggiunto frontend
  lastCommandInPending: string; // Aggiunto frontend (dice se c'era un comando in pending e che tipo di comando era 'reset', 'position')

  // Far mettere da dani
  name: string; // messo, ma dal server si chiama label_string;

  label_number: number;

  // per type == 1
  list_partecipant: string[];

  functionality_alarm: boolean;
  functionality_vehicle: boolean;
  functionality_billing: boolean;
  functionality_map: boolean;
  functionality_historical: boolean;
  functionality_report: boolean;
  functionality_poi: boolean;
  functionality_activation: boolean;

  // per type == 2
  typeTracker: number;
  trackerImageName: string;
  imei: string;
  lastPositionGps: PositionGps;
  virtualPosition: VirtualPosition;

  // per frontend
  inMoving: boolean;
  alarmsPoi: AlarmPoi[];

  // allarme di velocità
  speed_alarm_enable: boolean;
  speed_alarm_value: number;
  speed_alarm_hour_start: number;
  speed_alarm_minute_start: number;
  speed_alarm_hour_stop: number;
  speed_alarm_minute_stop: number;
  speed_alarm_day: boolean[];
  speed_alarm_day_default: boolean; // aggiunto per frontend (dice se sono esistenti o settasti di default)
  speed_alarm_email: string[];
  speed_alarm_partecipant: string[];

  // allarme offline
  offline_alarm_enable: boolean;
  offline_alarm_value: number;
  offline_alarm_hour_start: number;
  offline_alarm_minute_start: number;
  offline_alarm_hour_stop: number;
  offline_alarm_minute_stop: number;
  offline_alarm_day: boolean[];
  offline_alarm_day_default: boolean; // aggiunto per frontend (dice se sono esistenti o settasti di default)
  offline_alarm_email: string[];
  offline_alarm_partecipant: string[];

  // allarme di movimento
  movement_alarm_enable: boolean;
  movement_alarm_hour_start: number;
  movement_alarm_minute_start: number;
  movement_alarm_hour_stop: number;
  movement_alarm_minute_stop: number;
  movement_alarm_day: boolean[];
  movement_alarm_day_default: boolean; // aggiunto per frontend (dice se sono esistenti o settasti di default)
  movement_alarm_email: string[];
  movement_alarm_partecipant: string[];

  // allarme di scollegamento
  unplug_alarm_enable: boolean;
  unplug_alarm_hour_start: number;
  unplug_alarm_minute_start: number;
  unplug_alarm_hour_stop: number;
  unplug_alarm_minute_stop: number;
  unplug_alarm_day: boolean[];
  unplug_alarm_day_default: boolean; // aggiunto per frontend (dice se sono esistenti o settasti di default)
  unplug_alarm_email: string[];
  unplug_alarm_partecipant: string[];

  // allarme di parcheggio
  parking_alarm_enable: boolean;
  parking_alarm_hour_start: number;
  parking_alarm_minute_start: number;
  parking_alarm_hour_stop: number;
  parking_alarm_minute_stop: number;
  parking_alarm_day: boolean[];
  parking_alarm_day_default: boolean; // aggiunto per frontend (dice se sono esistenti o settasti di default)
  parking_alarm_email: string[];
  parking_alarm_partecipant: string[];

  // allarme input 1
  din1_alarm_enable: boolean;
  din1_alarm_value: number; // 0/1
  din1_alarm_hour_start: number;
  din1_alarm_minute_start: number;
  din1_alarm_hour_stop: number;
  din1_alarm_minute_stop: number;
  din1_alarm_day: boolean[];
  din1_alarm_day_default: boolean; // aggiunto per frontend (dice se sono esistenti o settasti di default)
  din1_alarm_email: string[];
  din1_alarm_partecipant: string[];


  // Per frontend
  hideOnMap: boolean;
  isDemo: boolean;
  initials: string;
  report: any;
  report_outdated: boolean;
  reportError: boolean;
  hideInList: boolean;
  hideInListTimeout: any;
  $fleetShow: boolean = true; // Sicurezza di default fa vedere il veicolo a prescindere della flotta selezionata
  $selected: boolean;

  last_virtualPosition_address: string;

  constructor(data?: any){
    if(data){
      this.setData(data);
    }
  }

  setData(data: any){

    this.initials = data.email?.charAt(0).toUpperCase();

    this._id = data._id;
    this.isDemo = data.isDemo; // Aggiunto in creazione veicoli demo (non arriva dal server)
    this.type = data.type;
    this.idOrganization = data.idOrganization;
    this.typeUser = data.typeUser;
    this.email = data.email;
    this.isOwner = data.isOwner;
    this.api_enabled = data.api_enabled;
    this.api_token = data.api_token;

    this.isActive = data.isActive;
    this.timestampActivation = data.timestampActivation;
    if(this.timestampActivation) this.timestampActivation_ms = this.timestampActivation * 1000;
    this.timestampDeactivation = data.timestampDeactivation;
    if(this.timestampDeactivation) this.timestampDeactivation_ms = this.timestampDeactivation * 1000;

    this.isPersonalized = data.isPersonalized;
    this.plan = data.plan;
    this.total_odometer = data.total_odometer;
    this.name = data.label_string;
    this.label_number = data.label_number;
    this.isOnline = data.isOnline;
    this.timestamp_isOnline = data.timestamp_isOnline;
    this.isGPSOnline = data.isGPSOnline;
    this.timestamp_isGPSOnline = data.timestamp_isGPSOnline;
    this.isPowerOn = data.isPowerOn;
    this.timestamp_isPowerOn = data.timestamp_isPowerOn;
    this.battery_voltage = data.battery_voltage;
    this.real_odometer = data.real_odometer;
    this.timestamp_position = data.timestamp_position;
    this.satellites = data.satellites;
    this.havePosition = data.havePosition;
    this.dout1 = data.dout1;
    this.timestamp_dout1 = data.timestamp_dout1;
    if(this.dout1 || this.dout1 === 0){
      this.dout1_label = Partecipant.engineStates[this.dout1];
      if(!this.dout1_label) this.dout1_label = 'unknow';
    }else{
      this.dout1_label = "not_set";
    }
    this.list_partecipant = data.list_partecipant;
    if(!this.list_partecipant) this.list_partecipant = [];
    this.functionality_alarm = data.functionality_alarm;
    this.functionality_vehicle = data.functionality_veichle;
    this.functionality_billing = data.functionality_billing;
    this.functionality_map = data.functionality_map;
    this.functionality_historical = data.functionality_historical;
    this.functionality_report = data.functionality_report;
    this.functionality_poi = data.functionality_poi;
    this.functionality_activation = data.functionality_activation;
    this.din1 = data.din1;
    this.timestamp_din1 = data.timestamp_din1;
    this.din1_name = data.din1_name;
    this.din1_label_0 = data.din1_label_0;
    this.din1_label_1 = data.din1_label_1;
    this.din1_stop_enabled = data.din1_stop_enabled;
    this.din1_moving_enabled = data.din1_moving_enabled;

    this.typeTracker = data.type_trackerGps;
    this.imei = data.imei;
    if(data.lastPositionGps){
      this.lastPositionGps = new PositionGps(data.lastPositionGps);
    }
    if(data.virtualPosition){
      if(this.virtualPosition){
        if(this.virtualPosition.address) this.last_virtualPosition_address = this.virtualPosition?.address;

        this.virtualPosition.setData(data.virtualPosition);
      }else{
        this.virtualPosition = new VirtualPosition(data.virtualPosition);
      }

      if(this.report){ //Il veicolo ha un report, dico che è obsoleto perche nel frattempo è arrivata una nuova posizione quindi per avere il report corretto è necessario aggiornare il report
        this.report_outdated = true;
      }
    }
    this.latitude = data.latitude;
    this.longitude = data.longitude;

    this.speed_alarm_enable = data.speed_alarm_enable;
    this.speed_alarm_value = data.speed_alarm_value;
    this.speed_alarm_hour_start = data.speed_alarm_hour_start;
    this.speed_alarm_minute_start = data.speed_alarm_minute_start;
    this.speed_alarm_hour_stop = data.speed_alarm_hour_stop;
    this.speed_alarm_minute_stop = data.speed_alarm_minute_stop;
    this.speed_alarm_day = data.speed_alarm_day;
    this.speed_alarm_email = data.speed_alarm_email;
    this.speed_alarm_partecipant = data.speed_alarm_partecipant;
    if(!this.speed_alarm_day || this.speed_alarm_day.length < 7){
      this.speed_alarm_day = [false, false, false, false, false, false, false];
      this.speed_alarm_day_default = true;
    }

    this.offline_alarm_enable = data.offline_alarm_enable;
    this.offline_alarm_value = data.offline_alarm_value;
    this.offline_alarm_hour_start = data.offline_alarm_hour_start;
    this.offline_alarm_minute_start = data.offline_alarm_minute_start;
    this.offline_alarm_hour_stop = data.offline_alarm_hour_stop;
    this.offline_alarm_minute_stop = data.offline_alarm_minute_stop;
    this.offline_alarm_day = data.offline_alarm_day;
    this.offline_alarm_email = data.offline_alarm_email;
    this.offline_alarm_partecipant = data.offline_alarm_partecipant;
    if(!this.offline_alarm_day || this.offline_alarm_day.length < 7) {
      this.offline_alarm_day = [false, false, false, false, false, false, false];
      this.offline_alarm_day_default = true;
    }

    this.movement_alarm_enable = data.movement_alarm_enable;
    this.movement_alarm_hour_start = data.movement_alarm_hour_start;
    this.movement_alarm_minute_start = data.movement_alarm_minute_start;
    this.movement_alarm_hour_stop = data.movement_alarm_hour_stop;
    this.movement_alarm_minute_stop = data.movement_alarm_minute_stop;
    this.movement_alarm_day = data.movement_alarm_day;
    this.movement_alarm_email = data.movement_alarm_email;
    this.movement_alarm_partecipant = data.movement_alarm_partecipant;
    if(!this.movement_alarm_day || this.movement_alarm_day.length < 7){
      this.movement_alarm_day = [false, false, false, false, false, false, false];
      this.movement_alarm_day_default = true;
    }

    this.unplug_alarm_enable = data.unplug_alarm_enable;
    this.unplug_alarm_hour_start = data.unplug_alarm_hour_start;
    this.unplug_alarm_minute_start = data.unplug_alarm_minute_start;
    this.unplug_alarm_hour_stop = data.unplug_alarm_hour_stop;
    this.unplug_alarm_minute_stop = data.unplug_alarm_minute_stop;
    this.unplug_alarm_day = data.unplug_alarm_day;
    this.unplug_alarm_email = data.unplug_alarm_email;
    this.unplug_alarm_partecipant = data.unplug_alarm_partecipant;
    if(!this.unplug_alarm_day || this.unplug_alarm_day.length < 7){
      this.unplug_alarm_day = [false, false, false, false, false, false, false];
      this.unplug_alarm_day_default = true;
    }

    this.parking_alarm_enable = data.parking_alarm_enable;
    this.parking_alarm_hour_start = data.parking_alarm_hour_start;
    this.parking_alarm_minute_start = data.parking_alarm_minute_start;
    this.parking_alarm_hour_stop = data.parking_alarm_hour_stop;
    this.parking_alarm_minute_stop = data.parking_alarm_minute_stop;
    this.parking_alarm_day = data.parking_alarm_day;
    this.parking_alarm_email = data.parking_alarm_email;
    this.parking_alarm_partecipant = data.parking_alarm_partecipant;
    if(!this.parking_alarm_day || this.parking_alarm_day.length < 7) {
      this.parking_alarm_day = [false, false, false, false, false, false, false];
      this.parking_alarm_day_default = true;
    }

    this.din1_alarm_enable = data.din1_alarm_enable;
    this.din1_alarm_value = data.din1_alarm_value;
    this.din1_alarm_hour_start = data.din1_alarm_hour_start;
    this.din1_alarm_minute_start = data.din1_alarm_minute_start;
    this.din1_alarm_hour_stop = data.din1_alarm_hour_stop;
    this.din1_alarm_minute_stop = data.din1_alarm_minute_stop;
    this.din1_alarm_day = data.din1_alarm_day;
    this.din1_alarm_email = data.din1_alarm_email;
    this.din1_alarm_partecipant = data.din1_alarm_partecipant;
    if(!this.din1_alarm_day || this.din1_alarm_day.length < 7) {
      this.din1_alarm_day = [false, false, false, false, false, false, false];
      this.din1_alarm_day_default = true;
    }
  }


  addAlarmPoi(alarm: AlarmPoi){
    if(!this.alarmsPoi) this.alarmsPoi = [];
    const oldAlarms: AlarmPoi = this.alarmsPoi.find((elem: AlarmPoi) => {
      return elem._id === alarm._id;
    });
    if(!oldAlarms){
      this.alarmsPoi.push(alarm);
    }
  }

  removeAlarmPoi(id: string){
    for(let i=0; i<this.alarmsPoi.length; i++){
      if(this.alarmsPoi[i]._id === id){
        this.alarmsPoi.splice(i, 1);
        return;
      }
    }
  }

  isTracker(){
    return this.type === 2;
  }

  isSelectableTracker(){
    return this.type === 2 && this.virtualPosition && this.virtualPosition.havePosition;
  }

  // Dice se il modello di localizzatore ha l'input 1;
  haveDin1(): boolean{
    return this.typeTracker === 1;
  }

  // Dice se il modello di localizzatore ha l'output1
  haveDout1(): boolean{
    return this.typeTracker === 1;
  }

  // Dice se l'alimentazione è funzionante
  isPowerOk(): boolean{
    return this.isDemo || (!this.isDemo && this.isPowerOn);
  }

  // Dice se il gsm funziona correttamente
  isGSMOk(): boolean{
    return this.isDemo || (this.virtualPosition && this.virtualPosition.check_isOnline !== 3);
  }

  // Dice se il gps funziona correttamente
  isGPSOk(): boolean{
    return this.isDemo || this.isGPSOnline;
  }

  // Verifica i permessi del partecipante
  functionalityIsEnabled(type: FunctionalityType){
    if(this.isOwner) return true;
    switch (type) {
      case 'owner':
        // return false;
        return this.isOwner;
      case 'alarms':
        // return true;
        return this.functionality_alarm;
      case 'vehicles':
        return this.functionality_vehicle;
      case 'billing':
        // return true;
        return this.functionality_billing;
      case 'history':
        return this.functionality_historical;
      case 'report':
        return this.functionality_report;
      case 'poi':
        // return true;
        return this.functionality_poi;
      case 'activation':
        // return false;
        return this.functionality_activation;
      default:
        return false;
    }
  }

  // Controllo se il partecipant puo essere visulizzato in app quando è applicata un filtro flotta
  isInFleet(){
    return this.$fleetShow;
  }

  static commandStates: any = {
    1: "sent",
    2: "in_sent",
    // 3: "sent", // scommentare per far scattare il timeout di comando non andato a buon fine
    // 4: "sent" // scommentare per far scattare il timeout di comando non andato a buon fine
    3: "error",
    4: "success"
  };

  setLastCommands(res: any) {
    this.timestamp_last_command_position = 0;
    this.timestamp_last_command_reset = 0;
    this.timestamp_last_command_dout1 = 0;
    if (!res) return;
    if (res.list_commandTrackerGps_finished) {
      for (let raw of res.list_commandTrackerGps_finished) {
        console.log(raw)
        if (raw.type === 4 && this.timestamp_last_command_position <= raw.timestamp_lastSend) { //comando posizione
          this.timestamp_last_command_position = raw.timestamp_lastSend;
          this.response_last_command_position = Partecipant.commandStates[raw.status_commandTrackerGps];
          if (!this.response_last_command_position) this.response_last_command_position = "unknow";
        }else if (raw.type === 5 && this.timestamp_last_command_reset <= raw.timestamp_lastSend) { //comando reset
          this.timestamp_last_command_reset = raw.timestamp_lastSend;
          this.response_last_command_reset = Partecipant.commandStates[raw.status_commandTrackerGps];
          if (!this.response_last_command_reset) this.response_last_command_reset = "unknow";
        } else if (raw.type === 3 && this.timestamp_last_command_dout1 <= raw.timestamp_lastSend) { //comando blocco motore
          this.timestamp_last_command_dout1 = raw.timestamp_lastSend;
          this.response_last_command_dout1 = Partecipant.commandStates[raw.status_commandTrackerGps];
          if (!this.response_last_command_dout1) this.response_last_command_dout1 = "unknow";
          if(raw.status_output || raw.status_output === 0){
            this.response_last_command_dout1_label = Partecipant.engineStates[raw.status_output];
            if(!this.response_last_command_dout1_label) this.dout1_label = 'unknow';
          }else{
            this.response_last_command_dout1_label = "not_set";
          }
        }
      }
    }
    if (res.list_commandTrackerGps) {
      for (let raw of res.list_commandTrackerGps) {
        if (raw.type === 4 && this.timestamp_last_command_position <= raw.timestamp_lastSend) { //comando posizione
          this.timestamp_last_command_position = raw.timestamp_lastSend;
          this.response_last_command_position = Partecipant.commandStates[raw.status_commandTrackerGps];
          if (!this.response_last_command_position) this.response_last_command_position = "unknow";
        } else if (raw.type === 5 && this.timestamp_last_command_reset <= raw.timestamp_lastSend) { //comando reset
          this.timestamp_last_command_reset = raw.timestamp_lastSend;
          this.response_last_command_reset = Partecipant.commandStates[raw.status_commandTrackerGps];
          if (!this.response_last_command_reset) this.response_last_command_reset = "unknow";
        } else if (raw.type === 3 && this.timestamp_last_command_dout1 <= raw.timestamp_lastSend) { //comando blocco motore
          this.timestamp_last_command_dout1 = raw.timestamp_lastSend;
          this.response_last_command_dout1 = Partecipant.commandStates[raw.status_commandTrackerGps];
          if (!this.response_last_command_dout1) this.response_last_command_dout1 = "unknow";
          if(raw.status_output || raw.status_output === 0){
            this.response_last_command_dout1_label = Partecipant.engineStates[raw.status_output];
            if(!this.response_last_command_dout1_label) this.dout1_label = 'unknow';
          }else{
            this.response_last_command_dout1_label = "not_set";
          }
        }
      }
    }
  }

  // Crea una posizione nuova di un veicolo demo
  resetDemoPosition(pos: any){
    if(!this.report || !this.report.total_distance || !this.report.time_trip){
      this.report = {
        total_distance: 6000, // 6 km
        time_trip: 60 * 1000 * 15 // 15 min
      }
    }else{
      this.report.total_distance += Math.random() * (90 - 1) + 1;
      this.report.time_trip += Math.random() * (10000 - 1) + 1;
    }
    if(!this.virtualPosition) this.virtualPosition = new VirtualPosition();
    this.virtualPosition.setData({
      havePosition: true,
      tripStatus: pos.position_type === 'moving',
      haveAnomaly: false,
      timestamp: Date.now() - (5 * 60 * 1000),
      timestamp_expired: Date.now() + 1000000000,
      longitude: pos.longitude,
      latitude: pos.latitude,
      angle: pos.angle,
      odometer_trip: 10000,
      speed: pos.speed
    });
    this.virtualPosition.address = undefined;
    this.total_odometer = pos.total_odometer;
  }

  // Dice se abbiamo la posizione oppure no
  hasPosition(): boolean{
    return (this.virtualPosition && this.virtualPosition.havePosition);
  }

  // Dive se il particpinat è in movimento o fermo;
  isMoving(): boolean{
    return (this.hasPosition() && this.virtualPosition.tripStatus === true);
  }

  getIn1State(): true | false | undefined{
    if(this.din1_moving_enabled && this.virtualPosition.tripStatus){
      return !!this.din1;
    }
    if(this.din1_stop_enabled && !this.virtualPosition.tripStatus){
      return !!this.din1;
    }
    return;
  }
}

export class HoursMinutes{
  hours: number;
  minutes: number;

  constructor(time: string) {
    let times: any[] = time.split(':');
    this.hours = parseInt(times[0]);
    this.minutes = parseInt(times[1]);
  }

  // Controlla se le date di start e stop si accavallano
  static isValidIntervalTime(start: HoursMinutes, stop: HoursMinutes): boolean{
    if(start.hours > stop.hours){
      return false;
    }else if(start.hours === stop.hours){
      if(start.minutes >= stop.minutes) return false
    }
    return true;
  }

  // Traduce valori interi in stringa HH:mm
  static getStringValue(hours: number, minutes: number): string{
    let res: string = '';
    if(!hours || hours < 10) res += '0';
    res += (hours || 0) + ':';
    if(!minutes || minutes < 10) res += '0';
    res += (minutes || 0);
    return res;
  }

}



