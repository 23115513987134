import {Injectable} from '@angular/core';
import {Subject} from "rxjs";
import {GoogleMapsService} from "./google-map.service";
import {
  Partecipant
} from "../../classes/common/partecipant";
import {Poi} from "../../classes/common/poi";
import {Utility} from "../../classes/common/utility";
import {MARKER_WITH_DIRECTION} from "src/models/markerSize";

@Injectable({
  providedIn: 'root'
})
export class MainMapService {

  readonly MAP_NAME: string = "main";

  static iconsRed: any[]; // array di {0: {min: 347, max:23. url: '...}}
  static iconsGreen: any[]; // array di {0: {min: 347, max:23. url: '...}}
  static iconPoi: string; // url dell'icona dei POI
  static iconDisabled: string; // url icona quando c'è qualche errore
  static infotrafficLabel: string; // label etichetta infotraffico
  static infotrafficImage: string; // Url immagine anteprima infotraffico
  static roadMapLabel: string; // label etichetta read map
  static roadMapImage: string; // Url immagine anteprima road map
  static satelliteMapLabel: string; // label etichetta mappa satellitare
  static satelliteMapImage: string; // Url immagine anteprima mappa satellitare
  static isMobile: boolean;

  mapType: string;
  map: any;
  participantMarkers: any[] = [];
  popupMarkers: any[] = [];
  poiMarkers: any[] = [];
  infoWindow: any;
  circle: any;
  selectedMarker: any;

  onSelectMarker = new Subject<any>();
  onSelectMarker$ = this.onSelectMarker.asObservable();

  buttonMapType_1: any;
  buttonMapType_2: any;
  infoTrafficEnabled: boolean = true;

  needResize: boolean;

  static currentZoom: number;
  static currentCenter: any;
  static currentInfotraffic: boolean;
  static currentMapType:  string;
  static initCount: number = -1;

  static labelVisible: boolean = true;
  static popupVisible: boolean = true;

  constructor(
    public googleMaps: GoogleMapsService
  ) {
  }

  initMap(
    mapType: string,
    iconsRed: any,
    iconsGreen: any,
    iconPoi: string,
    iconDisabled: string,
    infotrafficLabel: string,
    infotrafficImage: string,
    roadMapLabel: string,
    roadMapImage: string,
    satelliteMapLabel: string,
    satelliteMapImage: string,
    lightInit: boolean,
    isMobile?: boolean
  ) {
    MainMapService.iconsRed = iconsRed;
    MainMapService.iconsGreen = iconsGreen;
    MainMapService.iconPoi = iconPoi;
    MainMapService.iconDisabled = iconDisabled;
    MainMapService.infotrafficLabel = infotrafficLabel;
    MainMapService.infotrafficImage = infotrafficImage;
    MainMapService.roadMapLabel = roadMapLabel;
    MainMapService.roadMapImage = roadMapImage;
    MainMapService.satelliteMapLabel = satelliteMapLabel;
    MainMapService.satelliteMapImage = satelliteMapImage;
    MainMapService.isMobile = isMobile;

    this.mapType = mapType;
    switch (this.mapType) {
      case "Google":
        this.initMapGoogle(lightInit);
        break;
    }
  }

  drawSelectMarker(lat: number, lng: number){
    switch (this.mapType) {
      case "Google":
        this.drawSelectMarkerGoogle(lat, lng);
        break;
    }
  }

  clearMap(){
    this.selectedMarker = undefined;
    this.poiMarkers = [];
    this.participantMarkers = [];
    this.popupMarkers = [];
    // this.cleanStopMarkers();
    // this.cleanPolyline();
    // this.stopMarkers = [];
    // this.specialMarkers = [];
    // this.polyline = undefined;
    // this.selectedMarker = undefined;
  }

  drawParticipantMarkers(participants: Partecipant[]){
    switch (this.mapType) {
      case "Google":
        this.drawParticipantMarkersGoogle(participants);
        break;
    }
  }

  toggleVisibilityParticipant(participant: Partecipant, showPopup: boolean){
    switch (this.mapType) {
      case "Google":
        this.toggleVisibilityParticipantGoogle(participant, showPopup);
        break;
    }
  }

  drawPoiMarkers(pois: Poi[]){
    switch (this.mapType) {
      case "Google":
        this.drawPoiMarkersGoogle(pois);
        break;
    }
  }

  toggleVisibilityPoi(poi: Poi){
    switch (this.mapType) {
      case "Google":
        this.toggleVisibilityPoiGoogle(poi);
        break;
    }
  }

  changeZoom(delta: number){
    switch (this.mapType) {
      case "Google":
        this.changeZoomGoogle(delta);
        break;
    }
  }

  resizeMap(){
    switch (this.mapType) {
      case "Google":
        this.resizeMapGoogle();
        break;
    }
  }

  setCenter(lat: number, lng: number){
    switch (this.mapType) {
      case "Google":
        this.setCenterGoogle(lat, lng);
        break;
    }
  }

  setMapType(type: string){
    switch (this.mapType) {
      case "Google":
        this.setMapTypeGoogle(type);
        break;
    }
  }

  getMapType(){
    switch (this.mapType) {
      case "Google":
        return this.getMapTypeGoogle();
    }
  }

  selectParticipant(id: string){
    switch (this.mapType) {
      case "Google":
        return this.selectParticipantGoogle(id);
        break;
    }
  }

  selectPoi(id: string){
    switch (this.mapType) {
      case "Google":
        return this.selectPoiGoogle(id);
        break;
    }
  }

  drawCirclePoi(poi: Poi){
    switch (this.mapType) {
      case "Google":
        return this.drawCirclePoiGoogle(poi);
        break
    }
  }

  removeCirclePoi(){
    switch (this.mapType) {
      case "Google":
        return this.removeCirclePoiGoogle();
        break
    }
  }

  // Visualizza o elimina il label numerico all'interno dei marker
  toggleVisibilityLabel(visible: boolean){
    MainMapService.labelVisible = visible;
    switch (this.mapType) {
      case "Google":
        return this.toggleVisibilityLabelGoogle(visible);
    }
  }

  // Visualizza o elimina popup per il label number sopra i marker delle posizioni
  toggleVisibilityPopup(visible: boolean){
    MainMapService.popupVisible = visible;
    switch (this.mapType) {
      case "Google":
        return this.toggleVisibilityPopupGoogle(visible);
    }
  }

  // Aggiorna il marker dopo una notifica di cambio posizione oppure cambio label o cose simili
  updateParticipantMarker(participant: Partecipant, resizePopup?: boolean){
    switch (this.mapType) {
      case "Google":
        return this.updateParticipantMarkerGoogle(participant, resizePopup);
    }
  }

  // Aggiorna il marker dopo una notifica di cambio di un poi
  updatePoiMarker(poi: Poi){
    switch (this.mapType) {
      case "Google":
        return this.updatePoiMarkerGoogle(poi);
    }
  }

  removeParticipantMarker(id: string){
    switch (this.mapType) {
      case "Google":
        return this.removeParticipantMarkerGoogle(id);
    }
  }

  removePoiMarker(id: string){
    switch (this.mapType) {
      case "Google":
        return this.removePoiMarkerGoogle(id);
    }
  }

  // Chiude l'infowindow
  closeInfoWindow(){
    switch (this.mapType) {
      case "Google":
        return this.closeInfoWindowGoogle();
    }
  }

  static clearService(){
    MainMapService.initCount = -1;
    MainMapService.currentZoom = undefined;
    MainMapService.currentCenter = undefined;
    MainMapService.currentInfotraffic = undefined;
    MainMapService.currentMapType = undefined;
  }

  requestResize(consumed?: boolean){
    this.needResize = !consumed;
  }

  isToResize(): boolean{
    return this.needResize;
  }

  // Dice se la mappa è già visibile
  isCurrentMap(){
    return this.googleMaps.currentMap === this.MAP_NAME;
  }

  /*// Visualizza o meno i poi sulla mappa
  toggleVisibilityPoiMarker(visible: boolean){
    switch (this.mapType) {
      case "Google":
        return this.toggleVisibilityPoiMarkerGoogle(visible);
    }
  }*/



  /*** GOOGLE MAPS ***/

  private async initMapGoogle(lightInit: boolean) {
    this.clearMap();
    await this.googleMaps.isReady(); // Attende che google sia caricato
    this.googleMaps.clearMap();
    this.map = this.googleMaps.getMap();
    let mapContainer: any = document.getElementById("main-map");
    if(mapContainer){
      mapContainer.appendChild(this.googleMaps.getDivMap());
      this.googleMaps.setCurrentMap(this.MAP_NAME);
    }
    const listenerRef: any = this.map.addListener('rightclick', (event: any) => {
      this.drawSelectMarkerGoogle(event.latLng.lat(), event.latLng.lng());
    });
    this.googleMaps.addListener(listenerRef, this.MAP_NAME);
    const listenerClick: any = this.map.addListener('click', (event: any) => {
      this.closeInfoWindow();
    });
    this.googleMaps.addListener(listenerClick, this.MAP_NAME);
    const listenerZoomChange: any = this.map.addListener("zoom_changed", () => {
      MainMapService.currentZoom = this.map.getZoom();
    });
    this.googleMaps.addListener(listenerZoomChange, this.MAP_NAME);
    const listenerCenterChange: any = this.map.addListener("center_changed", () => {
      MainMapService.currentCenter = this.map.getCenter();
    });
    this.googleMaps.addListener(listenerCenterChange, this.MAP_NAME);
    if(!lightInit){
      this.infoWindow = new GoogleMapsService.google.maps.InfoWindow();
      this.infoWindow.setContent(document.getElementById("main-map-infowindow") as HTMLElement);
      this.infoWindow.setZIndex(4000);
    }
    MainMapService.initCount++;
    this.map.setOptions({
      center: MainMapService.currentCenter || {lat: 41.8933203, lng: 12.4829321},
      zoom: MainMapService.currentZoom || 6,
      fullscreenControl: false,
      zoomControl: !MainMapService.isMobile,
      streetViewControl: false,
      mapTypeControl: false,
      clickableIcons: false,
      mapTypeId: MainMapService.currentMapType || GoogleMapsService.google.maps.MapTypeId.ROADMAP
    });
    if(MainMapService.currentInfotraffic){
      this.googleMaps.enableInfoTraffic();
    }else{
      this.googleMaps.disableInfoTraffic();
    }
    this.setMapTypeControls();
  }

  // Visualizza il marker di selezione sulla mappa (tasto dx o ricerca indirizzo)
  private drawSelectMarkerGoogle(lat: number, lng: number){
    console.log(lat, lng);
    this.selectedMarker = new GoogleMapsService.google.maps.Marker({
      zIndex: 10000,
      position: {lat: lat, lng: lng},
      id: 'selected-marker'
    });
    this.onSelectMarker.next({type: 'selected-marker', id: this.selectedMarker.id, position: {lat: lat, lng:lng}});
    this.infoWindow.open(this.map, this.selectedMarker);
    this.selectedMarker.addListener("spider_click", () => {
      this.onSelectMarker.next({type: 'selected-marker', id: this.selectedMarker.id, position: {lat: lat, lng:lng}});
      this.infoWindow.open(this.map, this.selectedMarker);
    });
    this.googleMaps.addMarker(this.selectedMarker, this.MAP_NAME, false);
  }

  // Inserisce i marker dei partecipanti localizzati
  private drawParticipantMarkersGoogle(participants: Partecipant[]){
    this.participantMarkers = [];
    this.popupMarkers = [];
    for(let i=0; i<participants.length; i++){
      this.drawSingleParticipantMarkerGoogle(participants[i], i);
    }
  }

  // Metodo ausiliario per la creazione di un marker di un participant
  private drawSingleParticipantMarkerGoogle(participant: Partecipant, index: number){
    let icon: any = {
      url: MainMapService.iconDisabled,
      scaledSize: new GoogleMapsService.google.maps.Size(MARKER_WITH_DIRECTION[this.googleMaps.marker_opts.marker_size].size.x, MARKER_WITH_DIRECTION[this.googleMaps.marker_opts.marker_size].size.y), // Dimensioni desiderate del marker
      size: new GoogleMapsService.google.maps.Size(MARKER_WITH_DIRECTION[this.googleMaps.marker_opts.marker_size].size.x, MARKER_WITH_DIRECTION[this.googleMaps.marker_opts.marker_size].size.y), // Dimensioni desiderate del marker        origin: new GoogleMapsService.google.maps.Point(0, 0),
      anchor: new GoogleMapsService.google.maps.Point(MARKER_WITH_DIRECTION[this.googleMaps.marker_opts.marker_size].anchor.x, MARKER_WITH_DIRECTION[this.googleMaps.marker_opts.marker_size].anchor.y),
      origin: new GoogleMapsService.google.maps.Point(0, 0),
      // labelOrigin: new GoogleMapsService.google.maps.Point(23, 24)
    };
    if(participant.virtualPosition && participant.virtualPosition.latitude && participant.virtualPosition.longitude){
      const coord: any = {
        lat: participant.virtualPosition.latitude,
        lng: participant.virtualPosition.longitude
      };
      let label: any = {
        color: 'white',
        fontSize: `${MARKER_WITH_DIRECTION[this.googleMaps.marker_opts.marker_size].size.font}px`,
      };
      // Scelgo l'icona in base all'heading
      const urlIcon: any = Utility.selectMarkerIcons(MainMapService.iconsGreen, MainMapService.iconsRed, participant.virtualPosition.tripStatus, participant.virtualPosition.angle, participant.getIn1State());
      if(urlIcon) icon.url = urlIcon;
      if(participant.label_number){
        label.text = participant.label_number + '';
      }else{
        label = undefined;
      }
      let marker: any = new GoogleMapsService.google.maps.Marker({
        zIndex: index,
        position: coord,
        label: label,
        icon: icon,
        id: participant._id
      });
      marker.customLabel = label;
      let popup: any = this.createPopupGoogle(marker.getPosition(), participant.name || participant.imei, participant._id);
      this.googleMaps.addPopup(popup, this.MAP_NAME);
      marker.addListener("spider_click", () => {
        this.onSelectMarker.next({type: 'participant', id: marker.id});
        if(!MainMapService.isMobile) this.infoWindow.open(this.map, marker);
      });
      this.participantMarkers.push(marker);
      this.popupMarkers.push(popup);
      this.googleMaps.addMarker(marker, this.MAP_NAME, true);
    }
  }

  // Rimuove o visualizza un marker dalla mappa
  private toggleVisibilityParticipantGoogle(participant: Partecipant, showPopup: boolean){
    let p: any = this.popupMarkers.find((elem: any) => {
      return elem.id === participant._id;
    });
    if(this.infoWindow) this.infoWindow.close();
    for(let marker of this.participantMarkers){
      if(marker.id === participant._id){
        if(participant.hideOnMap){
          if(marker.getVisible()){
            marker.setVisible(false);
            if(p) p.setMap(null);
          }
        }else{
          if(!marker.getVisible()){
            marker.setVisible(true);
            if(showPopup){
              if(p){
                p.setMap(this.map);
              }
            }
          }
        }
        break;
      }
    }
  }

  // Inserisce i marker dei POI
  private drawPoiMarkersGoogle(pois: Poi[]){
    this.poiMarkers = [];
    for(let i=0; i<pois.length; i++){
      this.drawSinglePoiMarkerGoogle(pois[i], i);
    }
  }

  // Metodo ausiliario per la creazione di un marker poi
  private drawSinglePoiMarkerGoogle(poi: Poi, index: number){
    let icon: any = {
      url: MainMapService.iconPoi,
      scaledSize: new GoogleMapsService.google.maps.Size(MARKER_WITH_DIRECTION[this.googleMaps.marker_opts.marker_size].size.x, MARKER_WITH_DIRECTION[this.googleMaps.marker_opts.marker_size].size.y), // Dimensioni desiderate del marker
      size: new GoogleMapsService.google.maps.Size(MARKER_WITH_DIRECTION[this.googleMaps.marker_opts.marker_size].size.x, MARKER_WITH_DIRECTION[this.googleMaps.marker_opts.marker_size].size.y), // Dimensioni desiderate del marker        origin: new GoogleMapsService.google.maps.Point(0, 0),
      anchor: new GoogleMapsService.google.maps.Point(MARKER_WITH_DIRECTION[this.googleMaps.marker_opts.marker_size].anchor.x, MARKER_WITH_DIRECTION[this.googleMaps.marker_opts.marker_size].anchor.y),
      origin: new GoogleMapsService.google.maps.Point(0, 0),
    };
    if(poi.position && poi.position.coordinates && poi.position.coordinates.length === 2){
      const coord: any = {
        lat: poi.position.coordinates[1],
        lng: poi.position.coordinates[0]
      };
      let label: any = {
        color: 'white',
        fontSize: `${MARKER_WITH_DIRECTION[this.googleMaps.marker_opts.marker_size].size.font}px`,
      };
      if(poi.label_number){
        label.text = poi.label_number + '';
      }else{
        label = undefined;
      }
      let marker: any = new GoogleMapsService.google.maps.Marker({
        zIndex: index,
        position: coord,
        label: label,
        icon: icon,
        id: poi._id
      });
      marker.customLabel = label;
      marker.addListener("spider_click", () => {
        this.onSelectMarker.next({type: 'poi', id: marker.id});
        if(!MainMapService.isMobile) this.infoWindow.open(this.map, marker);
      });
      this.poiMarkers.push(marker);
      this.googleMaps.addMarker(marker, this.MAP_NAME, true);
    }
  }

  // Rimuove o visualizza un marker di un poi dalla mappa
  private toggleVisibilityPoiGoogle(poi: Poi){
    if(this.infoWindow) this.infoWindow.close();
    for(let marker of this.poiMarkers){
      if(marker.id === poi._id){
        if(poi.hideOnMap){
          if(marker.getMap()){
            this.googleMaps.removeMarker(marker, this.MAP_NAME);
          }
        }else{
          if(!marker.getMap()){
            this.googleMaps.addMarker(marker, this.MAP_NAME, true);
          }
        }
        return;
      }
    }
  }

  // Crea il label sopra al marker, devo mettere anche la classe all'interno del metodo altrimenti non si riesce a fare
  private createPopupGoogle(position: any, label: string, id: string): any{
    const font_size = MARKER_WITH_DIRECTION[this.googleMaps.marker_opts.label_size].size.font
    const fattore_correzione_label = MARKER_WITH_DIRECTION[this.googleMaps.marker_opts.marker_size].label.fattore_correzione
    class Popup extends GoogleMapsService.google.maps.OverlayView {
      position: any;
      containerDiv: HTMLDivElement;
      id: string;

      constructor(position: any, content: HTMLElement, id: string) {
        super();
        this.position = position;
        this.id = id;

        content.classList.add("map-poi-popup-bubble");

        // This zero-height div is positioned at the bottom of the bubble.
        const bubbleAnchor = document.createElement("div");
        bubbleAnchor.classList.add("map-poi-popup-bubble-anchor");
        bubbleAnchor.appendChild(content);

        // This zero-height div is positioned at the bottom of the tip.
        this.containerDiv = document.createElement("div");
        this.containerDiv.classList.add("map-poi-popup-container");
        this.containerDiv.appendChild(bubbleAnchor);

        // Optionally stop clicks, etc., from bubbling up to the map.
        Popup.preventMapHitsAndGesturesFrom(this.containerDiv);
      }

      /** Called when the popup is added to the map. */
      onAdd() {
        this.getPanes().floatPane.appendChild(this.containerDiv);
      }

      /** Called when the popup is removed from the map. */
      onRemove() {
        if (this.containerDiv.parentElement) {
          this.containerDiv.parentElement.removeChild(this.containerDiv);
        }
      }

      /** Called each frame when the popup needs to draw itself. */
      draw() {
        const divPosition = this.getProjection().fromLatLngToDivPixel(
          this.position
        );

        //Hide the popup when it is far out of view.
        const display =
          Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
            ? "block"
            : "none";
        // const display = 'block';

        if (display === "block") {
          const top = divPosition.y + fattore_correzione_label
          this.containerDiv.style.left = divPosition.x + "px";
          this.containerDiv.style.top =  top + "px";
        }

        this.containerDiv.style.fontSize = `${font_size}px`

        if (this.containerDiv.style.display !== display) {
          this.containerDiv.style.display = display;
        }
      }
    }

    let element: HTMLElement = document.createElement('div');
    element.innerText = label;
    return new Popup(
      position,
      element,
      id
    );
  }

  private changeZoomGoogle(delta: number){
    let zoom: number = this.map.getZoom();
    this.map.setZoom(zoom + delta);
  }

  private resizeMapGoogle(){
    let bounds: any = new GoogleMapsService.google.maps.LatLngBounds();
    for (let i = 0; i < this.participantMarkers.length; i++) {
      if(this.participantMarkers[i].getVisible()){
        bounds.extend(this.participantMarkers[i].getPosition());
      }
    }
    for (let i = 0; i < this.poiMarkers.length; i++) {
      if(this.poiMarkers[i].getMap()){
        bounds.extend(this.poiMarkers[i].getPosition());
      }
    }
    if(!bounds.isEmpty()){
      this.map.fitBounds(bounds, 50);
      setTimeout(() => {
        if(this.map.getZoom() > 16){
          this.map.setZoom(16);
        }
      }, 200)
    }
  }

  private setCenterGoogle(lat: number, lng: number){
    this.map.setCenter({lat: lat, lng: lng});
  }

  private setMapTypeGoogle(type: string){
    this.map.setMapTypeId(type);
  }

  private getMapTypeGoogle(){
    return this.map.getMapTypeId();
  }

  private selectParticipantGoogle(id: string){
    for(let marker of this.participantMarkers){
      if(marker.id === id){
        let position: any = marker.getPosition();
        this.setCenter(position.lat(), position.lng());
        this.onSelectMarker.next({type: 'participant', id: marker.id});
        this.infoWindow.open(this.map, marker);
        return;
      }
    }
  }

  private selectPoiGoogle(id: string){
    for(let marker of this.poiMarkers){
      if(marker.id === id){
        let position: any = marker.getPosition();
        this.setCenter(position.lat(), position.lng());
        this.onSelectMarker.next({type: 'poi', id: marker.id});
        this.infoWindow.open(this.map, marker);
        return;
      }
    }
  }

  private drawCirclePoiGoogle(poi: Poi){
    this.removeCirclePoi();
    for(let marker of this.poiMarkers){
      if(marker.id === poi._id){
        this.circle = new GoogleMapsService.google.maps.Circle({
          center: marker.getPosition(),
          radius: poi.radius,
          strokeColor: "#2e97f2",
          strokeWeight: 2,
          fillColor: "#2e97f2",
          fillOpacity: 0.15,
          id: 'circle'
        });
        this.googleMaps.addCircle(this.circle, this.MAP_NAME);
        return;
      }
    }
  }

  private removeCirclePoiGoogle(){
    if(this.circle) this.googleMaps.removeCircle(this.circle, this.MAP_NAME);
    this.circle = undefined;
  }

  /*private toggleVisibilityPoiMarkerGoogle(visible: boolean){
    for(let marker of this.poiMarkers){
      if(visible){
        marker.setMap(this.map);
      }else{
        marker.setMap(null);
      }
    }
  }*/

  private toggleVisibilityLabelGoogle(visible: boolean){
    for(let marker of this.participantMarkers){
      marker.setLabel(visible ? marker.customLabel : undefined);
    }
    for(let marker of this.poiMarkers){
      marker.setLabel(visible ? marker.customLabel : undefined);
    }
  }

  private toggleVisibilityPopupGoogle(visible: boolean){
    for(let popup of this.popupMarkers){
      if(visible){
        let relMarker: any = this.participantMarkers.find((elem: any) => {
          return elem.id === popup.id;
        });
        if(relMarker) {
          if (relMarker.getVisible()) popup.setMap(this.map);
        }
      }else{
        popup.setMap(null);
      }
    }
  }

  private updateParticipantMarkerGoogle(participant: Partecipant, resizePopup?: boolean){

    let marker: any = this.participantMarkers.find((elem: any) => {
      return elem.id === participant._id;
    });
    if(marker){ // modifico il marker esistente
      // Aggiorno posizione
      marker.setPosition({lat: participant.virtualPosition.latitude, lng: participant.virtualPosition.longitude});
      // Aggiorno la posizione del popup
      for(let popup of this.popupMarkers){
        if(popup.id === participant._id){
          popup.position = marker.getPosition();
          break;
        }
      }
      // Aggiorno marker
      let icon: any = {
        url: MainMapService.iconDisabled,
        scaledSize: new GoogleMapsService.google.maps.Size(MARKER_WITH_DIRECTION[this.googleMaps.marker_opts.marker_size].size.x, MARKER_WITH_DIRECTION[this.googleMaps.marker_opts.marker_size].size.y), // Dimensioni desiderate del marker
        size: new GoogleMapsService.google.maps.Size(MARKER_WITH_DIRECTION[this.googleMaps.marker_opts.marker_size].size.x, MARKER_WITH_DIRECTION[this.googleMaps.marker_opts.marker_size].size.y), // Dimensioni desiderate del marker        origin: new GoogleMapsService.google.maps.Point(0, 0),
        anchor: new GoogleMapsService.google.maps.Point(MARKER_WITH_DIRECTION[this.googleMaps.marker_opts.marker_size].anchor.x, MARKER_WITH_DIRECTION[this.googleMaps.marker_opts.marker_size].anchor.y),
        // labelOrigin: new GoogleMapsService.google.maps.Point(23, 24)
      };
      const urlIcon: any = Utility.selectMarkerIcons(MainMapService.iconsGreen, MainMapService.iconsRed, participant.virtualPosition.tripStatus, participant.virtualPosition.angle, participant.getIn1State());
      if(urlIcon) icon.url = urlIcon;
      marker.setIcon(icon);
      if(participant.label_number || participant.label_number === 0){
        marker.customLabel = {
          color: 'white',
          fontSize: `${MARKER_WITH_DIRECTION[this.googleMaps.marker_opts.marker_size].size.font}px`,
          text: participant.label_number + ''
        };
      }else{
        marker.customLabel = undefined;
      }
      if(resizePopup){
        //devo controllare se c'è gia il popup per il participant

        for(let i=0; i<this.popupMarkers.length; i++){
          if(this.popupMarkers[i].id === participant._id){
            //rimuovo il popup dalla mappa e lo inserisco aggiornato
            this.googleMaps.removePopup(this.popupMarkers[i], this.MAP_NAME)
            this.popupMarkers.splice(i, 1)
          }
        }

        let popup: any = this.createPopupGoogle(marker.getPosition(), participant.name || participant.imei, participant._id);
        this.googleMaps.addPopup(popup, this.MAP_NAME);
        this.popupMarkers.push(popup)

      }
      if(MainMapService.labelVisible) marker.setLabel(marker.customLabel);
    }else if(participant.isInFleet()){ // devo aggiungere il marker perchè è nuovo solo se è in flotta
      this.drawSingleParticipantMarkerGoogle(participant, this.participantMarkers.length);
    }
  }

  private removeParticipantMarkerGoogle(id: string){
    for(let i=0; i<this.participantMarkers.length; i++){
      if(this.participantMarkers[i].id === id){
        this.googleMaps.removeMarker(this.participantMarkers[i], this.MAP_NAME);
        for(let j=0; j<this.popupMarkers.length; j++){
          if(this.popupMarkers[i].id === id){
            this.googleMaps.removePopup(this.popupMarkers[i], this.MAP_NAME);
            this.popupMarkers.splice(j, 1);
            break;
          }
        }
        this.participantMarkers.splice(i, 1);
        return;
      }
    }
  }

  private updatePoiMarkerGoogle(poi: Poi){
    let marker: any = this.poiMarkers.find((elem: any) => {
      return elem.id === poi._id;
    });
    if(marker){ // Aggiorno il marker del poi
      // Aggiorno posizione
      marker.setPosition({lat: poi.latitude, lng: poi.longitude});

      let icon: any = {
        url: MainMapService.iconPoi,
        scaledSize: new GoogleMapsService.google.maps.Size(MARKER_WITH_DIRECTION[this.googleMaps.marker_opts.marker_size].size.x, MARKER_WITH_DIRECTION[this.googleMaps.marker_opts.marker_size].size.y), // Dimensioni desiderate del marker
        size: new GoogleMapsService.google.maps.Size(MARKER_WITH_DIRECTION[this.googleMaps.marker_opts.marker_size].size.x, MARKER_WITH_DIRECTION[this.googleMaps.marker_opts.marker_size].size.y), // Dimensioni desiderate del marker        origin: new GoogleMapsService.google.maps.Point(0, 0),
        anchor: new GoogleMapsService.google.maps.Point(MARKER_WITH_DIRECTION[this.googleMaps.marker_opts.marker_size].anchor.x, MARKER_WITH_DIRECTION[this.googleMaps.marker_opts.marker_size].anchor.y),
      };

      marker.setIcon(icon);
      if(poi.label_number || poi.label_number === 0){
        marker.customLabel = {
          color: 'white',
          fontSize: `${MARKER_WITH_DIRECTION[this.googleMaps.marker_opts.marker_size].size.font}px`,
          text: poi.label_number + ''
        };
      }else{
        marker.customLabel = undefined;
      }
      if(MainMapService.labelVisible) marker.setLabel(marker.customLabel);
    }else{ // Aggiungo un nuovo marker del poi
      this.drawSinglePoiMarkerGoogle(poi, this.poiMarkers.length);
    }
  }

  private removePoiMarkerGoogle(id: string){
    for(let i=0; i<this.poiMarkers.length; i++){
      if(this.poiMarkers[i].id === id){
        this.googleMaps.removeMarker(this.poiMarkers[i], this.MAP_NAME);
        this.poiMarkers.splice(i, 1);
        return;
      }
    }
  }

  // Setta il menù con le icone per cambio tipo mappa e infotraffico
  private async setMapTypeControls(){
    // console.log('main-map service setMapTypeControls()');

    const size: string = MainMapService.isMobile ? '54px' : '75px';
    if(!this.buttonMapType_1){
      this.buttonMapType_1 = document.createElement('div');
      let controlUIMap = document.createElement('div');
      controlUIMap.style.height = size;
      controlUIMap.style.width = size;
      controlUIMap.style.background = "linear-gradient(0deg,rgba(0,0,0,0.3),rgba(0,0,0,0.3)),url(" + MainMapService.satelliteMapImage + ")";
      controlUIMap.style.backgroundSize = size + " " + size;
      controlUIMap.style.border = '2px solid #fff';
      controlUIMap.style.borderRadius = '5px';
      controlUIMap.style.cursor = 'pointer';
      controlUIMap.style.marginRight = MainMapService.isMobile ? '8px' : '10px';
      this.buttonMapType_1.style.marginBottom = "5px";
      this.buttonMapType_1.appendChild(controlUIMap);
      let controlTexMap = document.createElement('div');
      controlTexMap.style.color = "#fff";
      controlTexMap.style.fontFamily = 'Roboto,Arial,sans-serif';
      controlTexMap.style.fontSize = MainMapService.isMobile ? '8px' : '10px';
      controlTexMap.style.lineHeight = '38px';
      controlTexMap.style.paddingLeft = '5px';
      controlTexMap.style.paddingRight = '5px';
      controlTexMap.style.marginTop = MainMapService.isMobile ? '20px' : '40px';
      controlTexMap.style.textOverflow = 'ellipsis';
      controlTexMap.style.overflow = 'hidden';
      controlTexMap.style.whiteSpace = 'nowrap';
      controlTexMap.innerHTML = MainMapService.satelliteMapLabel;
      this.buttonMapType_1.addEventListener('click', () => {
        let mapTypeId: string = this.map.getMapTypeId();
        if (mapTypeId == GoogleMapsService.google.maps.MapTypeId.HYBRID) {
          controlUIMap.style.background = "linear-gradient(0deg,rgba(0,0,0,0.3),rgba(0,0,0,0.3)),url(" + MainMapService.satelliteMapImage + ")";
          controlUIMap.style.backgroundSize = size + " " + size;
          controlTexMap.innerHTML = MainMapService.satelliteMapLabel;
          MainMapService.currentMapType = GoogleMapsService.google.maps.MapTypeId.ROADMAP;
        } else {
          controlUIMap.style.background = "linear-gradient(0deg,rgba(0,0,0,0.3),rgba(0,0,0,0.3)),url(" + MainMapService.roadMapImage + ")";
          controlUIMap.style.backgroundSize = size + " " + size;
          controlTexMap.innerHTML = MainMapService.roadMapLabel;
          MainMapService.currentMapType = GoogleMapsService.google.maps.MapTypeId.HYBRID;
        }
        this.map.setMapTypeId(MainMapService.currentMapType);
      });
      controlUIMap.appendChild(controlTexMap);
    }
    if(!this.buttonMapType_2){
      this.buttonMapType_2 = document.createElement('div');
      let controlUITraffic = document.createElement('div');
      controlUITraffic.style.height = size;
      controlUITraffic.style.width = size;
      controlUITraffic.style.background = "linear-gradient(0deg,rgba(0,0,0,0.3),rgba(0,0,0,0.3)),url(" + MainMapService.infotrafficImage + ")";
      controlUITraffic.style.backgroundSize = size + " " + size;
      controlUITraffic.style.border = '2px solid #fff';
      controlUITraffic.style.borderRadius = '5px';
      controlUITraffic.style.cursor = 'pointer';
      controlUITraffic.style.marginRight = MainMapService.isMobile ? '8px' : '10px';
      controlUITraffic.style.marginBottom = "5px";
      this.buttonMapType_2.appendChild(controlUITraffic);
      let controlTextTraffic = document.createElement('div');
      controlTextTraffic.style.color = "#fff";
      controlTextTraffic.style.fontFamily = 'Roboto,Arial,sans-serif';
      controlTextTraffic.style.fontSize = MainMapService.isMobile ? '8px' : '10px';
      controlTextTraffic.style.lineHeight = '38px';
      controlTextTraffic.style.paddingLeft = '5px';
      controlTextTraffic.style.paddingRight = '5px';
      controlTextTraffic.style.textOverflow = 'ellipsis';
      controlTextTraffic.style.overflow = 'hidden';
      controlTextTraffic.style.whiteSpace = 'nowrap';
      controlTextTraffic.style.marginTop = MainMapService.isMobile ? '20px' : '40px';
      controlTextTraffic.innerHTML = MainMapService.infotrafficLabel;
      this.buttonMapType_2.addEventListener('click', () => {
        if(MainMapService.currentInfotraffic){
          MainMapService.currentInfotraffic = false;
          this.googleMaps.disableInfoTraffic();
          controlUITraffic.style.borderColor = "#fff";
        }else{
          MainMapService.currentInfotraffic = true;
          this.googleMaps.enableInfoTraffic();
          controlUITraffic.style.borderColor = "#32aadf";
        }
      });
      controlUITraffic.appendChild(controlTextTraffic);
    }
    this.map.controls[GoogleMapsService.google.maps.ControlPosition.RIGHT_BOTTOM].push(this.buttonMapType_1);
    this.map.controls[GoogleMapsService.google.maps.ControlPosition.RIGHT_BOTTOM].push(this.buttonMapType_2);

  }

  private closeInfoWindowGoogle(){
    if(this.infoWindow) this.infoWindow.close();
    if(this.circle) this.googleMaps.removeCircle(this.circle, this.MAP_NAME);
    if(this.selectedMarker) this.googleMaps.removeMarker(this.selectedMarker, this.MAP_NAME);
  }

}
