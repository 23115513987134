import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component, Input,
  OnInit
} from '@angular/core';
import {
  ModalController,
  NavController
} from "@ionic/angular";
import {TranslateService} from "@ngx-translate/core";
import {ViewService} from "../../../../services/common/view.service";
import {DataService} from "../../../../services/common/data.service";
import {ApiService} from "../../../../services/common/api.service";

type PageTypes = 'delinquent' | 'hint';

@Component({
  selector: 'app-payment-problems',
  templateUrl: './payment-problems.page.html',
  styleUrls: ['./payment-problems.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentProblemsPage implements OnInit {

  @Input() type: PageTypes;
  @Input() dismissible: boolean;

  lang: any;
  closeAction: any;

  listener = {
    view: null
  };

  constructor(
    private cd: ChangeDetectorRef,
    private modalCtrl: ModalController,
    private view: ViewService,
    private translate: TranslateService,
    private navCtrl: NavController,
    public data: DataService,
    private api: ApiService
  ) { }

  ngOnInit() {
    this.closeAction = () => {this.dismiss()};
  }

  ionViewDidEnter() {
    this.listenerView();
    this.translate.get('PAYMENT_PROBLEMS').subscribe((lang: any) => {
      this.lang = lang;
      ViewService.updateView.next();
    });
  }

  ionViewWillLeave() {
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
  }

  dismiss(data?: any) {
    this.modalCtrl.dismiss(data);
  }

  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.cd.markForCheck();
    });
  }

  openTickets(){
    this.navCtrl.navigateRoot('tickets');
    this.dismiss();
  }

  async openPaymentPortal(){
    this.navCtrl.navigateRoot('payment');
    this.dismiss();
  }


}
