import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Input,
  Output, EventEmitter
} from '@angular/core';
import {
  PositionGps
} from "../../../classes/common/position-gps";
import {
  ViewService
} from "../../../services/common/view.service";
import {Partecipant} from "src/app/classes/common/partecipant";


// type Layout =
//   "chip-lbl" |
//   "item";


@Component({
  selector: 'app-map-popup',
  templateUrl: './map-popup.component.html',
  styleUrls: ['./map-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapPopupComponent implements OnInit {

  @Input() position: PositionGps;
  @Input() type: string;
  @Input() id: string;
  @Input() isIngress: boolean = false;
  @Input() isStart: boolean = false;
  @Input() participant: Partecipant;


  @Output() close = new EventEmitter<any>();

  // listener

  listener = {
    view: null,
  };



  constructor(
    private cd: ChangeDetectorRef,
  ) {}


  ngOnInit() {
    //  listeners:

    this.listenerView();
    if(!this.id) this.id = 'history-infowindow';
    //  -------------------------
  }

  ngOnDestroy() {
    // disengage listeners:

    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }

  }

  closeAction(){
    this.close.emit();
  }





  // listener

  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      this.cd.markForCheck();
      setTimeout(() => {
        this.cd.markForCheck();
      }, 250);
    });
  }








}
