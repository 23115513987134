import {
  Injectable, Pipe,
  PipeTransform
} from '@angular/core';
import {Poi} from "../../classes/common/poi";

@Pipe({
  name: 'poiList'
})
@Injectable()
export class PoiListPipe implements PipeTransform {
  transform(pois: Poi[], limit: number, othersLabel?: string,
    noElementsLabel?: string, changed?: number, type?: string,
    // functions?: any[]
  ): string {

    let res: string;

    switch (type) {
      case "reportMobile":
        res = ``;
        if(!pois || !limit || pois.length <= 0) return noElementsLabel || "";
        for(let i=0; i<pois.length; i++){
          let poi: string = pois[i].name;
          if(i < limit){
            res +=
              `<div class="ovp-li ovp-li--poi">
                ${poi}
              </div>`
          }else{
            if(othersLabel){
              res +=
                `<a class="ovp-li">
                  ${othersLabel}
                </a>`
            }else{
              res +=
                `<a class="ovp-li">
                  ...
                </a>`
            }
            break;
          }
        }
        break;

      default:
        res = ``;
        if(!pois || !limit || pois.length <= 0) return noElementsLabel || "";
        for(let i=0; i<pois.length; i++){
          let poi: string = pois[i].name;
          if(i < limit){
            res += poi + `<br>`;
          }else{
            if(othersLabel){
              res += `<a>` + othersLabel + `</a>`;
            }else{
              res += `<a>...</a>`;
            }
            break;
          }
        }
    }


    return res;
  }
}
