
export class Fleet{


  _id: string
  name: string
  description: string;
  participants: string[] = []
  timestamp_created: string;
  constructor(data: any){
    if(data){
      this.setData(data)
    }
  }

  setData(data){
    this._id = data._id;
    this.name = data.name;
    this.description = data.description;
    this.participants = data.participants;
    this.timestamp_created = data.timestamp_created;
  }
}
