
export class Subscription {

  id: string;
  cancel_at: number;
  cancel_at_period_end: number;
  collection_method: string;
  created: number;
  currency: string;
  current_period_end: number;
  current_period_start: number;
  latest_invoice: string;
  payment_settings: string[];
  quantity: number;
  start_date: number;
  status: string;
  trial_end: number;
  trial_start: number;
  pause_collection: any[]

  constructor(data?: any,) {
    if (data) {
      this.setData(data);
    }
  }

  setData(data) {
    this.id = data.id;
    this.cancel_at = data.cancel_at
    this.cancel_at_period_end = data.cancel_at_period_end
    this.collection_method = data.collection_method
    this.created = data.created
    this.currency = data.currency
    this.current_period_end = data.current_period_end
    this.current_period_start = data.current_period_start
    this.latest_invoice = data.latest_invoice
    this.payment_settings = data.payment_settings
    this.quantity = data.quantity
    this.start_date = data.start_date
    this.trial_end = data.trial_end
    this.trial_start = data.trial_start
    this.status = data.status
    this.pause_collection = data.pause_collection
  }


}
