export const firebaseConfig = {
  apiKey: "AIzaSyBjE-8j-0Ct-XZytEKdAHjxyFCl9uMo2SA",
  authDomain: "white-label-prod.firebaseapp.com",
  databaseURL: "https://white-label-prod-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "white-label-prod",
  storageBucket: "white-label-prod.appspot.com",
  messagingSenderId: "336855160915",
  appId: "1:336855160915:web:65aa5ebe477f89ab274ea6",
  measurementId: "G-GCP6NC3J4Q"
}
