import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort',
})
export class SortPipe implements PipeTransform {
  transform(array: any[], field: string, isDescending: boolean, changed?: number, copy?: boolean): any[] {
    // console.log(field);
    if(!array || array.length <= 0 || !field) return array;
    if(copy)array = [...array]
    return array.sort((a, b) => {
      let x, y, compareRes;

      if (a[field] !== 0 && !a[field]) {
        a[field] = "";
      }
      if (b[field] !== 0 && !b[field]) {
        b[field] = "";
      }

      x = ("" + a[field]).toLowerCase();

      if(typeof a[field] === "number"){

        x = a[field];
      }
      y = ("" + b[field]).toLowerCase();
      if(typeof b[field] === "number"){
        y = b[field];
      }

      if (x < y) { compareRes = -1; }
      if (x > y) { compareRes = 1; }

      if (isDescending) { compareRes = -compareRes; }

      if (!compareRes) { compareRes = 0; }

      return compareRes;
    });
  }
}
