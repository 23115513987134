import {
  Injectable, Pipe,
  PipeTransform
} from '@angular/core';

type Format = 
  | 'html d m s || m s' 
  | 'd m s || m s' 
  | 'html d h m s' 
  | 'd h m s' 
  | 'html d h m || s' 
  | 'd h m || s'
;

@Pipe({
  name: 'deltaTime'
})
@Injectable()
export class DeltaTimePipe implements PipeTransform {
  transform(ms: number, format: Format, from_now: boolean, lang?: any, css_class?: string, changed?: number): string {
    if(from_now) ms = Date.now() - ms;
    const seconds = parseInt((ms / 1000).toString());
    if(!lang) {
      lang = {};
    }
    const default_lang: any = {
      label_seconds: 's',
      label_min: 'min',
      label_hours: 'h',
      label_day: 'd',
    };

    let s: number = seconds;
    let m: number = parseInt("" + (seconds/60));
    s = s - (m * 60);
    let h: number = parseInt("" + (m/60));
    m = m - (h * 60);
    let g: number = parseInt("" + (h / 24));
    h = h - (g * 24);

    let result = "";

    switch (format) {

      case 'html d m s || m s':
        if(g > 0 || h > 0){
          if(m > 0){
            result = `${m}<label class="${css_class}">${(lang.label_min || default_lang.label_min)}</label>${result}`;
          }
          if(h > 0){
            result = `${h}<label class="${css_class}">${(lang.label_hours || default_lang.label_hours)}</label>${result}`;
          }
          if(g > 0){
            result = `${g}<label class="${css_class}">${(lang.label_day || default_lang.label_day)}</label>${result}`;
          }
        }else{
          if(s > 0){
            result = `${s}<label class="${css_class}">${(lang.label_seconds || default_lang.label_seconds)}</label>${result}`;
          }
          if(m > 0){
            result = `${m}<label class="${css_class}">${(lang.label_min || default_lang.label_min)}</label>${result}`;
          }
        }
        break;

      case 'd m s || m s':
        if(g > 0 || h > 0){
          if(m > 0){
            result = `${m} ${(lang.label_min || default_lang.label_min)} ${result}`;
          }
          if(h > 0){
            result = `${h} ${(lang.label_hours || default_lang.label_hours)} ${result}`;
          }
          if(g > 0){
            result = `${g} ${(lang.label_day || default_lang.label_day)} ${result}`;
          }
        }else{
          if(s > 0){
            result = `${s} ${(lang.label_seconds || default_lang.label_seconds)} ${result}`;
          }
          if(m > 0){
            result = `${m} ${(lang.label_min || default_lang.label_min)} ${result}`;
          }
        }
        break;

      case 'html d h m s':
        if(s > 0){
          result = `${s}<label class="${css_class}">${(lang.label_seconds || default_lang.label_seconds)}</label>${result}`;
        }
        if(m > 0 || h > 0){
          result = `${m}<label class="${css_class}">${(lang.label_min || default_lang.label_min)}</label>${result}`;
        }
        if(h > 0 || g > 0){
          result = `${h}<label class="${css_class}">${(lang.label_hours || default_lang.label_hours)}</label>${result}`;
        }
        if(g > 0){
          result = `${g}<label class="${css_class}">${(lang.label_day || default_lang.label_day)}</label>${result}`;
        }
        break;

      case 'd h m s':
        if(s > 0){
          result = `${s} ${(lang.label_seconds || default_lang.label_seconds)} ${result}`;
        }
        if(m > 0 || h > 0){
          result = `${m} ${(lang.label_min || default_lang.label_min)} ${result}`;
        }
        if(h > 0 || g > 0){
          result = `${h} ${(lang.label_hours || default_lang.label_hours)} ${result}`;
        }
        if(g > 0){
          result = `${g} ${(lang.label_day || default_lang.label_day)} ${result}`;
        }
        break;

      case 'html d h m || s':
        if(m > 0){
          result = `${m}<label class="${css_class}">${(lang.label_min || default_lang.label_min)}</label>${result}`;
        }
        if(h > 0){
          result = `${h}<label class="${css_class}">${(lang.label_hours || default_lang.label_hours)}</label>${result}`;
        }
        if(g > 0){
          result = `${g}<label class="${css_class}">${(lang.label_day || default_lang.label_day)}</label>${result}`;
        }
        if(!result){
          result = `${s}<label class="${css_class}">${(lang.label_seconds || default_lang.label_seconds)}</label>${result}`;
        }
        break;

      case 'd h m || s':
        if(m > 0){
          result = `${m} ${(lang.label_min || default_lang.label_min)} ${result}`;
        }
        if(h > 0){
          result = `${h} ${(lang.label_hours || default_lang.label_hours)} ${result}`;
        }
        if(g > 0){
          result = `${g} ${(lang.label_day || default_lang.label_day)} ${result}`;
        }
        if(!result){
          result = `${s} ${(lang.label_seconds || default_lang.label_seconds)} ${result}`;
        }
        break;
    }

    return result;
  }
}
