import {
  Component, EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

@Component({
  selector: 'app-diagnostic-panel',
  templateUrl: './diagnostic-panel.component.html',
  styleUrls: ['./diagnostic-panel.component.scss'],
})
export class DiagnosticPanelComponent implements OnInit {


  @Input() type: 'success' | 'error' | 'warn' | 'loading';
  @Input() icon: string;
  @Input() title: string;
  @Input() main_label: string;
  @Input() row1: string;
  @Input() row2: string;
  @Input() sub_label: string;
  @Input() show_info: boolean;

  @Output() select = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {}

}
