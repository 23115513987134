import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {PopoverController} from '@ionic/angular';
import {
  ViewService
} from "../../../services/common/view.service";

@Component({
  selector: 'app-popover-select',
  templateUrl: './popover-select.component.html',
  styleUrls: ['./popover-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopoverSelectComponent implements OnInit {

  // component args
  @Input() opts;
  @Input() enableSearch: boolean = false;


  searchQuery: string;
  // listener
  listener = {
    view: null,
  };

  constructor(
    private cd: ChangeDetectorRef,
    private popoverCtrl: PopoverController,
    public view: ViewService,
  ) {
  }

  ngOnInit() {
  }

  ionViewWillEnter() {
  }

  ionViewDidEnter() {
    this.listenerView();
  }

  ionViewWillLeave() {
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
  }

  ionViewDidLeave() {
  }

  async dismiss(id: string, data: any) {
    await this.popoverCtrl.dismiss({
      id: id,
      data: data ? data : ''
    })
  }


  // listener
  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.cd.markForCheck();
    });
  }


}
