
// NON PIU USATA IN BALINAPP MA SOLO IN ADMINAPP
export class Platform {

  timezones: string[] = [
    "Europe/Amsterdam",
    "Europe/Andorra",
    "Europe/Astrakhan",
    "Europe/Athens",
    "Europe/Belfast",
    "Europe/Belgrade",
    "Europe/Berlin",
    "Europe/Bratislava",
    "Europe/Brussels",
    "Europe/Bucharest",
    "Europe/Budapest",
    "Europe/Busingen",
    "Europe/Chisinau",
    "Europe/Copenhagen",
    "Europe/Dublin",
    "Europe/Gibraltar",
    "Europe/Guernsey",
    "Europe/Helsinki",
    "Europe/Isle_of_Man",
    "Europe/Istanbul",
    "Europe/Jersey",
    "Europe/Kaliningrad",
    "Europe/Kiev",
    "Europe/Kirov",
    "Europe/Lisbon",
    "Europe/Ljubljana",
    "Europe/London",
    "Europe/Luxembourg",
    "Europe/Madrid",
    "Europe/Malta",
    "Europe/Mariehamn",
    "Europe/Minsk",
    "Europe/Monaco",
    "Europe/Moscow",
    "Europe/Nicosia",
    "Europe/Oslo",
    "Europe/Paris",
    "Europe/Podgorica",
    "Europe/Prague",
    "Europe/Riga",
    "Europe/Rome",
    "Europe/Samara",
    "Europe/San_Marino",
    "Europe/Sarajevo",
    "Europe/Saratov",
    "Europe/Simferopol",
    "Europe/Skopje",
    "Europe/Sofia",
    "Europe/Stockholm",
    "Europe/Tallinn",
    "Europe/Tirane",
    "Europe/Tiraspol",
    "Europe/Ulyanovsk",
    "Europe/Uzhgorod",
    "Europe/Vaduz",
    "Europe/Vatican",
    "Europe/Vienna",
    "Europe/Vilnius",
    "Europe/Volgograd",
    "Europe/Warsaw",
    "Europe/Zagreb",
    "Europe/Zaporozhye",
    "Europe/Zurich",
  ];
  italy_province: any[] = [];
  nations: any[] = [];
  languages: any[] = [
    {code: "it", language: "Italian"},
    {code: "fr", language: "French"},
    {code: "es", language: "Spanish"}
  ];
  type: number;
  type_partecipant: TypeParticipant[];

  constructor(data?: any) {
    if(data){
      this.setData(data);
    }
  }

  setData(data: any){
    if(data.timezone && data.timezone.length > 0){
      this.timezones = data.timezone;
    }
    if(data.translate_language && data.translate_language.length > 0){
      this.languages = data.translate_language;
    }
    if(data.italy_province && data.italy_province.length > 0){
      this.italy_province = data.italy_province;
    }
    if(data.nation && data.nation.length > 0){
      this.nations = data.nation;
    }
    this.type = data.type;
    this.type_partecipant = [];
    for(let p of data.type_partecipant){
      this.type_partecipant.push(new TypeParticipant(p));
    }
  }


  getProvinceIta(provinceCode: string){
    return this.italy_province.find((elem: any) => {
      return elem.sigla === provinceCode;
    });
  }

  getNation(nationCode: string){
    return this.nations.find((elem: any) => {
      return elem.code === nationCode;
    });
  }

  getLanguage(languageCode: string){
    return this.languages.find((elem: any) => {
     return elem.code === languageCode;
    });
  }

  getTypeTracker(type: number): TypeParticipant{
    if(this.type_partecipant){
      return this.type_partecipant.find((elem: TypeParticipant) => {
        return elem.type_trackerGps === type;
      });
    }
    return null;
  }
}

export class TypeParticipant{
  
  type: number;
  type_trackerGps: number;
  // manual_url: string = 'https://teltonika-gps.com/downloads/en/fmb920/FMB920_Quick_Manual_v1.3.pdf'; // Forzato a quello di teltonika ma devo farlo aggiungere da dani
  manual_url: string = "https://balin.app/support";
  
  constructor(data?: any){
    if(data){
      this.setData(data);
    }
  }
  
  setData(data?: any){
    this.type = data.type;
    this.type_trackerGps = data.type_trackerGps;
    if(data.url_specifications){
      this.manual_url = data.url_specifications;
    }
  }
}
