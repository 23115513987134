import {NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {IonicModule} from "@ionic/angular";
import {CommonPipesModule} from "../../pipes/common/pipes.module";
import {AvatarComponent} from "./avatar/avatar.component";
import {CarComponent} from "./car/car.component";
import {InputBoolComponent} from "./input-bool/input-bool.component";
import {InputDateComponent} from "./input-date/input-date.component";
import {InputSelectComponent} from "./input-select/input-select.component";
import {ItemComponent} from "./item/item.component";
import {MapPopupCarComponent} from "./map-popup-car/map-popup-car.component";
import {MapPopupComponent} from "./map-popup/map-popup.component";
import {MapPopupPoiComponent} from "./map-popup-poi/map-popup-poi.component";
import {OutputComponent} from "./output/output.component";
import {PageTitleComponent} from "./page-title/page-title.component";
import {PoiComponent} from "./poi/poi.component";
import {SelectComponent} from "./select/select.component";
import {SelectChipComponent} from "./select-chip/select-chip.component";
import {SidebarComponent} from "./sidebar/sidebar.component";
import {PopoverMapSettingsComponent} from "./popover-map-settings/popover-map-settings.component";
import {PopoverFlagComponent} from "./popover-flag/popover-flag.component";
import {PopoverMapTypeComponent} from "./popover-map-type/popover-map-type.component";
import {SelectPoiComponent} from "./select-poi/select-poi.component";
import {PopoverSelectCarComponent} from "./popover-select-car/popover-select-car.component";
import {PopoverSelectPoiComponent} from "./popover-select-poi/popover-select-poi.component";
import {TranslateModule} from "@ngx-translate/core";
import {SelectDialCodeComponent} from "./select-dial-code/select-dial-code.component";
import {PopoverSelectComponent} from "./popover-select/popover-select.component";
import {StepsComponent} from "./steps/steps.component";
import {TopbarComponent} from "./topbar/topbar.component";
import {CommonComponentsModule} from "../common/common-components.module";
import {UserPersonalDataComponent} from "src/app/components/desktop/user-personal-data/user-personal-data.component";
import {ShippingDataComponent} from "src/app/components/desktop/shipping-data/shipping-data.component";
import {ReactiveFormsModule} from "@angular/forms";
import {PopoverSelectMultipleCarComponent} from "src/app/components/desktop/popover-select-multiple-car/popover-select-multiple-car.component";
import {
  InputDeviceComponent
} from "./input-device/input-device.component";
import {ReportSettingsPage} from "src/app/pages/desktop/common/report-settings/report-settings.page";
import {PopoverListParticipantComponent} from "src/app/components/desktop/popover-list-participant/popover-list-participant.component";

@NgModule({
  declarations: [
    // component
    AvatarComponent,
    CarComponent,
    InputBoolComponent,
    InputDateComponent,
    InputSelectComponent,
    ItemComponent,
    MapPopupCarComponent,
    MapPopupComponent,
    MapPopupPoiComponent,
    OutputComponent,
    PageTitleComponent,
    PoiComponent,
    SelectComponent,
    SelectChipComponent,
    SidebarComponent,
    StepsComponent,
    // popover
    PopoverFlagComponent,
    PopoverMapSettingsComponent,
    PopoverMapTypeComponent,
    PopoverSelectComponent,
    PopoverSelectCarComponent,
    PopoverSelectPoiComponent,
    SelectPoiComponent,
    SelectDialCodeComponent,
    TopbarComponent,
    UserPersonalDataComponent,
    ShippingDataComponent,
    PopoverSelectMultipleCarComponent,
    PopoverListParticipantComponent,
    InputDeviceComponent,
    ReportSettingsPage
  ],
  entryComponents: [

  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    CommonPipesModule,
    CommonComponentsModule,
    ReactiveFormsModule
  ],
  exports: [
    // component
    AvatarComponent,
    CarComponent,
    InputBoolComponent,
    InputDateComponent,
    InputSelectComponent,
    ItemComponent,
    MapPopupCarComponent,
    MapPopupComponent,
    MapPopupPoiComponent,
    OutputComponent,
    PageTitleComponent,
    PoiComponent,
    SelectComponent,
    SelectChipComponent,
    SidebarComponent,
    StepsComponent,
    TopbarComponent,
    UserPersonalDataComponent,
    ShippingDataComponent,
    PopoverSelectMultipleCarComponent,
    PopoverListParticipantComponent,
    InputDeviceComponent,
    ReportSettingsPage,
  ],
})

export class DesktopComponentsModule {}
