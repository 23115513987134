import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { ViewService } from "../../../services/common/view.service";
import { StripeService } from "../../../services/common/stripe.service";
import { PopoverController } from "@ionic/angular";
import { PopoverPaymentComponent } from "../popover-payment/popover-payment.component";

@Component({
  selector: 'select-payment-method',
  templateUrl: './select-payment-method.component.html',
  styleUrls: ['./select-payment-method.component.scss'],
})
export class SelectPaymentMethodComponent implements OnInit{

  @Input() allowedPaymentType: string;

  @Output() changedPaymentMethod = new EventEmitter<any>()
  @Output() selectedPaymentMethodChanged = new EventEmitter<any>()
  @Output() noValidPaymentMethod = new EventEmitter<any>()

  public paymentList: any;
  public selectedPayment;

  constructor(
    private stripe: StripeService,
    private popoverCtrl: PopoverController
  ){}

  ngOnInit(){
    if(this.allowedPaymentType && this.allowedPaymentType !== 'all'){

      this.paymentList = this.stripe.paymentList.filter((item) => item.type === this.allowedPaymentType)
      if(!this.paymentList.length){
        this.noValidPaymentMethod.emit({
          type: 'add_payment',
          validPayment: false,
          message: 'Nessun metodo di pagamento valido presente'
        })
        return;
      }else{
        this.noValidPaymentMethod.emit({
          type: 'add_payment',
          validPayment: true,
          message: 'Metodi di pagamento presenti'
        })
      }
    }else{
      this.paymentList = this.stripe.paymentList;
    }

    let validDefaultPaymentMethod = this.paymentList.filter((item) => item.id === this.stripe.default_payment_method)
    //il metodo di pagamento di default è inclso in lista?
    if(!validDefaultPaymentMethod.length){ //non è incluso, seleziono il primo
      this.selectedPayment = this.paymentList[0]
    }else{
      this.selectedPayment = validDefaultPaymentMethod[0]
    }

    this.changedPaymentMethod.emit({type: 'btn', value: true})
    this.selectedPaymentMethodChanged.emit(this.selectedPayment.id)
  }

  manageActionOnChange(){
    if(!this.selectedPayment){ //non ho selezionato nessuna carta
      this.changedPaymentMethod.emit({type: 'btn', value: false})
    }else{
      this.changedPaymentMethod.emit({type: 'btn', value: true})
      this.selectedPaymentMethodChanged.emit(this.selectedPayment.id)
    }

    ViewService.updateView.next()
  }

  async openSelect(event){
    if(this.paymentList.length <= 1){ //se nella select ho solo un metodo di pagamento, allora non apro il popover in quanto sara gia selezionato in automatico
      return;
    }
    const popover = await this.popoverCtrl.create({
      backdropDismiss: true,
      component: PopoverPaymentComponent,
      componentProps: {
        dataObj: {
          paymentList: this.paymentList
        },
        popoverType: 'payment_list'
      },
      cssClass: "popover_listPlain",
      event: event,
      showBackdrop: false,
    });
    popover.onDidDismiss().then((data: any) => {
      if(data && data.data){
        this.selectedPayment = data.data;
        this.manageActionOnChange()
        ViewService.updateView.next();
      }
    });
    await popover.present();
  }


}
