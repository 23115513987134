import { Component, OnInit, Input} from '@angular/core';
import {ModalController} from "@ionic/angular";

@Component({
  selector: 'app-image-detail',
  templateUrl: './image-detail.component.html',
  styleUrls: ['./image-detail.component.scss'],
})
export class ImageDetailComponent implements OnInit {

  @Input() image_src: string

  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {}

  dismiss(data?: any) {
    this.modalController.dismiss(data);
  }

}
