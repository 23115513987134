import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {

  static analytics: any;
  static tagPlatform: string;

  constructor() { }

  // Inizializza firebase per quella specifica app e attiva il listener di cambio di stato
  static initialization(firebase: any, tagPlatform: string){
    console.log("StatisticsService initialization")
    try{
      StatisticsService.analytics = firebase.analytics();
      console.log('statistiche inizializzate');
      StatisticsService.tagPlatform = tagPlatform;
    }catch(err){
      console.error(err);
    }
  }

  // Invia un evento all'analytics di google
  static sendLogEventGoogle(name: string, params?: any){
    console.log("sendLogEventGoogle", StatisticsService.analytics)
    try{
      if(StatisticsService.analytics){
        if(!params) params = {};
        params.platform = StatisticsService.tagPlatform;
        StatisticsService.analytics.logEvent(name, params);
      }
    }catch(err){
      console.error(err);
    }
  }
}
