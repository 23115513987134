import { Injectable } from '@angular/core';
import {Subject} from "rxjs";
import {
  AlertController, LoadingController,
  ModalController, PopoverController
} from "@ionic/angular";
import {
  SmallAlertPage
} from "../../components/common/small-alert/small-alert.page";
import {
  AlertPage
} from "../../components/common/alert/alert.page";

@Injectable({
  providedIn: 'root'
})
export class ViewService {

  // Oggetto con tutte le icone necessarie con chiave es {'succ': 'assets/img/icons/...'} valorizzato in inizialize
  static iconsMap: any = {};
  static isMobile: boolean;

  pipeChanged: number = 0;



  // VIGO
  currentPath: string;


  // I don't like this here
  topbar = {
    currentPath: "routes-history",
  };

  loading: any;

  // events

  static updateView = new Subject<any>();
  static updateView$ = ViewService.updateView.asObservable();




  constructor(
    private alertController: AlertController,
    private modalCtrl: ModalController,
    private loadingCtrl: LoadingController,
    private popoverController: PopoverController
  ) {}

  initialize(iconsMap: any, isMobile?: boolean){
    ViewService.iconsMap = iconsMap;
    ViewService.isMobile = isMobile;
  }



  /**
   * VIGO
   * @param path
   */
  setCurrentPath(path: string){
    this.currentPath = path;
    ViewService.updateView.next('currentPath');
  }

  async presentAlert(header: string, message: string, buttons: any[], subHeader?: string, cssClass?: string, backdropDismiss?: boolean, inputs?: any[]) {
    const alert = await this.alertController.create({
      cssClass: cssClass,
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: buttons,
      inputs: inputs,
      backdropDismiss: backdropDismiss
    });
    await alert.present();
  }

  // Crea un alert grafico. L'icona è presa da iconsMap e deve essere passato nel metodo initialize
  async modalAlert(title?: string, msg?: string, icon?: string, buttons?: any, disable_backdrop_dismiss?: boolean ) {
    if(buttons){
      if(!Array.isArray(buttons)){
        buttons = [{label: buttons}];
      }
    }
    if(!icon) icon = 'info';
    const modal: HTMLIonModalElement = await this.modalCtrl.create({
      component: ViewService.isMobile ? SmallAlertPage : AlertPage,
      componentProps: {
        msg: msg,
        title: title,
        buttons: buttons,
        view: ViewService,
        hideCloseButton: disable_backdrop_dismiss
      },
      backdropDismiss: !disable_backdrop_dismiss,
      cssClass: "modal_alert",
    });
    modal.componentProps.icon = ViewService.iconsMap[icon] || '';
    await modal.present();
    return modal;
  }

  async showLoading(message?: any){
    if(this.loading && this.loading.isOverlay) this.loading.dismiss();
    this.loading = await this.loadingCtrl.create({
      message: message
    });
    await this.loading.present();
  }

  async hideLoading(){
    if(this.loading) await this.loading.dismiss();
  }

  async presentPopover(ev: any, component: any, componentProps?: any) {
    if(!component) return;
    const popover = await this.popoverController.create({
      component: component,
      event: ev,
      translucent: true,
      componentProps: componentProps
    });
    await popover.present();
    return popover;
  }


}
