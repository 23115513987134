import {Injectable} from '@angular/core';
import {DataService} from "./data.service";
import {ApiService} from "./api.service";
import {
  Invoice,
  INVOICE_STATUS
} from "../../classes/common/invoice";
import {
  PaymentMethods
} from "../../classes/common/paymentMethods";
import {TranslateService} from "@ngx-translate/core";
import {
  Subscription
} from "../../classes/common/subscription";
import { ToastController } from "@ionic/angular";
import { Subject } from "rxjs";
import {ShopService} from "src/app/services/common/shop.service";


declare var Stripe: any;


@Injectable({
  providedIn: 'root'
})
export class StripeService{

  static setLoader = new Subject<any>();
  static setLoader$ = StripeService.setLoader.asObservable()

  public stripe: any;
  public paymentList: any[] = [];
  public subscription: Subscription;
  public customer_secret: string;
  public customer_id: string;
  public upcoming_invoice: Invoice;
  public default_payment_method: string;
  public invoices_list: Invoice[] = [];
  public orders_invoices_list: Invoice[] = [];
  public balance: any;
  public loader_balance: boolean = false;
  public loader_payment_list: boolean = false;
  public loader_invoices: any[] = [];
  public invoice_status: any;

  //Balance
  public minRechargeAmount: number = 100;
  public rechargeAmount: number = 100;
  public minAmountBankTransfer: number;

  public stripeElements: any;
  public stripePaymentElement: any;
  public stripeIbanPayment: string;
  public waitingOrder: boolean = false;

  constructor(
    private data: DataService,
    private api: ApiService,
    public toastController: ToastController,
    private translate: TranslateService,
    private shop: ShopService
  ){}

  async inizialize(){
    try{

      await DataService.isInitialized().then(async () => {
        this.stripe = Stripe(DataService.generalConfig.stripe_public_key);
        await this.getPayment();
        if(this.data.selOrganization.stripe_customer){
          //check if the customer has some subscriptions
          await this.getDefaultPayment();
          await this.getUpcomingInvoice()
          await this.getInvoices()
          await this.getBalance()
          await this.getSubscription()
          await this.getIntent()
          this.setCustomer()
          this.invoice_status = INVOICE_STATUS
        }
        this.minAmountBankTransfer = DataService.generalConfig.minRechargeAmount
        this.stripeIbanPayment = DataService.generalConfig.iban
      })


    }catch(err){
      console.error(err)
    }


  }

  async inizialize_order_invoices(){
    try{

      await DataService.isInitialized().then(async () => {
        this.stripe = Stripe(DataService.generalConfig.stripe_public_key);
        await this.getPayment();
        if(this.data.selOrganization.stripe_customer){
          //check if the customer has some subscriptions
          await this.getDefaultPayment();
          await this.getOrdersInvoices()
          this.setCustomer()
          this.invoice_status = INVOICE_STATUS
        }
        this.minAmountBankTransfer = DataService.generalConfig.minRechargeAmount
        this.stripeIbanPayment = DataService.generalConfig.iban
      })


    }catch(err){
      console.error(err)
    }


  }

  async inizialize_lite(){
    try{
      await DataService.isInitialized().then(async () => {
        this.stripe = Stripe(DataService.generalConfig.stripe_public_key);
        await this.getPayment();
        await this.getDefaultPayment();
        await this.getIntent()
        this.minAmountBankTransfer = DataService.generalConfig.minRechargeAmount
        this.stripeIbanPayment = DataService.generalConfig.iban
      })


    }catch(err){
      console.error(err)
    }


  }

  setCustomer(){
    this.customer_id = this.data.selOrganization.stripe_customer_id
  }

  /**
   * Set customer secret
   *
   * @param secret
   */
  setCustomerSecret(secret: string){
    this.customer_secret = secret
  }

  /**
   * Set default payment method for the customer
   * @param paymentId
   */
  async getDefaultPayment(paymentId: string = ''){
    if(this.data.selOrganization.stripe_customer){
      this.default_payment_method = paymentId ? paymentId : (this.data.selOrganization.stripe_customer.invoice_settings.default_payment_method || '')
    }
  }

  getLineName(type: number): string{
    let typeTrackerGps = DataService.generalConfig.type_trackerGps
    for(let key in typeTrackerGps){
      if(type && typeTrackerGps[key].type_trackerGps === type){
        return typeTrackerGps[key].description
      }
    }

    return undefined;

  }

  /**
   * Get all the active subscription for the customer
   */
  async getUpcomingInvoice(){
    try{
      if(this.data.selOrganization.stripe_upcoming_invoice){
        this.upcoming_invoice = new Invoice(this.data.selOrganization.stripe_upcoming_invoice, {getLineName: this.getLineName})
      }else{
        this.upcoming_invoice = undefined;
      }
    }catch(e){
      console.error(e)
    }

  }

  async getSubscription(){
    this.subscription = this.data.selOrganization.stripe_subscription;
  }

  /**
   * Get all the invoice
   */
  async getInvoices(){
    try{
      if(this.data.selOrganization.stripe_invoices){
        let wrapper_invoice_list = []

        let invoice_sorted: any = Object.values(this.data.selOrganization.stripe_invoices).sort((a, b) =>{
          let aObj = new Invoice(a)
          let bObj = new Invoice(b)

          return  bObj._finalized_at - aObj._finalized_at
        } )

        let orders: any = await this.api.shopGetOrdersByOrganizationId(this.data.selOrganization.me._id, this.data.selOrganization._id)

        for(let invoice of invoice_sorted){
          if(invoice.status_formatted !== 'not_showed'){
            let invoiceObj = invoice
            if(invoice.metadata?.type === 'product'){
              //cerco nella lista ordini quello da associare con l'invoice
              let res: any = orders.find((elem)=>{
                return elem.invoice_id === invoice.id
              })

              if(res){
                invoiceObj = {...invoice,...{order: res}}
              }
            }

            wrapper_invoice_list.push(this.shop.mapInvoiceLineWithProduct(new Invoice(invoiceObj, {getLineName: this.getLineName})) );
          }
        }
        this.invoices_list = wrapper_invoice_list
      }
    }catch(err){
      console.error(err)
    }


  }

  async getOrdersInvoices(){
    try{
      if(this.data.selOrganization.stripe_invoices && !this.waitingOrder){
        let wrapper_invoices_list = [];

        let orders: any = await this.api.shopGetOrdersByOrganizationId(this.data.selOrganization.me._id, this.data.selOrganization._id)
        let orders_sorted: any = Object.values(orders).sort((a, b) => b['created_timestamp'] - a['created_timestamp'])

        for(let order of orders_sorted){
          //devo trovare l'invoice legata all'ordine
          let obj = order
          if(this.data.selOrganization.stripe_invoices[order.invoice_id]){
              obj = {...this.data.selOrganization.stripe_invoices[order.invoice_id], ...{order: order}}
          }
          wrapper_invoices_list.push(this.shop.mapInvoiceLineWithProduct(new Invoice(obj, {getLineName: this.getLineName})) );

        }

        this.orders_invoices_list = wrapper_invoices_list;
      }
    }catch(err){
      console.error(err)
    }

  }

  async getBalance(){
    try{
      if(this.data.selOrganization?.stripe_customer_balance !== false){
        this.balance = this.data.selOrganization?.stripe_customer?.balance_formatted || 0
      }
    }catch(err){
      console.error(err)
    }


  }

  /**
   * Get the intent
   */
  async getIntent(){

    await this.api.getClientSecretStripe(this.data.selOrganization?._id, this.data.selOrganization?.me._id)
    .then((item: any) => {
      this.setCustomerSecret(item.client_secret)

    })
    .catch((err) => {
      console.error(err)
    })

  }

  /**
   * Get list of payment method by stripe customer
   */
  async getPayment(){
    this.paymentList = [];
    if(this.data.selOrganization?.stripe_payment_methods){
      for(let key in this.data.selOrganization?.stripe_payment_methods){
        this.paymentList.push(new PaymentMethods(this.data.selOrganization?.stripe_payment_methods[key]));
      }
    }

    await this.getDefaultPayment()
  }

  /**
   * Method used to download the invoices's pdf
   * @param invoice_number
   */
  downloadInvoice(invoice_number: string){
    return new Promise((resolve, reject) => {
      //impostare metodo di pagamento selezionato come default, after that dismiss
      this.api.getStripeInvoiceUrl(this.data.selOrganization._id, this.data.selOrganization.me._id, invoice_number, this.data.selOrganization.platform_type)
      .then((res) => {
        return resolve(res)
      })
      .catch((err) => {
        return reject(err)
      })
    })

  }

  downloadMandate(id_payment_method){
    return new Promise((resolve, reject) => {
      //impostare metodo di pagamento selezionato come default, after that dismiss
      this.api.getMandateUrlByPaymentMethodId(this.data.selOrganization._id, this.data.selOrganization.me._id, id_payment_method)
        .then((res) => {
          return resolve(res)
        })
        .catch((err) => {
          return reject(err)
        })
    })
  }

  /**
   * Metodo che gestisce i popover
   *
   *  1. Popover su card metodi di pagamento
   *  2. Popover su singolo metodi di pagamento
   *  3. Popover su modifica dati anagrafici
   *  4. popover aggiungi metodi di pagamento
   * @param type
   * @param values
   *
   * @return array
   */
  getPaymentPopoverOptions(type, values = '', translate: TranslateService){
    let opts;
    switch(type){
      case 1:
        opts = [
          {
            id: 'view_all_payment',
            lbl: translate.instant("PAYMENT.popover.vedi_tutti_metodi_pagamento"),
            color: 'default',
            divider: true,
          },
          {
            id: 'add_payment_method',
            lbl: translate.instant("PAYMENT.popover.aggiunti_metodo_pagamento"),
            color: 'default'
          }
        ]

        break;
      case 2:
        opts = [
          {
            id: 'set_as_default',
            lbl: translate.instant("PAYMENT.popover.imposta_come_predefinito"),
            color: 'default', //define font color
            data: values, //additional value
          },
          {
            id: 'delete_payment',
            lbl: translate.instant("PAYMENT.popover.elimina_metodo_pagamento"),
            color: 'danger', //define font color
            data: values, //additional value
          }
        ]
        break;
      case 3:
        opts = [
          {
            id: 'edit_personal_data',
            lbl: translate.instant("PAYMENT.popover.modifica_dati"),
            color: 'default'
          }
        ]
        break;
      case 4:
        opts = [
          {
            id: 'add_payment_method',
            lbl: translate.instant("PAYMENT.popover.aggiunti_metodo_pagamento"),
            color: 'default'
          }
        ]
        break;
      case 5:
        opts = [
          {
            id: 'recharge_balance',
            lbl: translate.instant("PAYMENT.popover.ricarica_balance"),
            color: 'default'
          }
        ]
        break;
      case 6:
        opts = [
          {
            id: 'set_as_default',
            lbl: translate.instant("PAYMENT.popover.imposta_come_predefinito"),
            color: 'default', //define font color
            data: values, //additional value
          },
          {
            id: 'download_mandate',
            lbl: translate.instant("PAYMENT.scarica_mandato"),
            color: 'default', //define font color
            data: values, //additional value
          }, {
            id: 'delete_payment',
            lbl: translate.instant("PAYMENT.popover.elimina_metodo_pagamento"),
            color: 'danger', //define font color
            data: values, //additional value
          }
        ]
        break;
    }
    return opts;
  }

  /**
   * Detach del metodo di pagamento dal customer
   * @param idPaymentMethod
   */
  deletePaymentMethod(idPaymentMethod: string){
    return new Promise((resolve, reject) => {
      //impostare metodo di pagamento selezionato come default, after that dismiss
      this.api.deleteStripeCustomerSource(this.data.selOrganization._id, this.data.selOrganization.me._id, idPaymentMethod)
      .then((res) => {
        return resolve(res)
      })
      .catch((err) => {
        return reject(err)
      })
    })
  }

  async setDefaultPayment(idPaymentMethod: string){
    new Promise((resolve, reject) => {
      this.api.patchStripeCustomerDefaultPaymentMethod(this.data.selOrganization._id, this.data.selOrganization.me._id, idPaymentMethod)
      .then((items: any) => {
        resolve(items)
      })
      .catch((err) => {
        reject(err)
      })
    })
  }

  createElement(type: string){
    let options = {
      clientSecret: this.customer_secret,
      appearance: {
        theme: 'stripe'
      }
    }
    this.stripeElements = this.stripe.elements(options);

    switch(type){
      case 'card':
        this.stripePaymentElement = this.stripeElements.create('card');
        break;
      case 'sepa':
        this.stripePaymentElement = this.stripeElements.create('iban', {
          supportedCountries: ['SEPA'],
        });
        break;
    }
    this.stripePaymentElement.mount('#payment-element');
  }

  /**
   *
   * @param message, message to been show in toast
   * @param duration, duration of the toast in ms
   * @param color, primary | secondary | success | warning | danger...
   */
  async showToast(message: string, duration: number, color?: string){
    const toast = await this.toastController.create({
      message: message,
      duration: duration ? duration : 2000,
      color: color ? color : "success"
    });

    toast.present();
  }

  /**
   * Copy message to clipoboard
   *
   * @param message
   * @param lbl
   */
  async copyToClipboard(message: any, lbl?: string){
    if(message){
      await navigator.clipboard.writeText(message)
      await this.showToast(lbl || this.translate.instant('PAYMENT.copy'), 1500, "success")
    }
  }

  isEmptyObject(obj) {
    return (obj && (Object.keys(obj).length === 0));
  }
}
