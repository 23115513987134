import {
  Injectable, Pipe,
  PipeTransform
} from '@angular/core';

@Pipe({
  name: 'emailList'
})
@Injectable()
export class EmailListPipe implements PipeTransform {
  transform(emails: any[], limit: number, noElementsLabel?: string): string {
    let res: string = ``;
    if(!emails || !limit || emails.length <= 0) return noElementsLabel || "";
    for(let i=0; i<emails.length; i++){
      let email: string = emails[i];
      if(i < limit){
        res += email + `<br>`;
      }else{
        res += `<label class="text-muted"> + ${emails.length - limit} </label>`;
        break;
      }
    }
    return res;
  }
}
