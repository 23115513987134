import {NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {IonicModule} from "@ionic/angular";
import {TranslateModule} from "@ngx-translate/core";
import {CommonPipesModule} from "../../pipes/common/pipes.module";
import {AlertPage} from "./alert/alert.page";
import {AvatarItemComponent} from "./avatar-item/avatar-item.component";
import {ChipbarComponent} from "./chipbar/chipbar.component";
import {SmallAlertPage} from "./small-alert/small-alert.page";
import {TopBarComponent} from "./top-bar/top-bar.component";
import {FaqComponent} from "./faq/faq.component";
import {StepCardComponent} from "./step-card/step-card.component";
import {SelectPaymentMethodComponent} from "./select-payment-method/select-payment-method.component";
import { InvoiceCostDetailsComponent } from './invoice-cost-details/invoice-cost-details.component';
import {PopoverPaymentComponent} from "./popover-payment/popover-payment.component";
import {RecapInvoiceComponent} from "src/app/components/mobile/recap-invoice/recap-invoice.component";
import {LoginComponent} from "src/app/components/common/login/login.component";
import {ReactiveFormsModule} from "@angular/forms";
import {
  DiagnosticPanelComponent
} from "./diagnostic-panel/diagnostic-panel.component";
import {PopoverFleetComponent} from "src/app/components/common/popover-fleet/popover-fleet.component";
import {TooltipModule} from "./tooltip/tooltip.module";

@NgModule({
  declarations: [
    AlertPage,
    AvatarItemComponent,
    ChipbarComponent,
    SmallAlertPage,
    TopBarComponent,
    FaqComponent,
    StepCardComponent,
    SelectPaymentMethodComponent,
    InvoiceCostDetailsComponent,
    PopoverPaymentComponent,
    RecapInvoiceComponent,
    LoginComponent,
    DiagnosticPanelComponent,
    PopoverFleetComponent
  ],
  entryComponents: [

  ],
  imports: [CommonModule, FormsModule, IonicModule, TranslateModule, CommonPipesModule, ReactiveFormsModule, TooltipModule],
  exports: [
    AlertPage,
    AvatarItemComponent,
    ChipbarComponent,
    SmallAlertPage,
    TopBarComponent,
    FaqComponent,
    StepCardComponent,
    SelectPaymentMethodComponent,
    InvoiceCostDetailsComponent,
    PopoverPaymentComponent,
    RecapInvoiceComponent,
    LoginComponent,
    DiagnosticPanelComponent,
    PopoverFleetComponent
  ],
})

export class CommonComponentsModule {}
