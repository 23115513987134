import { Component, OnInit } from '@angular/core';
import {ShopService} from "src/app/services/common/shop.service";
import {Input} from "@angular/core";
import {ViewService} from "src/app/services/common/view.service";
import {DataService} from "src/app/services/common/data.service";
import {ModalController} from "@ionic/angular";
import {AppMobileComponent} from "src/app/app-mobile.component";
import {NavController} from "@ionic/angular";
import {FinalizeOrderPage} from "src/app/pages/mobile/common/finalize-order/finalize-order.page";
import {AppLauncher} from "@capacitor/app-launcher";
import {LoginModalPage} from "src/app/pages/mobile/common/login-modal/login-modal.page";
import {NavigationService} from "src/app/services/common/navigation.service";

@Component({
  selector: 'app-recap-invoice',
  templateUrl: './recap-invoice.component.html',
  styleUrls: ['./recap-invoice.component.scss'],
})
export class RecapInvoiceComponent implements OnInit {


  @Input() shop: ShopService
  @Input() lang: any;
  @Input() appComponent: AppMobileComponent

  listener : any ={
    setLoader: null,
    removeLoader: null
  }
  constructor(
    public data: DataService,
    private modalCtrl: ModalController,
    private view: ViewService,
    private navController: NavController,
  ) { }

  ngOnInit(){
  }

  async ionViewWillEnter(){
    this.listenerSetLoader()
    this.listenerRemoveLoader()
  }


  /**
   * Listener for set loader
   */
  listenerSetLoader(){
    this.listener.setLoader = ShopService.setLoader$.subscribe((obj: any) => {
      this.setLoader(obj.target, obj.value)
    });
  }

  /**
   * Listener for remove loader
   */
  listenerRemoveLoader(){
    this.listener.removeLoader = ShopService.removeLoader$.subscribe((obj: any) => {
      this.removeLoader(obj.target, obj.value)
    });
  }


  /**
   * Gestione loader per lista pagamenti, invoice e balance
   * @param type
   * @param value
   */
  setLoader(type, value: number){
    switch(type){
      case 'invoice':
        this.shop.invoiceLoader = true;
        break;
    }
    ViewService.updateView.next();
  }

  /**
   * Rimozione loader per lista pagamenti, invoice e balance
   * @param type
   * @param value
   */
  removeLoader(type, value: number){
    switch(type){
      case 'invoice':
        this.shop.invoiceLoader = false;
        break;
    }
    ViewService.updateView.next();
  }

  ionViewWillLeave(){

    if(this.listener.setLoader){
      this.listener.setLoader.unsubscribe();
    }
    if(this.listener.removeLoader){
      this.listener.removeLoader.unsubscribe();
    }
  }v

  /**
   *
   */
  async openFinalizeOrder(){
    if(this.data.selOrganization.billing_type){
      let modal = await this.modalCtrl.create({
        component: FinalizeOrderPage,
        cssClass: "modal_alarm--h",
        componentProps: {
          shopInvoice: this.shop.shop_invoice,
          shopService: this.shop,
          appComponent: this.appComponent
        }
      });
      await modal.present();

      const {data} = await modal.onDidDismiss();

      if (data && data.payment) {
        //show loader
        this.navController.navigateForward('tabs/menu/order-history');
      } else {
        return false;
      }
    }else{
      await this.modalUserData()
    }

    ViewService.updateView.next();
  }


  isLoading(){
    if(this.shop.productLoader.length > 0 ){
      return true
    }
    return false
  }

  openTermsOfSale(){
    try{
      AppLauncher.openUrl({url: this.shop.url_terms_of_sales});
    }catch(err){
      console.error(err);
    }
  }


  async modalUserData(){
    if(this.appComponent.isForcedAuth || !this.data.selOrganization?.billing_type){
      await NavigationService.navigate("tabs/menu/user-data", false, false,  {closeModal: true});
    }else{
      await this.view.presentAlert(this.lang.modifica_anagrafica, this.lang.info_apertura_ticket, [{
        text: this.lang.chiudi, role: 'cancel', cssClass: 'secondary'
      }, {
        text: this.lang.apri_ticket, handler: () => {
          NavigationService.navigate("tabs/menu/support/tickets/ticket-add", false, true);
        }
      }]);
    }
    ViewService.updateView.next()
  }

  async openLogin(){
    const modal = await this.modalCtrl.create({
      component: LoginModalPage,
      componentProps: {
        closeModal: true,
        disableTitle: true,
      },
      cssClass: "modal_alarm--h",
    });
    return await modal.present();
    ViewService.updateView.next()
  }

}
