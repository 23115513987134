import {Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import {Input} from "@angular/core";
import {
  ViewService
} from "../../../services/common/view.service";
import {Poi} from "../../../classes/common/poi";

@Component({
  selector: 'app-poi',
  templateUrl: './poi.component.html',
  styleUrls: ['./poi.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PoiComponent implements OnInit {

  // component args
  @Input() val: Poi;

  // listener
  listener = {
    view: null,
  };

  constructor(
    private cd: ChangeDetectorRef,
    public view: ViewService,
  ) {}

  ngOnInit() {
    this.listenerView();
  }

  ngOnDestroy() {
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
  }

  // listener
  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.cd.markForCheck();
      setTimeout(() => {
        this.cd.markForCheck();
      }, 250);
    });
  }
}
