import {Injectable} from '@angular/core';
import {GoogleMapsService} from "./google-map.service";
import {MARKER_WITH_DIRECTION} from "src/models/markerSize";

@Injectable({
  providedIn: 'root'
})
export class PoiMapService {

  readonly MAP_NAME: string = "poi";
  mapType: string;
  map: any;
  poiMarker: any;
  circle: any;
  popup: any;
  static urlMarkerPoi: string;
  static popupLabel: string; // Frase da scrivere nel popup aperto sopra il marker

  constructor(
    public googleMaps: GoogleMapsService
  ) {
  }

  initMap(mapType: string, urlMarkerPoi: string, popupLabel: string, lat?: number, lng?: number, radius?: number) {
    PoiMapService.urlMarkerPoi = urlMarkerPoi;
    PoiMapService.popupLabel = popupLabel;
    this.mapType = mapType;
    switch (this.mapType) {
      case "Google":
        this.initMapGoogle(lat, lng, radius);
        break;
    }
  }

  drawPoiMarker(lat: number, lng: number, radius: number, isStandardMarker?: boolean){
    switch (this.mapType) {
      case "Google":
        this.drawPoiMarkerGoogle(lat, lng, radius, isStandardMarker);
        break;
    }
  }

  cleanPoiMarkers(){
    switch (this.mapType) {
      case "Google":
        this.cleanPoiMarkeGoogle();
        break;
    }
  }

  // Muove il marker, il cerchio e anche il label nella posizione voluta (solitamente a ricerca indirizzo
  movePoiMarker(lat: number, lng: number){
    switch (this.mapType) {
      case "Google":
        this.movePoiMarkerGoogle(lat, lng);
        break;
    }
  }

  // Cambia il raggio del marker
  changeCircleRadius(radius: number){
    switch (this.mapType) {
      case "Google":
        this.changeCircleRadiusGoogle(radius);
        break;
    }
  }



  private async initMapGoogle(lat?: number, lng?: number, radius?: number) {
    this.googleMaps.clearMap();
    this.map = this.googleMaps.getMap();
    let mapContainer: any = document.getElementById("poi-map");
    if(mapContainer){
      mapContainer.appendChild(this.googleMaps.getDivMap());
      this.googleMaps.setCurrentMap(this.MAP_NAME);
    }
    this.map.setOptions({
      center: {lat: lat || 44.69, lng: lng || 7.84},
      zoom: 9,
      fullscreenControl: false,
      zoomControl: true,
      streetViewControl: false,
      mapTypeControl: false,
      mapTypeControlOptions: {
        style: GoogleMapsService.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: GoogleMapsService.google.maps.ControlPosition.RIGHT_BOTTOM
      },
    });
    this.poiMarker = undefined;
    this.circle = undefined;
    this.drawPoiMarker(lat || 44.7030, lng || 7.8395, radius, true);
  }

  private drawPoiMarkerGoogle(lat: number, lng: number, radius: number, isStandardMarker?: boolean){
    let icon: any = {}
    if(isStandardMarker){
      icon = {
        url: PoiMapService.urlMarkerPoi,
        size: new GoogleMapsService.google.maps.Size(36, 45),
        scaledSize: new GoogleMapsService.google.maps.Size(36, 45),
        origin: new GoogleMapsService.google.maps.Point(0, 0),
        anchor: new GoogleMapsService.google.maps.Point(18, 45),
        labelOrigin: new GoogleMapsService.google.maps.Point(18, 22)
      };
    }else{
      icon = {
        url: PoiMapService.urlMarkerPoi,
        scaledSize: new GoogleMapsService.google.maps.Size(MARKER_WITH_DIRECTION[this.googleMaps.marker_opts.marker_size].size.x, MARKER_WITH_DIRECTION[this.googleMaps.marker_opts.marker_size].size.y), // Dimensioni desiderate del marker
        size: new GoogleMapsService.google.maps.Size(MARKER_WITH_DIRECTION[this.googleMaps.marker_opts.marker_size].size.x, MARKER_WITH_DIRECTION[this.googleMaps.marker_opts.marker_size].size.y), // Dimensioni desiderate del marker        origin: new GoogleMapsService.google.maps.Point(0, 0),
        anchor: new GoogleMapsService.google.maps.Point(MARKER_WITH_DIRECTION[this.googleMaps.marker_opts.marker_size].anchor.x, MARKER_WITH_DIRECTION[this.googleMaps.marker_opts.marker_size].anchor.y),
        origin: new GoogleMapsService.google.maps.Point(0, 0),
      };
    }

    const coord: any = {
      lat: lat,
      lng: lng
    };
    let marker: any = new GoogleMapsService.google.maps.Marker({
      zIndex: 1,
      position: coord,
      icon: icon,
      id: 'poi',
      draggable: true,
      raiseOnDrag: true
    });
    let circle = new GoogleMapsService.google.maps.Circle({
      center: coord,
      radius: radius,
      strokeColor: "#2e97f2",
      strokeWeight: 2,
      fillColor: "#2e97f2",
      fillOpacity: 0.15,
      id: 'poi'
    });
    marker.addListener('dragend', () => { // se vogliamo muovere tutto in contemporanea mettere evento 'drag'
      if(this.circle) this.circle.setCenter(marker.getPosition());
      if(this.popup) this.popup.position = marker.getPosition();
    });
    this.poiMarker = marker;
    this.circle = circle;
    this.googleMaps.addCircle(circle, this.MAP_NAME);
    this.googleMaps.addMarker(marker, this.MAP_NAME, true);
    this.popup = this.createPopupGoogle(marker.getPosition());
    this.googleMaps.addPopup(this.popup, this.MAP_NAME);
    this.fitCenterGoogle();
  }

  private cleanPoiMarkeGoogle(){
    if(this.poiMarker){
      this.googleMaps.removeMarker(this.poiMarker, this.MAP_NAME);
    }
    if(this.circle){
      this.googleMaps.removeMarker(this.circle, this.MAP_NAME);
    }
    if(this.popup){
      this.googleMaps.removePopup(this.popup, this.MAP_NAME);
    }
  }

  // Crea il label sopra al marker, devo mettere anche la classe all'interno del metodo altrimenti non si riesce a fare
  private createPopupGoogle(position: any): any{
    class Popup extends GoogleMapsService.google.maps.OverlayView {
      position: any;
      containerDiv: HTMLDivElement;
      id: string;

      constructor(position: any, content: HTMLElement, id: string) {
        super();
        this.position = position;
        this.id = id;

        content.classList.add("map-poi-popup-bubble");

        // This zero-height div is positioned at the bottom of the bubble.
        const bubbleAnchor = document.createElement("div");
        bubbleAnchor.classList.add("map-poi-popup-bubble-anchor");
        bubbleAnchor.appendChild(content);

        // This zero-height div is positioned at the bottom of the tip.
        this.containerDiv = document.createElement("div");
        this.containerDiv.classList.add("map-poi-popup-container");
        this.containerDiv.appendChild(bubbleAnchor);

        // Optionally stop clicks, etc., from bubbling up to the map.
        Popup.preventMapHitsAndGesturesFrom(this.containerDiv);
      }

      /** Called when the popup is added to the map. */
      onAdd() {
        this.getPanes().floatPane.appendChild(this.containerDiv);
      }

      /** Called when the popup is removed from the map. */
      onRemove() {
        if (this.containerDiv.parentElement) {
          this.containerDiv.parentElement.removeChild(this.containerDiv);
        }
      }

      /** Called each frame when the popup needs to draw itself. */
      draw() {
        const divPosition = this.getProjection().fromLatLngToDivPixel(
          this.position
        );

        //Hide the popup when it is far out of view.
        const display =
          Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
            ? "block"
            : "none";
        // const display = 'block';

        if (display === "block") {
          this.containerDiv.style.left = divPosition.x + "px";
          this.containerDiv.style.top = divPosition.y + "px";
        }

        if (this.containerDiv.style.display !== display) {
          this.containerDiv.style.display = display;
        }
      }
    }

    let element: HTMLElement = document.createElement('div');
    element.innerText = PoiMapService.popupLabel;
    return new Popup(
      position,
      element,
      'label'
    );
  }

  private movePoiMarkerGoogle(lat: number, lng: number){
    const position: any = new GoogleMapsService.google.maps.LatLng(lat, lng);
    if(this.poiMarker) this.poiMarker.setPosition(position);
    if(this.circle) this.circle.setCenter(position);
    if(this.popup) this.popup.position = position;
    this.fitCenterGoogle();
  }

  private changeCircleRadiusGoogle(radius: number){
    if(this.circle){
      this.circle.setRadius(radius);
      this.fitCenterGoogle();
    }
  }

  private fitCenterGoogle(){
    let bounds: any = this.circle.getBounds();
    if(!bounds.isEmpty()){
      this.map.fitBounds(bounds);
      setTimeout(() => {
        if(this.map.getZoom() > 16){
          this.map.setZoom(16);
        }
      }, 200)

    }
  }
}

