import {NgModule} from "@angular/core";
import {SortAlphabeticallyPipe} from "./sortAlphabetically.pipe";
import {EmailListPipe} from "./emailList.pipe";
import {WeekDaysPipe} from "./weekDays.pipe";
import {ParticipantListPipe} from "./participantList.pipe";
import {SortPipe} from "./sort.pipe";
import {SearchPipe} from "./search.pipe";
import {DeltaTimePipe} from "./deltaTime.pipe";
import {DecimalNumberPipe} from "./decimal-number.pipe";
import {DurationPipe} from "./duration.pipe";
import {PoiListPipe} from "./poiList.pipe";
import { MergeMessagesPipe } from './merge-messages.pipe';
import {SearchTicketPipe} from "./search-ticket.pipe";
import { SearchFilterPipe } from './search-filter.pipe';
import {DeadlineVehiclesPipe} from "src/app/pipes/common/deadlineVehicles.pipe";


@NgModule({
  declarations: [
    SortAlphabeticallyPipe,
    EmailListPipe,
    WeekDaysPipe,
    SortPipe,
    SearchPipe,
    DeltaTimePipe,
    DecimalNumberPipe,
    ParticipantListPipe,
    DurationPipe,
    PoiListPipe,
    MergeMessagesPipe,
    SearchTicketPipe,
    SearchFilterPipe,
    DeadlineVehiclesPipe
  ],
  entryComponents: [],
  imports: [
  ], exports: [SortAlphabeticallyPipe, EmailListPipe, WeekDaysPipe, SearchPipe, SortPipe, DeltaTimePipe, DecimalNumberPipe, ParticipantListPipe, DurationPipe, PoiListPipe, MergeMessagesPipe, SearchTicketPipe, SearchFilterPipe, DeadlineVehiclesPipe],
})

export class CommonPipesModule {}
