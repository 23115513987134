import { Injectable } from '@angular/core';
import {AuthService} from "./auth.service";
import {ApiService} from "./api.service";
import {ViewService} from "./view.service";
import {Subject} from "rxjs";
import {
  Organization
} from "../../classes/common/organization";
import {Poi} from "../../classes/common/poi";
import {Platform} from "../../classes/common/platform";
import {
  GeneralConfig
} from "../../classes/common/generalConfig";
import {
  Partecipant
} from "../../classes/common/partecipant";
import {
  DEMO_ROUTES,
  DEMO_ROUTES_LIVE
} from "../../classes/common/position-gps";
import {AlarmPoi} from "../../classes/common/alarm-poi";
import {Fleet} from "src/app/classes/common/fleet";
import {Storage} from "@ionic/storage";
import {Ticket} from "src/app/classes/common/ticket";
import {DeadlineTarget} from "src/app/classes/common/deadline";
import {Deadline} from "src/app/classes/common/deadline";

@Injectable({
  providedIn: 'root'
})
export class DataService {

  static api: ApiService;
  static firebase: any;
  static initialized: boolean = false;
  static onInit = new Subject<any>();
  static onInit$ = DataService.onInit.asObservable();

  static hasGeneralConfig: boolean = false;
  static onGeneralConfig = new Subject<any>();
  static onGeneralConfig$ = DataService.onGeneralConfig.asObservable()

  static activationImei: string;
  static activationSerial: string;
  static activationModel: string;
  static registrationEmail: string;
  static customerPhone: string;
  static ownerPhone: string;

  showTabs: boolean = true;
  showDemoBadge: boolean = true;

  static limit: number = 5000;
  organizations: Organization[] = [];

  notificationsCount: number;
  ticketCount: number;

  public selOrganization: Organization = new Organization();
  static idOrganization: string;
  public pois: Poi[] = [];
  static platform: Platform = new Platform();

  static updateData = new Subject<any>();
  static updateData$ = DataService.updateData.asObservable();

  intervalsDemo: any[] = [];

  mobile_usersPage_user: any;
  mobile_ticketsPage_ticket: any;

  isDemo: boolean;
  static nativeApp: 'ios' | 'android' | undefined;
  static version: string;

  static generalConfig: GeneralConfig = new GeneralConfig();

  static bootControlsChecked: boolean; // Serve in mobile per capire se ho già fatto tutti i controlli all'avvio

  public fleetSelectedInStorage: Fleet;
  public fleetList: Fleet[] | null = [];

  tickets: Ticket[] = [];

  deadlineTarget: DeadlineTarget[] = [];
  deadline: Deadline[] = [];

  static firebase_storage: any;



  constructor(
    private storage: Storage
  ) { }

  async initialize(api: ApiService, firebase: any){
    DataService.api = api;
    DataService.firebase = firebase;
    DataService.firebase_storage = firebase.storage().ref();

    let dataLogin: any = await DataService.api.getLogin();

    if(dataLogin.token) {
      AuthService.token = dataLogin.token;
      if(AuthService.token?._id) await this.storage.set('authToken', AuthService.token._id.toString());
    }
    if(dataLogin.list_organization){
      this.organizations = [];
      for(let org of dataLogin.list_organization){
        this.organizations.push(new Organization(org));
      }
      if(dataLogin.list_partecipant && this.organizations.length > 0){
        this.selOrganization = this.organizations[0];
        DataService.idOrganization = this.selOrganization._id;
        this.triggerNotificationsDatabase(this.selOrganization._id);

        this.fleetSelectedInStorage = await this.storage.get('fleet')
        for(let part of dataLogin.list_partecipant){
          const org: Organization = this.organizations.find(el => el._id === part.idOrganization);
          let participant: Partecipant = new Partecipant(part);
          if(participant.email === AuthService.getEmail()){
            org.me = participant;
          }
          if(participant.isOwner){
            org.owner = participant;
          }

          if(this.fleetSelectedInStorage){
            if(this.fleetSelectedInStorage.participants){
              for(let elem of this.fleetSelectedInStorage.participants){
                if(elem.toString() === participant._id.toString()){
                  participant.$fleetShow = true;
                  break;
                }else{
                  participant.$fleetShow = false;
                }
              }
            }else{
              //se non è presente una flotta seleziona vuol dire che voglio vedere tutti i veicoli
              participant.$fleetShow = true;
            }
          }else{
            //se non è presente una flotta seleziona vuol dire che voglio vedere tutti i veicoli
            participant.$fleetShow = true;
          }
          org.addParticipant(participant);
        }
      }
    }

    try{
      let fleetList: any = await DataService.api.getFleet(this.selOrganization.me._id, this.selOrganization._id)
      if(fleetList && fleetList.fleets){
        for(let fleet of fleetList.fleets){
          this.fleetList.push(new Fleet(fleet))
        }
      }
    }catch (e){
      console.log('No fleet found')
    }


    /** Ottengo la lista di ticket in modo */
    try{
      let tickets: any = await DataService.api.getTicketsCommunication();
      if(tickets){
        for(let ticket of tickets){
          this.tickets.push(new Ticket(ticket))
        }
      }
    }catch(e){
      console.error('Error in getting user ticket')
    }


    /** Ottengo la lista di veicoli e autisti **/
    try{
      let deadline_target: any = await DataService.api.getDeadlinesTarget(this.selOrganization.me._id)
      if(deadline_target){
        for(let target of deadline_target){
          this.deadlineTarget.push(new DeadlineTarget(target))
        }
      }
    }catch (err){
      console.error('Error in get target', err)
    }

    /** Ottengo la lista di scadenze**/
    try{
      await this.retriveDeadline(0);
    }catch (err){
      console.error('Error in get target', err)
    }



    this.initializeDemo();
    if(this.selOrganization.me.functionalityIsEnabled('poi')){
      await this.retrievePoi(api, 0).then(async () => {
        await this.retrieveAlarmsPoi(api, 0);
        DataService.initialized = true;
        DataService.onInit.next(true);
      }).catch((err: any) => {
        console.error(err);
      });
    }else{
      DataService.initialized = true;
      DataService.onInit.next(true);
    }
    this.retrieveNotificationCount();
    this.retriveTicketCount();
  }

  async clearData(){
    this.pois = [];
    this.selOrganization = undefined;
    DataService.idOrganization = undefined;
    DataService.initialized = false;
    this.organizations = []
    this.tickets = [];
    this.resetDemo();
    if(this.notificationReference) this.notificationReference.off();
    ViewService.updateView.next();
  }

  // Inizializza 3 veicoli demo
  initializeDemo(){
    if(this.selOrganization.login_view_demo){
      const intervalDelta: number = 3000;
      let positionCounter1: number = 0;
      let positionCounter2: number = 0;
      let positionCounter3: number = 0;
      this.selOrganization.participants = [];
      let firstPos: any = DEMO_ROUTES_LIVE['-1'][0];
      this.selOrganization.participants.push(new Partecipant({
        _id: '-1',
        isDemo: true,
        type: 2,
        idOrganization: this.selOrganization._id,
        type_trackerGps: 1,
        imei: '00000001',
        isActive: true,
        timestampActivation: 1605017515,
        label_number: 1,
        label_string: 'Veicolo demo 1',
        total_odometer: 1000,
        fleetShow: true
      }));
      this.selOrganization.participants[0].resetDemoPosition(firstPos);
      this.intervalsDemo.push(setInterval(() => {
        setTimeout(() => {
          let pos: any = DEMO_ROUTES_LIVE['-1'][positionCounter1];
          if(!this.selOrganization.participants[0] || !this.selOrganization.participants[0].isDemo) return;
          this.selOrganization.participants[0].resetDemoPosition(pos);
          DataService.updateData.next({target: 'participant', type: 'update', participant: this.selOrganization.participants[0]});
          ViewService.updateView.next();
        }, Math.floor(Math.random() * (1000 - 1 + 1) + 1));
        positionCounter1++;
        if(positionCounter1 >= DEMO_ROUTES_LIVE['-1'].length) positionCounter1 = 0;
      }, intervalDelta));
      firstPos = DEMO_ROUTES_LIVE['-2'][0];
      this.selOrganization.participants.push(new Partecipant({
        _id: '-2',
        isDemo: true,
        type: 2,
        idOrganization: this.selOrganization._id,
        type_trackerGps: 1,
        imei: '00000002',
        isActive: true,
        timestampActivation: 1605017515,
        label_number: 2,
        label_string: 'Veicolo demo 2',
        total_odometer: 1000,
        fleetShow: true
      }));
      this.selOrganization.participants[1].resetDemoPosition(firstPos);
      this.intervalsDemo.push(setInterval(() => {
        setTimeout(() => {
          let pos: any = DEMO_ROUTES_LIVE['-2'][positionCounter2];
          if(!this.selOrganization.participants[1] || !this.selOrganization.participants[1].isDemo) return;
          this.selOrganization.participants[1].resetDemoPosition(pos);
          DataService.updateData.next({target: 'participant', type: 'update', participant: this.selOrganization.participants[1]});
          ViewService.updateView.next();
        }, Math.floor(Math.random() * (1000 - 1 + 1) + 1));
        positionCounter2++;
        if(positionCounter2 >= DEMO_ROUTES_LIVE['-2'].length) positionCounter2 = 0;
      }, intervalDelta));
      firstPos = DEMO_ROUTES_LIVE['-3'][0];
      this.selOrganization.participants.push(new Partecipant({
        _id: '-3',
        isDemo: true,
        type: 2,
        idOrganization: this.selOrganization._id,
        type_trackerGps: 1,
        imei: '00000003',
        isActive: true,
        timestampActivation: 1605017515,
        label_number: 3,
        label_string: 'Veicolo demo 3',
        total_odometer: 1000,
        fleetShow: true
      }));
      this.selOrganization.participants[2].resetDemoPosition(firstPos);
      this.intervalsDemo.push(setInterval(() => {
        setTimeout(() => {
          let pos: any = DEMO_ROUTES_LIVE['-3'][positionCounter3];
          if(!this.selOrganization.participants[2] || !this.selOrganization.participants[2].isDemo) return;
          this.selOrganization.participants[2].resetDemoPosition(pos);
          DataService.updateData.next({target: 'participant', type: 'update', participant: this.selOrganization.participants[2]});
          ViewService.updateView.next();
        }, Math.floor(Math.random() * (1000 - 1 + 1) + 1));
        positionCounter3++;
        if(positionCounter3 >= DEMO_ROUTES_LIVE['-3'].length) positionCounter3 = 0;
      }, intervalDelta));
      this.isDemo = true;
    }
  }

  // Resetta tutte le impostazioni demo
  resetDemo(): boolean{
    for(let interval of this.intervalsDemo){
      clearInterval(interval);
    }
    this.isDemo = false;
    this.intervalsDemo = [];
    if(this.selOrganization && this.selOrganization.participants){
      for(let i=0; i<this.selOrganization.participants.length; i++){
        if(this.selOrganization.participants[i].isDemo){
          DataService.updateData.next({target: 'participant', type: 'delete', participant: this.selOrganization.participants[i]});
          this.selOrganization.participants.splice(i, 1);
          i--;
        }
      }
    }
    return false;
  }

  getMeId(){
    if(this.selOrganization && this.selOrganization.me) return this.selOrganization.me._id;
  }

  // recupera i poi
  retrievePoi(api: ApiService, skip: number){
    return new Promise((resolve, reject) => {
      if(skip === 0) this.pois = [];
      api.getPois(this.selOrganization.me._id, skip, DataService.limit).then( async(res: any) => {
        if(res.data){
          for(let raw of res.data){
            this.pois.push(new Poi(raw));
          }
        }
        if(!res.has_more){
          ViewService.updateView.next();
          return resolve(null)
        }else{
          return resolve(this.retrievePoi(api, skip + res.data.length));
        }
      }).catch((err: any) => {
        return reject(err);
      })
    })
  }

  // recupera gli allarmi e gli associa ai poi
  retrieveAlarmsPoi(api: ApiService, skip: number){
    return new Promise((resolve, reject) => {
      api.getAlarmsPoi(this.selOrganization.me._id, skip, DataService.limit).then(async (res: any) => {
        if(res.data){
          for(let raw of res.data){
            const alarm: AlarmPoi = new AlarmPoi(raw);
            alarm.poi = this.pois.find((elem: Poi) => {
              return elem._id === alarm.idPoi;
            });
            const participant: Partecipant = this.selOrganization.participants.find((elem: Partecipant) => {
              return elem._id === alarm.idPartecipant;
            });
            if(participant){
              participant.addAlarmPoi(alarm);
            }
          }
        }
        if(!res.has_more){
          ViewService.updateView.next();
          return resolve(null)
        }else{
          return resolve(this.retrievePoi(api, skip + res.data.length));
        }
      }).catch((err: any) => {
        return reject(err);
      })
    })
  }

  retrieveNotificationCount(){
    DataService.api.getNotificationsCount(this.selOrganization.me._id).then((res: any) => {
      if(res){
        this.notificationsCount = res.data;
        ViewService.updateView.next();
      }
    }).catch((err: any) => {
      console.error(err);
    });
  }

  retriveTicketCount(){
    DataService.api.getTicketCount().then((res: any) => {
      if(res){
        this.ticketCount = res.data;
        ViewService.updateView.next();
      }
    }).catch((err: any) => {
      console.error(err);
    });
  }

  retriveDeadline(skip: number){
    return new Promise((resolve, reject) => {
      DataService.api.getDeadlines(skip, DataService.limit, this.selOrganization.me._id).then(async (res: any) => {
        if(res){
          for(let raw of res){
            this.deadline.push(new Deadline(raw, {getParticipant: this.selOrganization.getParticipant.bind(this.selOrganization), getTarget: this.getTarget.bind(this)}))
          }
        }
        return resolve(null)

      }).catch((err: any) => {
        return reject(err);
      })
    })
  }

  static isInitialized(){
    return new Promise((resolve) => {
      if(DataService.initialized) return resolve(null)
      let listener: any = DataService.onInit$.subscribe(() => {
        listener.unsubscribe();
        return resolve(null)
      });
    });
  }
  static isGeneralConfig(){
    return new Promise((resolve) => {
      if(DataService.hasGeneralConfig) return resolve(null)
      let listener: any = DataService.onGeneralConfig$.subscribe(() => {
        listener.unsubscribe();
        return resolve(null)
      });
    });
  }


  notificationReference: any;

  private triggerNotificationsDatabase(organizationId: string){
    if(this.notificationReference) this.notificationReference.off();

    this.notificationReference = DataService.firebase.database().ref('/organization/' + organizationId);
    let first: boolean = true;
    this.notificationReference.on('value', (snapshot: any) => {
      if(!first){
        this.parseNotification(snapshot.val());
      }else{
        first = false;
      }
    });
  }

  /**
   * Parsifica l'oggetto di notifica ricevuto sul database delle notifiche
   * @param notification
   */
  private parseNotification(notification: any){
    if(!notification) return;
    switch (notification.element){
      case 'organization':
        this.manageOrganizationNotification(notification);
        break;
      case 'partecipant':
        this.manageParticipantNotification(notification);
        break;
      case 'poi':
        this.managePoiNotification(notification);
        break;
      case 'list_commandTrackerGps_finished':
        DataService.updateData.next({target: 'commandTrackerGps', type: notification.type, id: notification._id});
        break;
      case 'notification':
        this.retrieveNotificationCount();
        DataService.updateData.next({target: 'notification', type: notification.type, id: notification._id});
        break;
      case 'ticket':
        this.retriveTicketCount();
        DataService.updateData.next({target: 'ticket', type: notification.type, id: notification._id});
        break;
      case 'force_refresh':
        if(!DataService.nativeApp){
          console.log('Arrivato un force refresh da remoto');
          location.reload();
        }
        break;
      case 'batch_report':
        DataService.updateData.next({target: 'batch_report', type: notification.type, id: notification._id, id_participant: notification.id_participant});
        break;
    }
  }

  // Gestisce update di una organizzazione
  async manageOrganizationNotification(notification: any){
    switch (notification.type) {
      case 'update':
        console.log('UPDATE/CREATE organization');
        if(this.selOrganization._id === notification._id){
          await DataService.api.getOrganization(this.selOrganization.me._id, notification._id).then((res: any) => {
            this.selOrganization.setData(res);
            if(!this.selOrganization.login_view_demo) this.isDemo = this.resetDemo();
            DataService.updateData.next({target: 'organization', type: notification.type});
            ViewService.updateView.next();
          }).catch((err: any) => {
            console.error(err);
          });
        }
        break;
    }
  }

  // Gestisce update, cancellazione e inserimento di un partecipante

  static participant_updates: Map<string, boolean> = new Map<string, boolean>();
  static in_updating_participants: boolean;
  static last_update_participants: number = Date.now();
  static timeout_update_participants: any;
  static delta_update_participants: any = 2 * 1000;

  private async updateParticipants(){
    if(DataService.in_updating_participants){ // qualcuno ha già chiamato questo metodo e non vogliamo esecuzioni contemporanee
      // console.error('Non aggiorno participant perchè stanno già aggiornando');
      return;
    }
    DataService.in_updating_participants = true;
    try{
      if(DataService.last_update_participants > Date.now() - DataService.delta_update_participants){ // non aggiorniamo perchè è troppo presto;
        // console.error('Non aggiorno participant perchè troppo presto');
        if(!DataService.timeout_update_participants){
          // console.log('Avvio timeout prossimo update');
          DataService.timeout_update_participants = setTimeout(this.updateParticipants.bind(this), DataService.delta_update_participants);
        }
        DataService.in_updating_participants = false;
        return;
      }
      // console.log('Attivato update partecipant');
      if(DataService.timeout_update_participants){
        // console.log('Avevo timeout, lo pulisco');
        clearTimeout(DataService.timeout_update_participants);
        DataService.timeout_update_participants = undefined;
      }
      const to_update: string[] = [];
      for (const id of DataService.participant_updates.keys()) {
        DataService.participant_updates.delete(id); // lo toglie dalla lista di quelli da aggiornare;
        let participant: Partecipant;
        participant = this.selOrganization.participants.find((elem: Partecipant) => {
          return elem._id === id;
        });
        to_update.push(id);
        // await DataService.api.getParticipant(this.selOrganization.me._id, id).then((res: any) => {
        //   if(participant){
        //     participant.setData(res);
        //     DataService.updateData.next({target: 'participant', type: 'update', participant: participant});
        //     ViewService.updateView.next();
        //   }
        // }).catch((err: any) => {
        //   console.error(err);
        // });
      }
      const res: any = await DataService.api.getParticipantsBucket(this.selOrganization.me._id, to_update);
      for(const raw of res){
        const participant: Partecipant = this.selOrganization.participants.find((elem: Partecipant) => {
          return elem._id === raw._id;
        });
        if(participant){
          participant.setData(raw);
          DataService.updateData.next({target: 'participant', type: 'update', participant: participant});
        }
      }
      ViewService.updateView.next();
    }catch(err){
      console.error('Errore grave in updateParticipants');
    }
    // console.log('Finito update partecipant');
    DataService.in_updating_participants = false;
    DataService.last_update_participants = Date.now();
  }

  async manageParticipantNotification(notification: any){
    let participant: Partecipant;
    switch (notification.type) {
      case 'insert':
      case 'create':
        participant = this.selOrganization.participants.find((elem: Partecipant) => {
          return elem._id === notification._id;
        });
        await DataService.api.getParticipant(this.selOrganization.me._id, notification._id).then((res: any) => {
          if(participant){
            participant.setData(res);
            DataService.updateData.next({target: 'participant', type: 'update', participant: participant});
          }else{
            participant = new Partecipant(res);
            if(this.fleetSelectedInStorage){
              //se ho una flotta selezionata non voglio visualizzare il nuovo participant
              participant.$fleetShow = false;
            }
            this.selOrganization.addParticipant(participant);
            DataService.updateData.next({target: 'participant', type: 'create', participant: participant});
          }
          ViewService.updateView.next();
        }).catch((err: any) => {
          console.error(err);
        });
        break;
      case 'update':
        if(notification.isPosition){ // le posizioni le gestiamo per non fare troppi update contemporaneamente
          DataService.participant_updates.set(notification._id, true);
          this.updateParticipants();
        }else{ // tutti gli altri update di participant invece faccio la solita gestione
          participant = this.selOrganization.participants.find((elem: Partecipant) => {
            return elem._id === notification._id;
          });
          await DataService.api.getParticipant(this.selOrganization.me._id, notification._id).then((res: any) => {
            if(participant){
              participant.setData(res);
              DataService.updateData.next({target: 'participant', type: 'update', participant: participant});
              ViewService.updateView.next();
            }
          }).catch((err: any) => {
            console.error(err);
          });
        }
        break;
      case 'delete':
        this.selOrganization.removeParticipant(notification._id);
        DataService.updateData.next({target: 'participant', type: notification.type, id: notification._id});
        ViewService.updateView.next();
        break;
    }
  }

  // Gestisce update, cancellazione e inserimento di un poi
  private managePoiNotification(notification: any){
    switch (notification.type) {
      case 'create':
      case 'update':
        console.log('UPDATE/CREATE poi');
        let poi: Poi = this.pois.find((elem: Poi) => {
          return elem._id === notification._id;
        });
        DataService.api.getPoi(this.selOrganization.me._id, notification._id).then((res: any) => {
          if(poi){
            poi.setData(res);
          }else{
            poi = new Poi(res);
            this.pois = [...this.pois, poi]
          }
          DataService.updateData.next({target: 'poi', poi: poi});
          ViewService.updateView.next();
        }).catch((err: any) => {
          console.error(err);
        });
        break;
      case 'delete':
        for(let i=0; i<this.pois.length; i++){
          if(this.pois[i]._id === notification._id){
            this.pois.splice(i, 1);
            this.pois = [...this.pois]
            break;
          }
        }
        DataService.updateData.next({target: 'poi', type: notification.type, id: notification._id});
        ViewService.updateView.next();
        break;
    }
  }

  // Ritorna un report per un veicolo demo
  getDemoReport(participant: Partecipant, start: number, stop: number){
    if(!participant.isDemo) return [];
    let res: any = {
      data: [],
      report: {
        time_total: 60 * 60 * 1000,
        time_trip: 30 * 60 * 1000,
        total_distance: 10000,
        time_stop: 30 * 60 * 1000
      }
    };

    let totalPositions: any[] = DEMO_ROUTES['-1'];
    if(DEMO_ROUTES[participant._id]){
      totalPositions = DEMO_ROUTES[participant._id];
    }

    for(let pos of totalPositions){
      let raw: any = {
        _id: pos._id.toString(),
        idOrganization: participant.idOrganization,
        type: 2,
        position: {
          type: 'Point',
          coordinates: [pos.longitude, pos.latitude]
        },
        imei: participant.imei,
        timestamp_server: pos.delta_ms + start,
        timestamp_position: pos.delta_ms + start,
        position_type: 5,
        altitude: 0,
        angle: pos.angle || 0,
        satellites: 3,
        speed: pos.speed,
        total_odometer: pos.total_odometer,
        avl_id: 250,
        value: pos.position_type === 'moving' ? 1 : 0
      };
      if((res.data.length === 0 || res.data[res.data.length - 1].value !== raw.value)
        && (pos.delta_ms + start) <= stop){
        res.data.push(raw);
      }
    }
    if(res.data.length > 0){
      res.data.push({
        _id: 'end',
        idOrganization: participant.idOrganization,
        type: 2,
        position: {
          type: 'Point',
          coordinates: [res.data[res.data.length - 1].longitude, res.data[res.data.length - 1].latitude]
        },
        imei: participant.imei,
        timestamp_server: stop,
        timestamp_position: stop,
        position_type: 5,
        altitude: 0,
        angle: res.data[res.data.length - 1].angle || 0,
        satellites: 3,
        speed: res.data[res.data.length - 1].speed,
        total_odometer: res.data[res.data.length - 1].total_odometer,
        avl_id: 250,
        value: res.data[res.data.length - 1].value === 1 ? 0 : 1
      });
    }
    return res;
  }

  // Ritorna un report per un veicolo demo
  getDemoHistory(participant: Partecipant, start: number, stop: number){
    if(!participant.isDemo) return [];
    let totalPositions: any[] = DEMO_ROUTES['-1'];
    if(DEMO_ROUTES[participant._id]){
      totalPositions = DEMO_ROUTES[participant._id];
    }    let res: any = {data: [], has_more: false};
    for(let pos of totalPositions){
      let raw: any = {
        _id: pos._id.toString(),
        idOrganization: participant.idOrganization,
        type: 2,
        position: {
          type: 'Point',
          coordinates: [pos.longitude, pos.latitude]
        },
        imei: participant.imei,
        timestamp_server: pos.delta_ms + start,
        timestamp_position: pos.delta_ms + start,
        position_type: 4,
        altitude: 0,
        angle: pos.angle || 0,
        satellites: 3,
        speed: pos.speed,
        total_odometer: pos.total_odometer
      };
      res.data.push(raw);
    }
    return res;
  }

  // Salva un allarme su un veicolo demo
  saveDemoAlarm(type: string, target: Partecipant, values: Partecipant){
    switch (type) {
      case 'speed':
        target.speed_alarm_enable = values.speed_alarm_enable;
        target.speed_alarm_value = values.speed_alarm_value;
        target.speed_alarm_hour_start = values.speed_alarm_hour_start;
        target.speed_alarm_minute_start = values.speed_alarm_minute_start;
        target.speed_alarm_hour_stop = values.speed_alarm_hour_stop;
        target.speed_alarm_minute_stop = values.speed_alarm_minute_stop;
        target.speed_alarm_day = values.speed_alarm_day;
        target.speed_alarm_email = values.speed_alarm_email;
        target.speed_alarm_partecipant = values.speed_alarm_partecipant;
        break;
      case 'offline':
        target.offline_alarm_enable = values.offline_alarm_enable;
        target.offline_alarm_value = values.offline_alarm_value;
        target.offline_alarm_hour_start = values.offline_alarm_hour_start;
        target.offline_alarm_minute_start = values.offline_alarm_minute_start;
        target.offline_alarm_hour_stop = values.offline_alarm_hour_stop;
        target.offline_alarm_minute_stop = values.offline_alarm_minute_stop;
        target.offline_alarm_day = values.offline_alarm_day;
        target.offline_alarm_email = values.offline_alarm_email;
        target.offline_alarm_partecipant = values.offline_alarm_partecipant;
        break;
      case 'movement':
        target.movement_alarm_enable = values.movement_alarm_enable;
        target.movement_alarm_hour_start = values.movement_alarm_hour_start;
        target.movement_alarm_minute_start = values.movement_alarm_minute_start;
        target.movement_alarm_hour_stop = values.movement_alarm_hour_stop;
        target.movement_alarm_minute_stop = values.movement_alarm_minute_stop;
        target.movement_alarm_day = values.movement_alarm_day;
        target.movement_alarm_email = values.movement_alarm_email;
        target.movement_alarm_partecipant = values.movement_alarm_partecipant;
        break;
      case 'unplug':
        target.unplug_alarm_enable = values.unplug_alarm_enable;
        target.unplug_alarm_hour_start = values.unplug_alarm_hour_start;
        target.unplug_alarm_minute_start = values.unplug_alarm_minute_start;
        target.unplug_alarm_hour_stop = values.unplug_alarm_hour_stop;
        target.unplug_alarm_minute_stop = values.unplug_alarm_minute_stop;
        target.unplug_alarm_day = values.unplug_alarm_day;
        target.unplug_alarm_email = values.unplug_alarm_email;
        target.unplug_alarm_partecipant = values.unplug_alarm_partecipant;
        break;
      case 'parking':
        target.parking_alarm_enable = values.parking_alarm_enable;
        target.parking_alarm_hour_start = values.parking_alarm_hour_start;
        target.parking_alarm_minute_start = values.parking_alarm_minute_start;
        target.parking_alarm_hour_stop = values.parking_alarm_hour_stop;
        target.parking_alarm_minute_stop = values.parking_alarm_minute_stop;
        target.parking_alarm_day = values.parking_alarm_day;
        target.parking_alarm_email = values.parking_alarm_email;
        target.parking_alarm_partecipant = values.parking_alarm_partecipant;
        break;
      case 'input1':
        target.din1_alarm_enable = values.din1_alarm_enable;
        target.din1_alarm_value = values. din1_alarm_value;
        target.din1_alarm_hour_start = values.din1_alarm_hour_start;
        target.din1_alarm_minute_start = values.din1_alarm_minute_start;
        target.din1_alarm_hour_stop = values.din1_alarm_hour_stop;
        target.din1_alarm_minute_stop = values.din1_alarm_minute_stop;
        target.din1_alarm_day = values.din1_alarm_day;
        target.din1_alarm_email = values.din1_alarm_email;
        target.din1_alarm_partecipant = values.din1_alarm_partecipant;
        break;
    }
  }

  // Salva i dati di un veicolo demo in maniera fittizia
  saveDemoFields(target: Partecipant, name: string, label_number: number, odometer: number){
    target.name = name;
    target.label_number = label_number;
    target.total_odometer = odometer;
    DataService.updateData.next({target: 'participant', type: 'update', participant: target});
  }

  getPoi(id: string){
    return this.pois.find((elem: Poi) => {
      return elem._id === id;
    });
  }

  static clearActivationParams(){
    DataService.activationImei = undefined;
    DataService.activationSerial = undefined;
    DataService.activationModel = undefined;
    DataService.registrationEmail = undefined;
    AuthService.redirect = undefined
    AuthService.openModal = false;
  }

  // Invia un log ai server se avviene un errore in lancio di una pagina che non è gestito in altro modo
  static async manageStartupPageError(page: string, message: string, error: any){
    try{
      console.error(message || 'manageStartupPageError', error);
      await DataService.isInitialized();
      await DataService.api.sendLog(2, page, message || undefined,error?.message || undefined, DataService.nativeApp, DataService.version, DataService.idOrganization);
    }catch(err){
      console.error('Impossibile inviare log di errore in startup di pagina', err);
    }
  }

  async uploadMedia(name: string, file: any, metadata?: any){
    if(name && file){
      try{
        const url: string = `temp/${Date.now()}_${Math.floor(Math.random() * 10000) + 1}/${name}`
        const ref: any = DataService.firebase_storage.child(url);
        await ref.put(file, metadata);
        return url;
      }catch(err){
        console.error(err);
        return undefined
      }
    }
  }

  async getMediaInfo(name: string): Promise<{url: string, metadata: any}>{
    if(name){
      try{
        const ref: any = DataService.firebase_storage.child(name);
        let results: any[] = await Promise.all([
          ref.getDownloadURL(),
          ref.getMetadata()
        ]);
        let result: any = {
          url: results[0],
          metadata: results[1]
        };
        ViewService.updateView.next();
        return result;
      }catch(err){
        console.error(err);
        return undefined
      }
    }
  }


  getTicket(id: string){
    return this.tickets.find((ticket: Ticket) => {
      return ticket._id === id;
    });
  }

  findParticipantByImei(imei: string){
    return this.selOrganization.participants?.find((elem)=>elem.imei === imei)
  }

  getTarget(id: string): DeadlineTarget{
    return this.deadlineTarget.find(elem => elem._id === id)
  }

}
