import { Component, OnInit } from '@angular/core';
import {Input} from "@angular/core";
import {Partecipant} from "src/app/classes/common/partecipant";
import {ChangeDetectorRef} from "@angular/core";
import {PopoverController} from "@ionic/angular";
import {ViewService} from "src/app/services/common/view.service";

@Component({
  selector: 'app-popover-select-multiple-car',
  templateUrl: './popover-select-multiple-car.component.html',
  styleUrls: ['./popover-select-multiple-car.component.scss'],
})
export class PopoverSelectMultipleCarComponent implements OnInit {

  @Input('cars') cars: Partecipant[];
  @Input() selectAll: boolean = false;


  searchQuery: string;

  listener = {
    view: null,
  };

  constructor(
    private cd: ChangeDetectorRef,
    private popoverCtrl: PopoverController,
    public view: ViewService,
  ) {}


  ngOnInit() {
  }


  ionViewWillEnter() {
  }


  ionViewDidEnter() {
    this.listenerView();
  }


  ionViewWillLeave() {
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
  }

  ionViewDidLeave() {
  }


  ngOnDestroy() {
  }


  async dismiss(data: any) {
    await this.popoverCtrl.dismiss(data)
  }

  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.cd.markForCheck();

      setTimeout(() => {
        this.cd.markForCheck();
      }, 250);

    });
  }


}
