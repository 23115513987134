import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mergeMessages'
})
export class MergeMessagesPipe implements PipeTransform {

  transform(messages: any, changed?: number): any[] {
    if (!messages || typeof messages !== 'object') {
      return [];
    }

    /** Lista di tipi messaggio, aggiungo anche un campo message_type */
    const webMessages = Array.isArray(messages.web) ? messages.web : [];
    const whatsappMessages = Array.isArray(messages.whatsapp) ? messages.whatsapp : [];

    const mergedArray = [...webMessages, ...whatsappMessages];
    console.log(mergedArray)
    return mergedArray
      .filter((message) => message && message.dateSent_ms) // Rimuovi gli elementi senza data valida
      .sort((a, b) => {
        const dateA = new Date(a.dateSent_ms);
        const dateB = new Date(b.dateSent_ms);

        if (!isNaN(dateA.getTime()) && !isNaN(dateB.getTime())) {
          return dateA.getTime() - dateB.getTime();
        } else if (!isNaN(dateA.getTime())) {
          return -1;
        } else if (!isNaN(dateB.getTime())) {
          return 1;
        } else {
          return 0;
        }
      });
  }
}
