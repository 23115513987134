import {Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter, Output} from '@angular/core';
import {Input} from "@angular/core";
import {
  ViewService
} from "../../../services/common/view.service";

@Component({
  selector: 'app-chipbar',
  templateUrl: './chipbar.component.html',
  styleUrls: ['./chipbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChipbarComponent implements OnInit {

  @Input() chips: any[] = [];
  /**
   * Optional (see component for default values)
   * - defines the name of some chip.properties
   * - property names used on the front-end are required;
   *   the others can be omitted
   */
  @Input() chipPropNames = {
    lbl: "lbl",
    icon: "icon",
    selected: "selected",
  };
  // @Input() layout: Layout;
  @Input() multiSelect: boolean;
  @Input() showArrows: boolean;
  @Input() update: number;
  @Output() chipSelect = new EventEmitter<any>();

  chipbar: Element;
  listener = {
    view: null
  };

  constructor(
    private cd: ChangeDetectorRef,
    public view: ViewService,
  ) {
  }

  ngOnInit() {
    this.listenerView();
    this.getDOMelements()
  }

  ngOnDestroy() {
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
  }

  // misc

  chipSelectFunc(chip) {
    const { selected } = this.chipPropNames;

    // WHOLE selection process can be done WAY better
    if (this.multiSelect) {
      chip[selected] = !chip[selected];
    } else {
      if (chip[selected]) {
        chip[selected] = false;
        this.chips[0][selected] = true;
      } else {
        this.chips.forEach((chip) => chip[selected] = false);
        chip[selected] = true;
      }
    }
    ViewService.updateView.next();
    this.chipSelect.emit(chip)
  }

  getDOMelements() {
    this.chipbar = document.getElementById("chipbar");
  }

  scrollChipbar(toLeft?: boolean) {
    if (toLeft) {
      this.chipbar.scrollLeft -= 350;
      // this.chipbar2.scrollLeft -= 350;
    } else {
      this.chipbar.scrollLeft += 350;
      // this.chipbar2.scrollLeft += 350;
    }
    // View.updateView.next();
  }

  // listener

  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.cd.markForCheck();
    });
  }


}
