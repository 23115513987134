export const MARKER_WITH_DIRECTION = {
  1: {
    size: {
      x: 16,
      y: 19.1,
      percentage: 0.2,
      font: 6
    },
    anchor: {
      x: 8,
      y: 19.1
    },
    label: {
      fattore_correzione: 35
    }
  },
  2: {
    size: {
      x: 26,
      y: 31.1,
      percentage: 0.325,
      font: 8
    },
    anchor: {
      x: 13,
      y: 31.1
    },
    label: {
      fattore_correzione: 23
    }
  },
  3: {
    size: {
      x: 36,
      y: 43.1,
      percentage: 0.45,
      font: 10
    },
    anchor: {
      x: 18,
      y: 43.1
    },
    label: {
      fattore_correzione: 12
    }
  },
  4: {
    size: {
      x: 46,
      y: 55,
      percentage: 0.575,
      font: 12
    },
    anchor: {
      x: 18,
      y: 55
    },
    label: {
      fattore_correzione: 0
    }
  },
  5: {
    size: {
      x: 56,
      y: 67,
      percentage: 0.7,
      font: 14
    },
    anchor: {
      x: 28,
      y: 67
    },
    label: {
      fattore_correzione: -13
    }
  },
  6: {
    size: {
      x: 66,
      y: 79,
      percentage: 0.825,
      font: 16
    },
    anchor: {
      x: 33,
      y: 79
    },
    label: {
      fattore_correzione: -23
    }
  }
};
