import {
  Injectable, Pipe,
  PipeTransform
} from '@angular/core';
import {
  Organization
} from "../../classes/common/organization";
import {
  Partecipant
} from "../../classes/common/partecipant";

@Pipe({
  name: 'participantList'
})
@Injectable()
export class ParticipantListPipe implements PipeTransform {
  transform(idList: string[], limit: number, organization: Organization, noElementsLabel?: string, meLabel?: string): string {
    let res: string = ``;
    if(!idList || !limit || idList.length <= 0) return noElementsLabel || "";
    for(let i=0; i<idList.length; i++){
      if(i < limit){
        if(meLabel && idList[i] === organization.me._id){
          res =  (meLabel) + `<br>` + res;
        }else{
          let p: Partecipant = organization.getParticipant(idList[i]);
          if(p){
            res +=  (p.name || p.email) + `<br>`;
          }else{
            limit++;
          }
        }
      }else{
        res += `<label class="text-muted">+ ${idList.length - limit}</label>`;
        break;
      }
    }
    return res;
  }
}
