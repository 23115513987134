import {
  Injectable, Pipe,
  PipeTransform
} from '@angular/core';
@Pipe({
  name: 'duration'
})
@Injectable()
export class DurationPipe implements PipeTransform {
  transform(seconds: number, type?: string, label_day?: string, label_days?: string, label_hours?: string, label_min?: string, label_seconds?: string, changed?: number): string {
    seconds = parseInt((seconds / 1000).toString());
    if(type === 'delta g h m'){
      seconds = parseInt("" + (Date.now() / 1000)) - seconds;

      if(!seconds || seconds === 0) return "0 s  ";

      let m: number = parseInt("" + (seconds/60));
      let h: number = parseInt("" + (m/60));
      m = m - (h * 60);
      let g: number = parseInt("" + (h / 24));
      h = h - (g * 24);

      let result: string = "";

      if(m > 0){
        result = m + " " + (label_min || "m") + "  " + result;
      }
      if(h > 0){
        result = h + " " + (label_hours || "h") + "  " + result;
      }
      if(g > 0){
        result = g + " " + (label_day || "d") + "  " + result;
      }
      if(!result){
        result = seconds + " s";
      }

      return result;
    } else if('g h m || m s'){
      if(!seconds || seconds === 0) return "0 s";

      let s: number = seconds;
      let m: number = parseInt("" + (seconds/60));
      s = s - (m * 60);
      let h: number = parseInt("" + (m/60));
      m = m - (h * 60);
      let g: number = parseInt("" + (h / 24));
      h = h - (g * 24);

      let result: string = "";

      if(g > 0 || h > 0){
        if(m > 0){
          result = m + " " + (label_min || "m") + "  " + result;
        }
        if(h > 0){
          result = h + " " + (label_hours || "h") + "  " + result;
        }
        if(g > 0){
          result = g + " " + (label_day || "d") + "  " + result;
        }
      }else{
        if(s > 0){
          result = s + " " + (label_seconds || "s") + "  " + result;
        }
        if(m > 0){
          result = m + " " + (label_min || "m") + "  " + result;
        }
      }

      return result;
    } else if(type == 'g min s'){
      if(!seconds || seconds === 0) return "0 s  ";

      let m: number = parseInt("" + (seconds/60));
      let s: number = seconds - (m * 60);
      let h: number = parseInt("" + (m/60));
      m = m - (h * 60);
      let g: number = parseInt("" + (h / 24));
      h = h - (g * 24);

      let result: string = "";

      if(s > 0){
        result += s + " " + (label_seconds || "s")  + "  ";
      }
      if(m > 0){
        result = m + " " + (label_min || "m") + "  " + result;
      }
      if(h > 0){
        result = h + " " + (label_hours || "h") + "  " + result;
      }
      if(g > 0){
        result = g + " " + (label_days || "d") + "  " + result;
      }

      return result;

    }else if(type == 'min s'){
      if(!seconds || seconds === 0) return "0 s";

      let m: number = parseInt("" + (seconds/60));
      let s: number = seconds - (m * 60);
      let h: number = parseInt("" + (m/60));
      m = m - (h * 60);
      let g: number = parseInt("" + (h / 24));
      h = h - (g * 24);

      let result: string = "";

      if(s > 0){
        result += s + " " +  (label_seconds || "s");
      }
      if(m > 0){
        result = m + " " + (label_min || "min") + " " + result;
      }
      if(h > 0){
        result = h + " " + (label_hours || "h") + " " + result;
      }
      if(g > 0){
        result = g + " " + (label_days || "d") + " " + result;
      }
      return result;

    } else if(type == 'g hh:mm:ss'){

      if(!seconds || seconds === 0) return "00:00:00";

      let m: number = parseInt("" + (seconds/60));
      let s: number = seconds - (m * 60);
      let h: number = parseInt("" + (m/60));
      m = m - (h * 60);
      let g: number = parseInt("" + (h / 24));
      h = h - (g * 24);

      let result: string = "";

      if(g > 0){
        if(g > 1){
          result += g + " " + (label_days || "d") + " ";
        }else{
          result += g + " " + (label_day || "d") + " ";
        }
      }
      if(h > 0){
        if(h > 9){
          result += h + ":";
        }else{
          result += "0" + h + ":";
        }
      }else{
        result += "00:";
      }
      if(m > 0){
        if(m > 9){
          result += m + ":";
        }else{
          result += "0" + m + ":";
        }
      }else{
        result += "00:";
      }
      if(s > 0){
        if(s > 9){
          result += s;
        }else{
          result += "0" + s;
        }
      }else{
        result += "00";
      }
      return result;
    }else{
      if(!seconds) return "00:00";
      let min = Math.floor(seconds/60);
      let result: string = "";
      result = "" + min;
      if(min < 10){
        result = "0" + min;
      }
      result += ":";
      let s = seconds - (min * 60);
      if(s < 10){
        result += "0" + s;
      }else{
        result += s;
      }
      return result;
    }
  }
}
