import {Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Input} from '@angular/core';
import {PopoverController} from "@ionic/angular";
import {
  ViewService
} from "../../../services/common/view.service";
import {
  DataService
} from "../../../services/common/data.service";
import {Poi} from "../../../classes/common/poi";

@Component({
  selector: 'app-popover-select-poi',
  templateUrl: './popover-select-poi.component.html',
  styleUrls: ['./popover-select-poi.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopoverSelectPoiComponent implements OnInit {

  @Input() poi: [];
  searchQuery: string;

  // listener
  listener = {
    view: null,
  };

  constructor(
    private cd: ChangeDetectorRef,
    private popoverCtrl: PopoverController,
    public view: ViewService,
    public data: DataService
  ) {}

  ngOnInit() {
  }

  ionViewWillEnter() {
  }

  ionViewDidEnter() {
    this.listenerView();
  }

  ionViewWillLeave() {
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
  }

  ionViewDidLeave() {
  }

  ngOnDestroy() {
  }

  async dismiss(poi?: Poi) {
    await this.popoverCtrl.dismiss({poi: poi});
  }

  // listener
  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.cd.markForCheck();
      setTimeout(() => {
        this.cd.markForCheck();
      }, 250);
    });
  }







}
