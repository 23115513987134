import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { PopoverSelectPoiComponent } from '../popover-select-poi/popover-select-poi.component';
import {
  ViewService
} from "../../../services/common/view.service";

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectComponent implements OnInit {

  // component args
  @Input() placeholder = "SELECT.seleziona";
  @Output() select = new EventEmitter<any>();
  @Input() val;
  @Input() valObj: string;

  // listener
  listener = {
    view: null,
  };

  constructor(
    private cd: ChangeDetectorRef,
    private popoverCtrl: PopoverController,
    public view: ViewService,
  ) {}

  ngOnInit() {
    this.listenerView();
  }

  ngOnDestroy() {
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
  }

  switchSelectWindow(event) {
    switch (this.valObj) {
      case "poi":
        this.popoverSelectPoi(event);
        break;

      default:
        break;
    }
  }

  // listener
  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.cd.markForCheck();
      setTimeout(() => {
        this.cd.markForCheck();
      }, 250);
    });
  }

  // popover
  async popoverSelectPoi(event) {
    const popover = await this.popoverCtrl.create({
      component: PopoverSelectPoiComponent,
      cssClass: "popover_list",
      event: event,
    });
    popover.onDidDismiss().then((data: any) => {
      if(data && data.data && data.data.poi){
        this.select.emit(data.data.poi);
      }
    });
    await popover.present();
  }
}
