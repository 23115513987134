import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {Storage} from "@ionic/storage";

@Injectable({
  providedIn: 'root'
})

export class GoogleMapsService {

  static google: any;
  static overlappingMarkerSpiderfier: any;

  divMap: any;
  map: any;
  currentMap: string;
  private oms: any;
  private trafficLayer: any;

  private listeners: any = {};
  private circles: any = {};
  private markers: any = {};
  private controls: any = {};
  private polylines: any = {};
  private popups: any = {};
  marker_opts: {marker_size: number, label_size: number} = {
    marker_size: 4,
    label_size: 4
  }

  private mainMapChangeEnabled: any = true;

  onMapClosed = new Subject<any>();
  onMapClosed$ = this.onMapClosed.asObservable();

  static googleReady = new Subject<any>();
  static googleReady$ = GoogleMapsService.googleReady.asObservable();

  constructor(
    private storage: Storage
  ) {
  }

  initialization(google: any, overlappingMarkerSpiderfier: any){
    this.storage.get('markerOpts').then((marker_opts: any) => {
      if(marker_opts){
        this.marker_opts = marker_opts;
      }
    });
    GoogleMapsService.google = google;
    GoogleMapsService.overlappingMarkerSpiderfier = overlappingMarkerSpiderfier;
    GoogleMapsService.googleReady.next();
  }

  isReady(){
    return new Promise((resolve) => {
      if(GoogleMapsService.google) return resolve(null)
      let sub: any = GoogleMapsService.googleReady$.subscribe(async () => {
        sub.unsubscribe();
        return resolve(null)
      });
    });
  }

  getMap(){
    if(!this.map) {
      this.divMap = document.createElement("div");
      this.divMap.style.width = "100%";
      this.divMap.style.height = "100%";
      this.map = new GoogleMapsService.google.maps.Map(this.divMap, {
        center: {lat: 41.8897, lng: 12.4935},
        zoom: 7,
        fullscreenControl: false,
        zoomControlOptions: {
          position: GoogleMapsService.google.maps.ControlPosition.LEFT_BOTTOM
        },
        streetViewControl: false,
        streetViewControlOptions: {
          position: GoogleMapsService.google.maps.ControlPosition.LEFT_BOTTOM
        },
        mapTypeControl: false,
      });
    }
    if(!this.oms){
      this.oms = new GoogleMapsService.overlappingMarkerSpiderfier(this.map, {
        markersWontMove: true,
        markersWontHide: true,
        basicFormatEvents: true
      });
    }
    return this.map;
  }

  getDivMap(){
    return this.divMap;
  }

  setCurrentMap(currentMap: string){
    this.currentMap = currentMap;
  }


  addPolyline(polyline: any, map: string){
    let polylineId: string = polyline.get("id");
    if(!polylineId) return console.error("No polylineId _id, polyline not added in map");
    if(!this.polylines.hasOwnProperty(map)){
      this.polylines[map] = {};
    }
    if(this.polylines[map][polylineId]) this.polylines[map][polylineId].setMap(null);
    this.polylines[map][polylineId] = polyline;
    polyline.setMap(this.map);
  }

  removePolyline(polyline: any, map: string){
    let polylineId: string = polyline.get("id");
    if(!polylineId) return console.error("No polyline _id, polyline not removed in map");
    if(!this.polylines.hasOwnProperty(map)){
      this.polylines[map] = {};
    }
    if(this.polylines[map].hasOwnProperty(polylineId)){
      delete this.polylines[map][polylineId];
    }
    polyline.setMap(null);
  }

  addPopup(popup: any, map: string){
    let popupId: string = popup.id;
    if(!popupId) return console.error("No popup id, popup not added in map");
    if(!this.popups.hasOwnProperty(map)){
      this.popups[map] = {};
    }
    if(this.popups[map][popupId]) this.popups[map][popupId].setMap(null);
    this.popups[map][popupId] = popup;
    popup.setMap(this.map);
  }

  removePopup(popup: any, map: string){
    let popupId: string = popup.id;
    if(!popupId) return console.error("No popup id, popup not remove in map");
    if(!this.popups.hasOwnProperty(map)){
      this.popups[map] = {};
    }
    if(this.popups[map].hasOwnProperty(popupId)){
      delete this.popups[map][popupId];
    }
    popup.setMap(null);
  }




  addCircle(circle: any, map: string){
    let circleId: string = circle.get("id");
    if(!circleId) return console.error("No circle _id, circle not added in map");
    if(!this.circles.hasOwnProperty(map)){
      this.circles[map] = {};
    }
    if(this.circles[map][circleId]) this.circles[map][circleId].setMap(null);
    this.circles[map][circleId] = circle;
    if(this.getMainMapUpdateState() || map !== "main"){
      circle.setMap(this.map);
    }
  }

  removeCircle(circle: any, map: string){
    let circleId: string = circle.get("id");
    if(!circleId) return console.error("No circle _id, circle not removed in map");
    if(!this.circles.hasOwnProperty(map)){
      this.circles[map] = {};
    }
    if(this.circles[map].hasOwnProperty(circleId)){
      delete this.circles[map][circleId];
    }
    circle.setMap(null);
  }

  addMarker(marker: any, map: string, spiderClick: boolean){
    let markerId: string = marker.id;
    if(!markerId) return console.error("No marker _id, marker not added in map", markerId);
    if(!this.markers.hasOwnProperty(map)){
      this.markers[map] = {};
    }
    if(this.markers[map][markerId]) this.markers[map][markerId].setMap(null);
    this.markers[map][markerId] = marker;
    if(this.getMainMapUpdateState() || map !== "main"){
      if(spiderClick){
        this.oms.addMarker(marker);
      }else{
        marker.setMap(this.map);
      }
    }
  }

  getMainMapUpdateState(){
    return this.mainMapChangeEnabled;
  }

  emitCloseMap(){
    this.onMapClosed.next();
  }

  addListener(listenerRef: any, map: string){
    if(!this.listeners.hasOwnProperty(map)){
      this.listeners[map] = [];
    }
    this.listeners[map].push(listenerRef);
  }


  removeListeners(map?: string){
    if(!map){
      for(let key in this.listeners){
        if(Array.isArray(this.listeners[key])){
          for(let listener of this.listeners[key]){
            GoogleMapsService.google.maps.event.removeListener(listener);
          }
          this.listeners[key] = [];
        }
      }
    }else{
      if(this.listeners[map] && Array.isArray(this.listeners[map])){
        for(let listener of this.listeners[map]){
          GoogleMapsService.google.maps.event.removeListener(listener);
        }
        this.listeners[map] = [];
      }
    }
  }

  removeCircles(map?: string){
    if(!map){
      for(let key in this.circles){
        if(this.circles[key]){
          for(let circleId in this.circles[key]){
            this.circles[key][circleId].setMap(null);
          }
        }
      }
    }else{
      if(this.circles[map]){
        for(let circleId in this.circles[map]){
          this.circles[map][circleId].setMap(null);
        }
      }
    }
  }

  removeMarkers(map?: string){
    if(!map){
      for(let key in this.markers){
        if(this.markers[key]){
          for(let markerId in this.markers[key]){
            this.markers[key][markerId].setMap(null);
          }
        }
      }
    }else{
      if(this.markers[map]){
        for(let markerId in this.markers[map]){
          this.markers[map][markerId].setMap(null);
        }
      }
    }
  }

  removePolylines(map?: string){
    if(!map){
      for(let key in this.polylines){
        if(this.polylines[key]){
          for(let polylineId in this.polylines[key]){
            this.polylines[key][polylineId].setMap(null);
          }
        }
      }
    }else{
      if(this.polylines[map]){
        for(let polylineId in this.polylines[map]){
          this.polylines[map][polylineId].setMap(null);
        }
      }
    }
  }

  removePopups(map?: string){
    if(!map){
      for(let key in this.popups){
        if(this.popups[key]){
          for(let popupId in this.popups[key]){
            this.popups[key][popupId].setMap(null);
          }
        }
      }
    }else{
      if(this.popups[map]){
        for(let popupId in this.popups[map]){
          this.popups[map][popupId].setMap(null);
        }
      }
    }
  }

  clearMap(){
    this.disableInfoTraffic();
    this.removeListeners();
    this.removeCircles();
    this.removeMarkers();
    this.removePolylines();
    this.removePopups();
    this.removeUIControls();
    if(this.map){
      this.map.setMapTypeId(GoogleMapsService.google.maps.MapTypeId.ROADMAP);
      let streetView = this.map.getStreetView();
      if(streetView) streetView.setVisible(false);
    }
    // if(this.directionsRenderer) this.directionsRenderer.setMap(null);
    this.listeners = {};
    this.circles = {};
    this.markers = {};
    this.polylines = {};
    this.popups = {};
    // this.disableMainMapUpdate();
  }

  enableMainMapUpdate(){
    this.mainMapChangeEnabled = true;
  }

  enableInfoTraffic(){
    if(!this.trafficLayer){
      this.trafficLayer = new GoogleMapsService.google.maps.TrafficLayer();
    }
    this.trafficLayer.setMap(this.map);
  }

  disableInfoTraffic(){
    if(this.trafficLayer) this.trafficLayer.setMap(null);
  }

  removeUIControls(){
    if(this.map){
      for(let control of this.map.controls){
        if(control !== undefined){
          try{
            control.clear();
          }catch(err){
            console.error(err);
          }
        }
      }
      this.map.setOptions({
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false
      });
    }
  }

  disableMainMapUpdate(){
    this.mainMapChangeEnabled = false;
  }




  initMap(){
    if(this.divMap){
      this.divMap = document.createElement("div");
      this.divMap.style.width = "100%";
      this.divMap.style.height = "100%";
    }
    if(!this.map){
      this.map = new GoogleMapsService.google.maps.Map(this.divMap, {
        center: {lat: 41.8897, lng: 12.4935},
        zoom: 7,
        fullscreenControl: false,
        zoomControlOptions: {
          position: GoogleMapsService.google.maps.ControlPosition.LEFT_BOTTOM
        },
        streetViewControl: true,
        streetViewControlOptions: {
          position: GoogleMapsService.google.maps.ControlPosition.LEFT_BOTTOM
        },
        mapTypeControl: false,
      });
      // if(this.dataService.users.length <= 0){
      //   this.tdweb.onFinishLoadUsersLocations$.subscribe(() => {
      //     this.setLiveLocations();
      //   });
      // }else{
      //   this.setLiveLocations();
      // }
    }
  }

  private setLiveLocations(){
    // for(let user of this.dataService.users){
    //   if(user.location && user.location.content){
    //     this.insertMarker(user);
    //   }
    // }
    this.fitCenter();
  }

  // private async insertMarker(user: User){
  //   if(this.map){
  //     let markerUrl: string = user.markerLive;
  //     if(((user.location.date + user.location.content.live_period) * 1000) < (new Date()).getTime()){
  //       markerUrl = user.markerNoLive;
  //     }
  //     if(this.markers.hasOwnProperty(user.location.sender_user_id)){
  //       this.markers[user.id].setIcon({
  //         url: markerUrl || 'assets/img/marker_green.png',
  //         size: new GoogleMapsService.google.maps.Size(46, 55),
  //         scaledSize: new GoogleMapsService.google.maps.Size(46, 55),
  //         origin: new GoogleMapsService.google.maps.Point(0, 0),
  //         anchor: new GoogleMapsService.google.maps.Point(18, 55)
  //       });
  //       this.markers[user.location.sender_user_id].setPosition({lat: user.location.content.latitude, lng: user.location.content.longitude});
  //     }else{
  //       const marker = new GoogleMapsService.google.maps.Marker({
  //         icon: {
  //           url: markerUrl || 'assets/img/marker-green.png',
  //           size: new GoogleMapsService.google.maps.Size(46, 55),
  //           scaledSize: new GoogleMapsService.google.maps.Size(46, 55),
  //           origin: new GoogleMapsService.google.maps.Point(0, 0),
  //           anchor: new GoogleMapsService.google.maps.Point(18, 55)
  //         },
  //         id: user.location.sender_user_id,
  //         position: {lat: user.location.content.latitude, lng: user.location.content.longitude}
  //       });
  //       marker.setMap(this.map);
  //       this.markers[user.location.sender_user_id] = marker;
  //     }
  //   }
  // }

  updateUserMarker(){

  }

  insertUser(){
    const marker = new GoogleMapsService.google.maps.Marker({
      id: "indicatorMarker",
      position: {lat: 41.8897, lng: 12.4935}
    });
    marker.setMap(this.map);
  }

  /*addMarker(marker: any, map: string){
    let markerId: string = marker.get("id");
    if(!markerId) return console.error("No marker _id, marker not added in map");
    if(!this.markers.hasOwnProperty(map)){
      this.markers[map] = {};
    }
    if(this.markers[map][markerId]) this.markers[map][markerId].setMap(null);
    marker.setMap(this.map);
    this.markers[map][markerId] = marker;
  }*/

  removeMarker(marker: any, map: string){
    let markerId: string = marker.get("id");
    if(!markerId) return console.error("No marker _id, marker not removed in map");
    if(!this.markers.hasOwnProperty(map)){
      this.markers[map] = {};
    }
    if(this.markers[map].hasOwnProperty(markerId)){
      delete this.markers[map][markerId];
    }
    marker.setMap(null);
  }

  fitCenter(){
    let bounds: any = new GoogleMapsService.google.maps.LatLngBounds();
    let haveMarkers: number = 0;
    for(let key in this.markers){
      bounds.extend(this.markers[key].getPosition());
      haveMarkers++;
    }
    if(haveMarkers > 0){
      this.map.fitBounds(bounds);
      this.map.panToBounds(bounds);
      if(this.map.getZoom() > 18){
        this.map.setZoom(18);
      }
    }
  }
}
