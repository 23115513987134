// import {firebaseConfig} from "./firebase/environment-firebase-dev";
//
// export const environment = {
//   app_name: "Flotte In Cloud Dev",
//   production: false,
//
//   version: "1.0.2",
//   platform_type: 2,
//
//   baseUrl: "https://default-dev-2jghk7fwgq-ew.a.run.app/",
//   communicationBaseUrl: "https://comunication-dev-2jghk7fwgq-ew.a.run.app/",
//   baseUrl_nativeApp: "", // solo per app nativa prod
//   communicationBaseUrl_nativeApp: "", // solo per app nativa prod
//
//   originalUrl: new URL(window.location.href),
//
//   desktopUrl: "", //Da valorizzare solo in prod
//   mobileUrl: "", //da valorizzare solo in prod
//
//   firebaseConfig: firebaseConfig
// };


import {firebaseConfig} from "./firebase/environment-firebase-prod";

export const environment = {
  app_name: "Flotta In Cloud Dev",
  production: true,
  version: "1.0.9",
  platform_type: 2,

  baseUrl: "https://default-dev-2jghk7fwgq-ew.a.run.app/",
  communicationBaseUrl: "https://api.balin.app/",
  originalUrl: new URL(window.location.href),


  baseUrl_nativeApp: "", // solo per app nativa prod
  communicationBaseUrl_nativeApp: "", // solo per app nativa prod

  mobileUrl: "",
  desktopUrl: "", //Da valorizzare solo in prod

  firebaseConfig: firebaseConfig
};
