

export class ShopProducts{
  custom_priceId_list: PriceList[] = []
  description: object;
  type: number;
  url_image: string;
  can_sell: string;
  long_description: string;
  original_price: string;
  price_id: string;
  see_more_url: string;
  _price: any;
  _organization_can_sell: boolean;
  _is_displayed: boolean = true;
  _has_custom_price: boolean = false;
  _default_price: any;
  _description: string;
  _long_description: string;

  private $lang: string = 'it'
  constructor(data?: any,  options? : {lang?: string }){
    if(data){
      this.setData(data, options)
    }
  }

  setData(data: any, options?: {lang?: string}){
    if(options){
      if(options.lang) this.$lang = options.lang
    }
    this.description = data.description;
    if(data.description){
      //aggiungo un controllo sul type of della descrizione per assicurarimi di visualizzare il dato corretto in base alla versione di general parameters
      if((typeof data.description) === 'string'){
        this._description = data.description
      }else if((typeof data.description) === 'object'){
        this._description = data.description[this.$lang ? this.$lang : 'en']
      }
    }
    this.type = data.type;
    this.url_image = data.url_image;
    this.can_sell = data.can_sell
    this.long_description = data.long_description;
    if(data.long_description){
      if((typeof data.long_description) === 'string'){
        this._long_description = data.long_description
      }else if((typeof data.long_description) === 'object'){
        this._long_description = data.long_description[this.$lang ? this.$lang : 'en']
      }
    }
    this.original_price = data.original_price;
    this.price_id = data.price_id;
    this.see_more_url = data.see_more_url;
    this._price = data.price;
    this._organization_can_sell = data.organization_can_sell;
    this._is_displayed = data.can_sell;
    this._has_custom_price = data.has_custom_price;
    this._default_price = data.default_price;

    if(data.custom_priceId_list){
      for(let price of data.custom_priceId_list){
        this.custom_priceId_list.push(new PriceList(price))
      }
    }
  }
}

export class PriceList{
  price: number;
  price_id: string;
  is_default: boolean;

  constructor(data?: any){
    if(data){
      this.setData(data)
    }
  }

  setData(data:any){
    this.price = data.price;
    this.price_id = data.price_id;
    this.is_default = data.is_default;
  }
}
