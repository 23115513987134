import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import {
  ViewService
} from "../../../services/common/view.service";


// type Layout =
//   "chip-lbl" |
//   "item";


@Component({
  selector: 'app-select-poi',
  templateUrl: './select-poi.component.html',
  styleUrls: ['./select-poi.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectPoiComponent implements OnInit {

  // component args
  @Input() opts: any[] = [];
  @Input() enableSearch: boolean;

  query: string;

  listener = {
    view: null,
  };

  constructor(
    private cd: ChangeDetectorRef,
    private popoverCtrl: PopoverController,
    public view: ViewService,
  ) {}

  ngOnInit() {}

  ionViewWillEnter() {}

  ionViewDidEnter() {
    this.listenerView();
  }

  ionViewWillLeave() {
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
  }

  ionViewDidLeave() {}

  async dismiss(data: any) {
    await this.popoverCtrl.dismiss({
      selectedElem: data.fullElem,
    }, "selection")
  }

  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.cd.markForCheck();
      setTimeout(() => {
        this.cd.markForCheck();
      }, 250);
    });
  }

}
