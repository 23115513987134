import { Injectable } from '@angular/core';
import {Subject} from "rxjs";
import {ViewService} from "./view.service";
import {Storage} from "@ionic/storage-angular";
import {ApiService} from "./api.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  static firebase: any;
  static isLogged: boolean;

  static authStateChange = new Subject<any>();
  static authStateChange$ = AuthService.authStateChange.asObservable();

  static hasLogged = new Subject<any>();
  static hasLogged$ = AuthService.hasLogged.asObservable()

  static forcedEmail: string;
  static forcedToken: string;
  static redirect: string;
  static vehicle_id: string;
  static openModal: boolean;

  /** Variabili per token **/
  static authTokenInUrl: string; // token letto in url
  static token: any; // classe token in backend
  isForced: boolean;
  static api: ApiService;
  static firebase_listener: any;

  constructor(
    private storage: Storage
  ) { }

  // Inizializza firebase per quella specifica app e attiva il listener di cambio di stato
  initialization(firebase: any, api: ApiService){
    AuthService.firebase = firebase;
    AuthService.api = api;

    if(AuthService.forcedEmail && AuthService.forcedToken){ // Email forzate
      this.isForced = true;
      ViewService.updateView.next();
      AuthService.isLogged = true;
      AuthService.authStateChange.next(true);
    }else if(AuthService.authTokenInUrl){ // Auth token di accesso temporaneo
      console.log('Auth con token')
      AuthService.isLogged = true;
      AuthService.authStateChange.next(true);
    }else{
      console.log('Auth con firebase')
      AuthService.activateFirebaseListener();
    }
  }

  static activateFirebaseListener(){
    AuthService.firebase_listener = AuthService.firebase.auth().onAuthStateChanged(async (user: any) => { // firebase classico
      if(user){
        AuthService.isLogged = true;
        AuthService.authStateChange.next(true);
      }else{
        AuthService.isLogged = false;
        AuthService.authStateChange.next(false);
      }
    });
  }

  //Dice o fa attendere fin quando non sa se siam loggati oppure no
  static isReady(){
    return new Promise((resolve, reject) => {
      if(AuthService.isLogged !== undefined) return resolve(AuthService.isLogged)
      let sub: any = AuthService.authStateChange.subscribe(async (loggedIn: boolean) => {
        sub.unsubscribe();
        return resolve(loggedIn);
      });
    });
  }

  // Recupera l'id del current user
  async getIdToken(){
    if(AuthService.forcedToken) return AuthService.forcedToken;
    if(!AuthService.firebase) return;
    if(AuthService.firebase.auth().currentUser){
      return await AuthService.firebase.auth().currentUser.getIdToken(false);
    }
  }

  // Recupera l'email dell'utente
  static getEmail(){
    if(AuthService.forcedEmail) return AuthService.forcedEmail;
    if(AuthService.token?.email) return AuthService.token.email;
    if(!AuthService.firebase) return;
    if(AuthService.firebase.auth().currentUser){
      return AuthService.firebase.auth().currentUser.email;
    }
  }

  // Effettua il login da username e password
  async loginWithEmailAndPassword(email: string, password: string){
    if(!AuthService.firebase) return;
    if(!AuthService.firebase_listener) AuthService.activateFirebaseListener();
    if(AuthService.firebase?.auth()?.currentUser?.email !== email) await AuthService.firebase.auth().signOut();
    await AuthService.firebase.auth().setPersistence(AuthService.firebase.auth.Auth.Persistence.LOCAL);
    await AuthService.firebase.auth().signInWithEmailAndPassword(email, password);
  }

  // Registra un nuovo utente
  async createUserWithEmailAndPassword(email: string, password: string){
    if(!AuthService.firebase) return;
    if(!AuthService.firebase_listener) AuthService.activateFirebaseListener();
    await AuthService.firebase.auth().createUserWithEmailAndPassword(email, password);
    await AuthService.firebase.auth().setPersistence(AuthService.firebase.auth.Auth.Persistence.LOCAL);
  }

  // Invia una mail alla mail inserita per il reset della password
  async resetPassword(email: string){
    if(!AuthService.firebase) return;
    await AuthService.firebase.auth().sendPasswordResetEmail(email);
  }

  // Riautentica l'utente per operazioni importanti come cambio password e cambio email utente
  async reauthenticateWithCredential(password: string){
    if(!AuthService.firebase) return;
    const user: any = AuthService.firebase.auth().currentUser;
    const credential: any = AuthService.firebase.auth.EmailAuthProvider.credential(
      user.email,
      password
    );
    await user.reauthenticateWithCredential(credential);
  }

  // Aggioorna l'email di un utente loggato
  async updateEmail(email: string){
    if(!AuthService.firebase) return;
    const user: any = AuthService.firebase.auth().currentUser;
    await user.updateEmail(email);
  }

  // Aggioorna la password di un utente loggato
  async updatePassword(password: string){
    if(!AuthService.firebase) return;
    const user: any = AuthService.firebase.auth().currentUser;
    await user.updatePassword(password);
  }

  // Effettua il logout
  async logout(){
    if(AuthService.authTokenInUrl){
      AuthService.token = null
      AuthService.authTokenInUrl = null;
      AuthService.isLogged = false;
      AuthService.authStateChange.next(false);
    }else if(AuthService.firebase){
      await AuthService.firebase.auth().signOut();
    }
  }

  async resetPasswordEmail(email){
    await AuthService.api.resetPassword(email);
  }
}
