import { Pipe, PipeTransform } from '@angular/core';
import {Ticket} from "src/app/classes/common/ticket";

@Pipe({
  name: 'searchTicket'
})
export class SearchTicketPipe implements PipeTransform {

  transform(tickets: Ticket[], status: string): Ticket[] {
    if (!tickets || !status || status === 'tutti') {
      return tickets; // Nessun filtro se gli argomenti sono nulli o se status è 'tutti'
    }

    switch(status){
      case 'aperti':
        return tickets.filter(ticket => ticket.status === 1);
      case 'in_lavorazione':
        return tickets.filter(ticket => ticket.status === 2);
      case 'chiusi':
        return tickets.filter(ticket => ticket.status === 3);
    }

  }


}
