

export class Order{
  shipping_address: ShippingAddress;
  organization_id: string;
  invoice_id: number;
  list_imei: string[];
  order_id: string;
  status: number;
  delivered_timestamp: number;
  url_tracking: string;

  constructor(data?: any){
    if(data){
      this.setData(data)
    }
  }

  setData(data: any){
    this.organization_id = data.organization_id;
    this.invoice_id = data.invoice_id;
    this.list_imei = data.list_imei;
    this.url_tracking = data.url_tracking;
    this.order_id = data.order_id;
    this.status = data.status;
    this.delivered_timestamp = data.delivered_timestamp;
    this.shipping_address = new ShippingAddress(data.shipping_address)
  }

}



export class ShippingAddress{
  id: number
  name: string;
  address1: string;
  city: string;
  zipcode: string;
  state: string;
  country: string = 'Italia';
  phone: string;

  constructor(data?: any){
    if(data){
      this.setData(data)
    }
  }

  setData(data:any){
    this.id = data.id;
    this.name = data.name;
    this.address1 = data.address1;
    this.city = data.city;
    this.zipcode = data.zipcode;
    this.state = data.state;
    this.country = data.country;
    this.phone = data.phone;
  }
}
