export type OnlineStates = 1 | 2 | 3; // 1 = online; 2 = offline da meno di 5 min; 3 = offline
export class VirtualPosition {

  havePosition: boolean;
  tripStatus: boolean;
  haveAnomaly: boolean;
  timestamp: number;
  timestamp_expired: number; // in ms
  longitude: number;
  latitude: number;
  angle: number;
  speed: number;
  odometer_trip: number;
  check_isOnline: OnlineStates;

  // Aggiunti
  address: string;
  odometer_trip_km: number;

  constructor(data?: any) {
    if(data){
      this.setData(data);
    }
  }

  setData(data: any){
    this.havePosition = data.havePosition;
    this.tripStatus = data.tripStatus;
    this.haveAnomaly = data.haveAnomaly;
    this.timestamp = data.timestamp;
    this.timestamp_expired = data.timestamp_expired;
    this.longitude = data.longitude;
    this.latitude = data.latitude;
    this.angle = data.angle;
    this.speed = data.speed;
    this.odometer_trip = data.odometer_trip;
    try{
      this.odometer_trip_km = parseInt("" + (this.odometer_trip / 1000));
    }catch(err){
      this.odometer_trip_km = 0;
    }
    this.check_isOnline = data.check_isOnline;
    this.address = undefined; // così all'aggiornamento ripulisce l'indirizzo
  }
}
