import {Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Input} from '@angular/core';
import {PopoverController} from "@ionic/angular";
import {
  Partecipant
} from "../../../classes/common/partecipant";
import {
  ViewService
} from "../../../services/common/view.service";


@Component({
  selector: 'app-popover-select-car',
  templateUrl: './popover-select-car.component.html',
  styleUrls: ['./popover-select-car.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopoverSelectCarComponent implements OnInit {

  @Input('cars') cars: Partecipant[];

  searchQuery: string;



  // listener

  listener = {
    view: null,
  };

  constructor(
    private cd: ChangeDetectorRef,
    private popoverCtrl: PopoverController,
    public view: ViewService,
  ) {}



  ngOnInit() {
    // console.log("ngOnInit()");

    // passed args (bad animation when inside
    // ionViewDidEnter)
    /*//noinspection TypeScriptUnresolvedFunction
    let componentProps =
      this.navParams.get("componentProps");

    this.menu = componentProps.menu;
    // this.cd.markForCheck(); // needed?*/
  }


  ionViewWillEnter() {
  }


  ionViewDidEnter() {
    this.listenerView();
  }


  ionViewWillLeave() {
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
  }

  ionViewDidLeave() {
  }


  ngOnDestroy() {
    // console.log("ngOnDestroy()");
  }





  async dismiss(data: any) {
    await this.popoverCtrl.dismiss(data)
  }




  // listener

  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      // console.log("listener.view", obj);

      // this.zone.run(() => {

      this.view.pipeChanged++;
      this.cd.markForCheck();

      setTimeout(() => {
        this.cd.markForCheck();
      }, 250);

      // });
    });
  }







}
