import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {NavParams, PopoverController} from '@ionic/angular';
import {
  PositionGps
} from "../../../classes/common/position-gps";
import {
  ViewService
} from "../../../services/common/view.service";


@Component({
  selector: 'app-popover-map-settings',
  templateUrl: './popover-map-settings.component.html',
  styleUrls: ['./popover-map-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopoverMapSettingsComponent implements OnInit {

  @Input() opts: any[] = [];
  @Input() map: any;
  @Input() route: PositionGps[];

  listener = {
    view: null
  };

  constructor(
    private cd: ChangeDetectorRef,
    private popoverCtrl: PopoverController,
    public view: ViewService,
    private navParams: NavParams,
  ) {
  }

  ngOnInit() {}

  ionViewDidEnter() {
    this.listenerView();
  }

  ionViewWillLeave() {
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
  }

  async dismiss(action: string) {
    await this.popoverCtrl.dismiss({ action: action })
  }

  changeVal(option: any){
    switch (option.id){
      case 'showLabelNumber':
        this.map.toggleVisibilityLabel(option.val);
        break;
      case 'showName':
        this.map.toggleVisibilityPopup(option.val);
        break;
      case 'history_showArrows':
        if(option.val){
          this.map.drawArrows();
        }else{
          this.map.cleanArrows();
        }
        break;
      case 'history_showStops':
        if(option.val){
          if(this.route){
            this.map.drawStopMarkers(this.route);
          }
        }else{
          this.map.cleanStopMarkers();
        }
        break;
      case 'history_settingsReport':
        //apro component per modifica
        this.dismiss( 'show_report_settings')
        break;
      case 'history_showPoi':
        if(option.val){
          this.map.drawPoiMarkers();
        }else{
          this.map.removePoiMarkers();
        }
        break;
      case 'openMarkerSettings':
        this.dismiss( 'show_marker_settings')
        break;
    }
  }


  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.cd.markForCheck();
      setTimeout(() => {
        this.cd.markForCheck();
      }, 250);
    });
  }
















}
