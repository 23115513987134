import {PaymentMethodsDetails} from "./paymentMethods";

export class Charge {

  id: string;
  payment_method: string[];
  payment_method_details: PaymentMethodsDetails;
  disputed: string;
  type: string;

  constructor(data?: any) {
    if (data) {
      this.setData(data);
    }
  }

  setData(data) {
    this.id = data.id;
    this.payment_method = data.payment_method;
    if(data.payment_method_details){
      this.payment_method_details = new PaymentMethodsDetails(data.payment_method_details, data.payment_method_details.type)
    }
    this.disputed = data.disputed;
  }

}
