import { ChangeDetectorRef, Component, OnInit, Input } from '@angular/core';
import { PopoverController } from "@ionic/angular";
import { ViewService } from "../../../services/common/view.service";
import { StripeService } from "../../../services/common/stripe.service";

@Component({
  selector: 'app-popover-payment',
  templateUrl: './popover-payment.component.html',
  styleUrls: ['./popover-payment.component.scss'],
})

export class PopoverPaymentComponent implements OnInit{
  @Input() dataObj: any;
  @Input() stripeCustomerId: string;
  @Input() stripeService: StripeService;
  @Input() popoverType: string;

  listener = {
    view: null,
  };

  public paymentList: any;

  constructor(
    private cd: ChangeDetectorRef,
    private popoverCtrl: PopoverController,
    public view: ViewService
  ){}

  ngOnInit(){
    switch(this.popoverType.toString()){
      case 'payment_list':
        this.paymentList = this.dataObj.paymentList;
        break;
    }
  }

  ionViewWillEnter(){
  }

  ionViewDidEnter(){
    this.listenerView();
  }

  ionViewWillLeave(){
    if(this.listener.view){
      this.listener.view.unsubscribe();
    }
  }

  ionViewDidLeave(){
  }

  listenerView(){
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.cd.markForCheck();
    });
  }

  async dismiss(data: any){
    await this.popoverCtrl.dismiss(data)
  }
}
