import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {NavParams, PopoverController} from '@ionic/angular';
import {
  ViewService
} from "../../../services/common/view.service";

@Component({
  selector: 'app-popover-map-type',
  templateUrl: './popover-map-type.component.html',
  styleUrls: ['./popover-map-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopoverMapTypeComponent implements OnInit {

  value: string;
  listener = {
    view: null
  };

  constructor(
    private cd: ChangeDetectorRef,
    private popoverCtrl: PopoverController,
    public view: ViewService,
    private navParams: NavParams
  ) {
    this.value = this.navParams.get('mapType');
  }

  ngOnInit() {}

  ionViewWillEnter() {
  }

  ionViewWillLeave() {
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
  }

  async dismiss(action: any) {
    await this.popoverCtrl.dismiss(action)
  }

  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.cd.markForCheck();
      setTimeout(() => {
        this.cd.markForCheck();
      }, 250);
    });
  }

}


