import * as moment from 'moment';

export class Ticket {

  _id: string;
  platform_type: string;
  id_organization: string;
  id_company: string;
  contacts: string[] = [];
  type: number;
  private _status: number;
  id_admin: string;
  important: boolean;
  to_read_client: boolean;
  unread_messages: number;
  unread_calls: number;
  unread_emails: number;
  timestamp_start: string;
  timestamp_end: string;
  last_update: string;
  private _$timestamp_start_from_now: string;
  flow_state: string;
  _$is_closed: boolean;
  num: string;


  constructor(data?: any) {
    if(data){
      this.setData(data)
    }
  }

  setData(data){
    this._id = data._id;
    this.platform_type = data.platform_type;
    this.id_organization = data.id_organization;
    this.unread_messages = data.unread_messages;
    this.unread_calls = data.unread_calls;
    this.unread_emails = data.unread_emails;
    this.id_company = data.id_company;
    this.id_admin = data.id_admin;
    this.important = data.important;
    this.contacts = data.contacts;
    this.num = data.num;
    this.status = data.status;
    this.timestamp_start = data.timestamp_start;
    this.$timestamp_start_from_now = data.last_update;
    this.to_read_client = data.to_read_client;
    this.timestamp_end = data.timestamp_end;
    this.flow_state = data.flow_state;
    this.type = data.type;
  }



  get $timestamp_start_from_now(): string{
    return this._$timestamp_start_from_now;
  }

  set $timestamp_start_from_now(value: string){
    this.last_update = value;
    moment.locale('it');
    const date = moment(value);
    this._$timestamp_start_from_now = date.fromNow();
  }

  get status(): number{
    return this._status;
  }

  set status(value: number){
    this._status = value;
    if(this.status){
      this.$is_closed = value === 3;
    }
  }

  get $is_closed(): boolean {
    return this._$is_closed;
  }

  set $is_closed(value: boolean) {
    this._$is_closed = value;
  }



}

export class WebMessage{
  _id: string;
  body: string;
  id_ticket: string;
  mediaUrl: string;
  message_type: string;
  dateSent_ms: string;
  numMedia: string;
  private _$date_sent: string;
  private _$media_url: string
  private _media_filename: string;
  private _$media_name: string;
  private _$media_is_img: boolean;
  private _$media_metadata: boolean;
  private _direction: string;

  private $getMediaInfo: (string) => Promise<{url: string, metadata: any}> = async () => {return {url: undefined, metadata: undefined}};


  constructor(rawObj?: any, options? : { getMediaInfo?: (string) => Promise<{url: string, metadata: any}> }){
    this.setAllFields(rawObj, options);
  }

  setAllFields(rawObj: any, options?: {getMediaInfo?: (string) => Promise<{url: string, metadata: any}>}){
    if(options){
      if(options.getMediaInfo) this.$getMediaInfo = options.getMediaInfo;
    }
    if(rawObj){
      this._id = rawObj._id;
      this.body = rawObj.body;
      this.mediaUrl = rawObj.mediaUrl;
      this.id_ticket = rawObj.id_ticket;
      this.message_type  = 'web';
      this.numMedia = rawObj.numMedia;
      this.media_filename = rawObj.media_filename;
      this.direction = rawObj.direction;
      this.$date_sent = rawObj.dateSent_ms;
    }
  }

  get $date_sent(): string{
    return this._$date_sent;
  }

  set $date_sent(value: string){
    this._$date_sent = moment(value).format('DD/MM/YYYY HH:mm');
    this.dateSent_ms = value;
  }

  get $media_url(): string{
    return this._$media_url;
  }

  set $media_url(value: string){
    this._$media_url = value;
  }

  get direction(): string{
    return this._direction;
  }

  set direction(value: string){
    switch (value){
      case 'inbound':
        this._direction = 'inbound';
        break;
      case 'outgoing':
      case 'outbound':
      case 'outbound-api':
      case 'outbound-dial':
        this._direction = 'outbound';
        break;
    }
  }

  get media_filename(): string{
    return this._media_filename;
  }

  set media_filename(value: string){
    this._media_filename = value;
    if(this.media_filename){
      try{
        let aus: string[] = this.media_filename.split('/');
        if(aus.length > 0){
          this.$media_name = aus[aus.length - 1];
          if(this.$media_name){
            aus = this.$media_name.split('.');
            if(aus.length > 0){
              const extension: string = aus[aus.length - 1];
              this.$media_is_img = extension === 'jpg' || extension === 'png' || extension === 'jpeg' || extension === 'gif';
            }
          }
        }
      }catch(err){
        console.error(err);
      }

      this.$getMediaInfo(this.media_filename).then((data: {url: string, metadata: any}) => {
        this.$media_url = data.url;
        this.$media_metadata = data.metadata;
      }).catch((err) => {
        console.error(err);
      });


    }
  }

  get $media_name(): string{
    return this._$media_name;
  }

  set $media_name(value: string){
    this._$media_name = value;
  }

  get $media_is_img(): boolean{
    return this._$media_is_img;
  }

  set $media_is_img(value: boolean){
    this._$media_is_img = value;
  }

  get $media_metadata(): boolean{
    return this._$media_metadata;
  }

  set $media_metadata(value: boolean){
    this._$media_metadata = value;
  }

}


export class WhatsappMessage{
  _id: string;
  body: string;
  private _direction: string;
  mediaUrl0: string;
  id_ticket: string;
  message_type: string;
  dateSent_ms: string;
  numMedia: string;
  private _$media_url: string
  private _$date_sent: string;
  private _media_filename: string;
  private _$media_name: string;
  private _$media_is_img: boolean;
  private _from: string;
  private _to: string;

  constructor(data?: any){
    if(data){
      this._id = data._id;
      this.body = data.body;
      this.direction = data.direction;
      this.from = data.from;
      this.to = data.to;
      this.mediaUrl0 = data.mediaUrl0;
      this.media_filename = data.media_filename;
      this.id_ticket = data.id_ticket;
      this.$date_sent = data.dateSent_ms;
      this.message_type  = 'whatsapp';
      this.numMedia = data.numMedia;
      this.$media_url = data.MediaUrl0;
    }
  }

  get $date_sent(): string{
    return this._$date_sent;
  }

  set $date_sent(value: string){
    this._$date_sent = moment(value).format('DD/MM/YYYY HH:mm');
    this.dateSent_ms = value;
  }

  get $media_url(): string{
    return this._$media_url;
  }

  set $media_url(value: string){
    this._$media_url = value;
  }

  get media_filename(): string{
    return this._media_filename;
  }

  set media_filename(value: string){
    this._media_filename = value;
    if(this.media_filename){
      try{
        let aus: string[] = this.media_filename.split('/');
        if(aus.length > 0){
          this.$media_name = aus[aus.length - 1];
          if(this.$media_name){
            aus = this.$media_name.split('.');
            if(aus.length > 0){
              const extension: string = aus[aus.length - 1];
              this.$media_is_img = extension === 'jpg' || extension === 'png' || extension === 'jpeg' || extension === 'gif';
            }
          }
        }
      }catch(err){
        console.error(err);
      }

    }
  }

  get $media_name(): string{
    return this._$media_name;
  }

  set $media_name(value: string){
    this._$media_name = value;
  }

  get $media_is_img(): boolean{
    return this._$media_is_img;
  }

  set $media_is_img(value: boolean){
    this._$media_is_img = value;
  }

  get from(): string{
    return this._from;
  }

  set from(value: string){
    const whatsappPreamble = 'whatsapp:';
    if (value.startsWith(whatsappPreamble)) {
      this._from = value.slice(whatsappPreamble.length);
    }else{
      this._from = value;
    }
  }

  get to(): string{
    return this._to;
  }

  set to(value: string){
    this._to = value;
  }

  get direction(): string{
    return this._direction;
  }

  set direction(value: string){
    switch (value){
      case 'inbound':
        this._direction = 'inbound';
        break;
      case 'outgoing':
      case 'outbound':
      case 'outbound-api':
      case 'outbound-dial':
        this._direction = 'outbound';
        break;
    }
  }



}
