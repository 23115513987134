import {Component, OnInit, Input, ChangeDetectorRef, Output, EventEmitter} from '@angular/core';
import {ChangeDetectionStrategy} from "@angular/core";
import {
  ViewService
} from "../../../services/common/view.service";


type Layout =
  "col" |
  "colStrong" |
  "colWeak" |

  "colWeak2" |
  // >may have 'edit' btn
  "colWeak2editable" |

  // can also take an array (vals)
  "colWeak3" |
  // colWeak3 + bug fix (white-space "reset")
  "colWeak3v2" |

  // has bool variation
  "colWeak4" |

  // *** row ***

  "row" |
  "rowWeak" |

  // row version of colWeak2
  "rowWeak2" |
  // >may have 'edit' btn
  "rowWeak2editable" |

  // row version of colWeak3v2 (w/ white-space "reset")
  // CANNOT take an array (NO vals)
  "rowWeak3" |
  // oneLiner version of colWeak3v2 (done w/ flex)
  "rowWeak3v2" |

  "";



@Component({
  selector: 'app-output',
  templateUrl: './output.component.html',
  styleUrls: ['./output.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OutputComponent implements OnInit {

  @Input() css: string;
  @Input() layout: Layout;
  DOMlayout: string;
  @Input() editable: boolean;
  @Input() lbl: string;
  @Input() prop: string;
  @Input() unit: string;
  @Input() val: boolean | string | any;
  @Input() valAlternatives: string[];
  @Input() val_isNum: boolean;
  @Input() vals: string[] = [];
  @Output() edit = new EventEmitter<any>();

  listener = {
    view: null,
  };

  constructor(
    private cd: ChangeDetectorRef,
    public view: ViewService,
  ) {}


  ngOnInit() {
    this.listenerView();
    this.set_DOMlayout();
  }

  ngOnDestroy() {
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
  }

  editEmit() {
    this.edit.emit();
  }


  set_DOMlayout() {
    if (!this.layout) {
      this.layout = "col";
    }


    let layout: string;

    switch (this.layout) {
      case "colStrong":
        layout = "col-strong";
        break;

      case "colWeak":
        layout = "col-weak";
        break;

      case "colWeak2":
        layout = "col-weak-2";
        break;

      case "colWeak2editable":
        layout = "col-weak-2-editable";
        break;

      case "colWeak3":
        layout = "col-weak-3";
        break;

      case "colWeak3v2":
        layout = "col-weak-3-v2";
        break;

      case "colWeak4":
        layout = "col-weak-4";
        break;

      case "rowWeak":
        layout = "row-weak";
        break;

      case "rowWeak2":
        layout = "row-weak-2";
        break;

      case "rowWeak2editable":
        layout = "row-weak-2-editable";
        break;

      case "rowWeak3":
        layout = "row-weak-3";
        break;

      case "rowWeak3v2":
        layout = "row-weak-3-v2";
        break;

      default:
        layout = this.layout;
    }

    this.DOMlayout = layout;
    ViewService.updateView.next();
  }



  // listener

  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.cd.markForCheck();
    });
  }





}
